.InfoTeaserOffer {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-white !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 32px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--content {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			'food trophies trophies'
			'food shout game';
		grid-template-rows: min-content 1fr;

		@include tablet-media() {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				'food food'
				'trophies trophies'
				'shout game';
		}

		@include mobile-media() {
			grid-template-columns: 1fr;
			grid-template-areas:
				'food'
				'trophies'
				'shout'
				'game';
		}
	}

	&--button {
		margin: 32px auto 0;
		width: fit-content;
	}
}

.InfoTeaserOffer--card {
	background-color: $c-white2;
	border-radius: 16px;
	overflow: hidden;
	padding: 24px;
	position: relative;

	&-small-title {
		color: $c-ink;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		margin-bottom: 4px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-title {
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 8px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-text {
		color: $c-blue-dark;
		font-size: 14px;
		line-height: 22px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 11px;
			line-height: 20px;
		}
	}

	&-food {
		grid-area: food;
		padding: unset;

		@include tablet-media() {
			align-items: center;
			display: flex;
		}

		@include mobile-media() {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		& > img {
			object-fit: cover;
			transform: scale(1.5);
			width: 100%;

			@include tablet-media() {
				width: 650px;
				min-width: 379px;
				height: 190px;
				transform: scale(1.1);
			}

			@include mobile-media() {
				height: unset;
				transform: scale(1.4);
				min-width: unset;
				width: 100%;
			}
		}

		&-wrapper {
			padding: 24px 24px 0px;

			@include tablet-media() {
				padding: 24px;
			}

			@include mobile-media() {
				padding: 24px 24px 0px;
			}
		}
	}

	&-trophies {
		align-items: center;
		display: flex;
		grid-area: trophies;
		padding: unset;

		@include mobile-media() {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		& > img {
			height: 100%;
			max-height: 190px;
			object-fit: contain;
		}

		&-wrapper {
			padding: 24px;

			@include mobile-media() {
				padding: 24px 24px 0px;
			}
		}
	}

	&-shout {
		grid-area: shout;

		&-tooltip-button {
			color: $c-blue-dark;
			margin-top: 16px;

			@include mobile-media() {
				@include reset-scale();
			}
		}
	}

	&-game {
		grid-area: game;
	}
}
