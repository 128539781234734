/* RawHtml Component Styles */
.RawHtml {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	opacity: 1;

	&.show {
		opacity: 1;
	}
}

.RawHtml--row {
	flex-direction: row-reverse;
	justify-content: flex-end;

	@include mobile {
		display: block;
	}
}

.RawHtml--title {
	@include space(margin-bottom, s);

	text-align: center;
}

.RawHtml + .RawHtml {
	@include space(margin-top, xl, true);
}

@include collapse-comp('RawHtml', ':not(.RawHtml)') {
	@include space(margin-top, xl, true);
}
