/* CompleteCookieConsent Component Styles */
.CompleteCookieConsent {

	position: fixed;
	bottom: 0;
	z-index: 999;
	background: red;
	width: 100%;
	transition: 0.5s height;

	background: linear-gradient(180deg, #F7F7F8 24.56%, #DCDCDE 87.69%);

	.inner-wrapper {
		@include space(padding-top, l);
		@include space(padding-bottom, l);

		@include mobile {
			@include space(padding-top, l);
			@include space(padding, m);
		}
	}

	@include mobile {
		.row > div:not(:last-child) {
			@include space(padding-bottom, m);
		}
	}

	input[type="checkbox"] {
		appearance: auto;
	}

	&--heading {
		@include space(margin-bottom, m);

		@include mobile {
			font-size: 24px;
			line-height: 2rem;
			@include space(margin-bottom, xs);
		}

		text-transform: uppercase;

		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 38px;

		@supports (-webkit-touch-callout: none) {
			line-height: 60px;

			@include mobile {
				line-height: 45px;
			}
		}
	}

	&--buttons {
		text-align: center;

		.Button {
			display: block;
			max-width: 215px;
			margin: auto;

			@include space(margin-bottom, m);

			@include mobile {
				@include space(margin-bottom, s);
				max-width: 275px;
			}

			.svg-arrow-left-dims {
				width: 40px;
				height: 40px;
				right: 7px;
				transform: rotate(180deg);
				margin: -15px;
			}
		}

		a[data-ref="CompleteCookieConsent:decline"] {
			color: $c-ink;
			text-transform: uppercase;
		}
	}

	.accordion {

		margin-right: 70px;
		display: none;
		color: $c-blue-dark;
		font-weight: 400;

		&_item_content p {
			color: $c-ink;
			font-size: 14px;
		}

		&.active {
			display: block;
		}

		&_item {
			margin-bottom: 10px;

			&_header {
				//background-color: $primaryColor;
				padding: 15px;
				cursor: pointer;
				position: relative;

				&::before {
					height: 15px;
					width: 15px;
					content: "";
					position: absolute;
					right: 15px;
					top: 15px;
					transition: 0.5s all;
					transform: rotate(45deg);
					border-right: 2px solid $c-blue-medium;
					border-bottom: 2px solid $c-blue-medium;
				}

				&.active {
					//background-color: $secondaryColor;
					//color: #fff;
					transition: 0.4s;

					&::before {
						transform: rotate(-135deg);
						top: 23px;
					}
				}
			}

			&_content {
				overflow-y: hidden;
				padding: 0;
				//display: none;
				transition: height 0.35s ease-in-out;
				height: 0;
				display: block;
			}

			.Wrapper-checkboxGroup {
				padding-top: 0;
				display: inline-block;

				.checkmark {
					background-color: $c-blue-neon;
				}
			}
		}
	}
}
