.corporate-section05 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-white !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 32px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--content {
		display: flex;
		gap: 16px;

		@include tablet-media() {
			flex-direction: column;
		}
	}

	&--right,
	&--left {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 16px;
	}

	&--right {
		@include tablet-media() {
			flex-direction: column-reverse;
		}
	}

	&--image-container {
		border-radius: 16px;
		overflow: hidden;
		flex-grow: 1;
		height: 300px;

		@include mobile-media() {
			height: 250px;
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.corporate-section05--card {
	background-color: $c-white2;
	border-radius: 16px;
	padding: 24px;
	height: fit-content;
	overflow: hidden;

	&-title {
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&-small-title {
		color: $c-blue-dark;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 21px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 14px;
		line-height: 22px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-success-message {
		align-items: center;
		display: none;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		min-height: 302px;
		width: 100%;

		&:only-child {
			display: flex;
		}
	}

	&-black {
		background-color: $c-black;
	}

	&-black &-title,
	&-black &-small-title {
		color: $c-white;
		width: fit-content;
	}

	&-form {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;

		.Inputv2 {
			width: 100%;

			&--label,
			&--input,
			&--select,
			&--textarea {
				color: $c-gray-light;
			}

			&--input,
			&--select,
			&--textarea {
				border-color: $c-ink;
				padding: 0.85rem;
				width: 100%;

				&::-webkit-input-placeholder {
					color: $c-gray-light;
				}
			}

			&--error {
				margin-top: 2px;
				position: unset;
			}
		}
	}
}
