.CorporateSection04 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--content {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&--image-container {
		border-radius: 16px;
		overflow: hidden;

		& > img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	&--cards-container {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(5, 1fr);

		@include tablet-media() {
			grid-template-columns: repeat(3, 1fr);
		}

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--action {
		display: flex;
		justify-content: center;
		margin-top: 32px;
	}
}

.CorporateSection04--card {
	padding: 24px;
	border-radius: 16px;
	background-color: $c-white2;
	height: fit-content;

	&-icon {
		margin-bottom: 4px;

		@include mobile-media() {
			@include reset-scale();
		}
	}

	&-title {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 8px;
		color: $c-blue-dark;
		line-height: 23px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 21px;
		}
	}

	&-description {
		font-size: 14px;
		color: $c-blue-dark;
		line-height: 22px;
		word-wrap: break-word;
		overflow-wrap: break-word;
		hyphens: auto;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}
}
