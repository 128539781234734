/* Loader Component Styles */
.Loader {
	display: flex;
	justify-content: space-between;
	width: rem(58);
	text-align: center;
}

.Loader > div {
	width: rem(12);
	height: rem(12);
	background-color: $c-blue-neon;
	border-radius: 100%;
	animation-name: bouncedelay;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	&:nth-child(1) {
		animation-delay: -0.32s;
	}

	&:nth-child(2) {
		animation-delay: -0.16s;
	}
}

@keyframes bouncedelay {
	0%,
	100% {
		transform: scale(0);
	}

	50% {
		transform: scale(1);
	}
}
