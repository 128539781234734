.floating-tooltip {
	background-color: $c-blue-dark;
	border-radius: 2px;
	color: $c-white;
	display: none;
	font-size: 14px;
	left: 0;
	line-height: 20px;
	padding: 6px 8px;
	position: absolute;
	top: 0;
	width: max-content;
	z-index: 9999;

	@include mobile-media() {
		@include reset-scale();

		font-size: 12px;
		line-height: 18px;
	}

	&--arrow {
		position: absolute;
		background: $c-blue-dark;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
	}
}
