body {
	.cc-banner.cc-theme-edgeless {
		.cc-link {
			font-size: 14px;
		}

		a.cc-btn {
			font-size: 18px;
			padding: 0.5em 1.8em;
		}
	}
}
