@mixin h2 {
	@include tx-title-l;

	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

@mixin h3 {
	@include tx-title-s;
	@include space(margin-top, m);
	@include space(margin-bottom, xs);

	.color-scheme-light &,
	.color-scheme-white & {
		color: $c-blue-medium;
	}
}

@mixin p {
	margin-bottom: 1em;
	color: $c-ink;

	.color-scheme-dark & {
		color: $c-white;
	}
}

@mixin list-base {
	display: block;
	margin-bottom: rem(40);

	> li {
		margin-bottom: 1em;
		padding-left: rem(50);
		color: $c-ink;
		position: relative;
	}
}

@mixin ul {
	@include list-base;

	> li {
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 11px;
			display: block;
			height: 2px;
			width: 30px;
			background-color: $c-blue-neon;
		}
	}
}

@mixin ol {
	@include list-base;

	counter-reset: numbered-list;

	> li {
		&::before {
			content: counter(numbered-list, decimal-leading-zero) '.';
			counter-increment: numbered-list;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
