/* Video Component Styles */

video.Video {
	position: absolute;
	width: auto;
	min-width: 100%;
	max-width: 100%;
	z-index: 10;
	top: 0;
	left: 0;
	opacity: 0.75;
	background: rgba(3, 3, 26, 1);
}

@include tablet {
	video.Video {
		right: 0;
		bottom: 0;
		min-height: 100%;
		height: auto;
		background-size: cover;
		overflow: hidden
	}
}

@include mobile {
	video.Video {
		top: -9999px;
		right: -9999px;
		bottom: -9999px;
		left: -9999px;
		margin: auto;
		height: 100%;
		max-width: 100vw;
		object-fit: cover;
	}
}

@include ios-tablet {
	video.Video {
		object-fit: cover;
		height: 100%;
	}
}
