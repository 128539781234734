/* ContactForm Component Styles */
.ContactForm {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	.g-recaptcha {
		height: 150px;
	}
}

.ContactForm--form {
	transition: opacity $transition-duration-fast $ease-in-out-quad;
}

.ContactForm--heading {
	@include space(margin-bottom, m);
}

.ContactForm--lead {
	@include space(margin-bottom, l);
}

.ContactForm--message {
	@include space(margin-top, m);

	display: none;
	padding: rem(30) rem(20);
	background-color: $c-blue-neon;
	color: $c-blue-medium;

	&.error {
		color: $c-white;
		background-color: $c-red;
	}
}

.ContactForm[data-state='submitting'] {
	.ContactForm--form {
		opacity: 0.5;
		pointer-events: none;
	}
}

.ContactForm[data-state='success'] {
	.ContactForm--message.success {
		display: block;
	}
}

.ContactForm[data-state='error'] {
	.ContactForm--message.error {
		display: block;
	}
}

.ContactForm--button--container {
	text-align: right;

	@include tablet-portrait {
		text-align: center;
	}

	@include mobile {
		text-align: center;
	}
}

.ContactForm.white-background {
	background-color: white;
}

.ContactForm--row {
	.Input--input {
		color: $c-white;
	}
}
