.svg-arrow-down-dims {
	width: 32px;
	height: 32px;
}

.svg-arrow-down-big-current-color-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-down-with-tail-dims {
	width: 25px;
	height: 24px;
}

.svg-arrow-left-dims {
	width: 40px;
	height: 40px;
}

.svg-arrow-right-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-right-white-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-with-tail-circled-dims {
	width: 44px;
	height: 44px;
}

.svg-arrow-with-tail-right-dims {
	width: 16px;
	height: 16px;
}

.svg-arrow-with-tail-right-tilt-dims {
	width: 18px;
	height: 18px;
}

.svg-beer-dims {
	width: 24px;
	height: 24px;
}

.svg-bell-dims {
	width: 16px;
	height: 20px;
}

.svg-birthday-dims {
	width: 60px;
	height: 60px;
}

.svg-birthday-inverted-dims {
	width: 60px;
	height: 60px;
}

.svg-business-people-dims {
	width: 64px;
	height: 64px;
}

.svg-business-people-inverted-dims {
	width: 64px;
	height: 64px;
}

.svg-cake-dims {
	width: 24px;
	height: 24px;
}

.svg-calendar-dims {
	width: 64px;
	height: 64px;
}

.svg-calendar-inverted-dims {
	width: 64px;
	height: 64px;
}

.svg-car-dims {
	width: 24px;
	height: 24px;
}

.svg-card-dims {
	width: 64px;
	height: 66px;
}

.svg-card-inverted-dims {
	width: 64px;
	height: 66px;
}

.svg-check-dims {
	width: 16px;
	height: 16px;
}

.svg-close-dims {
	width: 24px;
	height: 24px;
}

.svg-close-popup-dims {
	width: 21px;
	height: 20px;
}

.svg-corporateSection04--calendar-dims {
	width: 25px;
	height: 24px;
}

.svg-corporateSection04--challenge-league-dims {
	width: 25px;
	height: 24px;
}

.svg-corporateSection04--document-dims {
	width: 25px;
	height: 24px;
}

.svg-corporateSection04--people-dims {
	width: 25px;
	height: 24px;
}

.svg-corporateSection04--race-league-dims {
	width: 25px;
	height: 24px;
}

.svg-crown-dims {
	width: 67px;
	height: 51px;
}

.svg-crown-inverted-dims {
	width: 67px;
	height: 51px;
}

.svg-discord-dims {
	width: 25px;
	height: 19px;
}

.svg-document-dims {
	width: 24px;
	height: 24px;
}

.svg-download-dims {
	width: 24px;
	height: 24px;
}

.svg-download-circled-dims {
	width: 44px;
	height: 44px;
}

.svg-envelop-dims {
	width: 16px;
	height: 16px;
}

.svg-facebook-dims {
	width: 24px;
	height: 24px;
}

.svg-gamepad-dims {
	width: 24px;
	height: 24px;
}

.svg-hand-key-dims {
	width: 81px;
	height: 81px;
}

.svg-hand-key-inverted-dims {
	width: 81px;
	height: 81px;
}

.svg-helmet-dims {
	width: 64px;
	height: 66px;
}

.svg-helmet-inverted-dims {
	width: 64px;
	height: 66px;
}

.svg-info-dims {
	width: 24px;
	height: 24px;
}

.svg-instagram-dims {
	width: 24px;
	height: 24px;
}

.svg-learner-driver-dims {
	width: 62px;
	height: 62px;
}

.svg-learner-driver-inverted-dims {
	width: 62px;
	height: 62px;
}

.svg-linkedin-dims {
	width: 24px;
	height: 24px;
}

.svg-live-dims {
	width: 22px;
	height: 16px;
}

.svg-location-dims {
	width: 16px;
	height: 16px;
}

.svg-medal-dims {
	width: 24px;
	height: 24px;
}

.svg-midweek-madness-dims {
	width: 60px;
	height: 60px;
}

.svg-midweek-madness-inverted-dims {
	width: 60px;
	height: 60px;
}

.svg-people-group-dims {
	width: 102px;
	height: 24px;
}

.svg-phone-dims {
	width: 16px;
	height: 16px;
}

.svg-placeholder-dims {
	width: 24px;
	height: 24px;
}

.svg-play-circle-dims {
	width: 33px;
	height: 32px;
}

.svg-refresh-dims {
	width: 24px;
	height: 24px;
}

.svg-rocket-dims {
	width: 25px;
	height: 24px;
}

.svg-search-dims {
	width: 24px;
	height: 24px;
}

.svg-send-dims {
	width: 24px;
	height: 24px;
}

.svg-slider-next-dims {
	width: 104px;
	height: 104px;
}

.svg-slider-previous-dims {
	width: 104px;
	height: 104px;
}

.svg-speedometer-dims {
	width: 24px;
	height: 24px;
}

.svg-star-dims {
	width: 45px;
	height: 45px;
}

.svg-star-filled-dims {
	width: 45px;
	height: 45px;
}

.svg-study-break-dims {
	width: 95px;
	height: 60px;
}

.svg-study-break-inverted-dims {
	width: 95px;
	height: 60px;
}

.svg-threads-dims {
	width: 24px;
	height: 24px;
}

.svg-tiktok-dims {
	width: 25px;
	height: 24px;
}

.svg-trophy-dims {
	width: 24px;
	height: 24px;
}

.svg-twitch-dims {
	width: 25px;
	height: 24px;
}

.svg-twitter-dims {
	width: 24px;
	height: 19px;
}

.svg-user-groups-dims {
	width: 64px;
	height: 42px;
}

.svg-user-groups-inverted-dims {
	width: 64px;
	height: 42px;
}

.svg-xtwitter-dims {
	width: 24px;
	height: 24px;
}

.svg-youtube-dims {
	width: 24px;
	height: 24px;
}

