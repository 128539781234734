.fullscreenGrid {
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(3, 1fr);

	@include tablet-media() {
		grid-template-columns: 1fr;
	}

	&--column {
		display: flex;
		flex-direction: column;
		gap: 16px;
		flex: 1;
	}

	&--card {
		background-color: $c-white2;
		border-radius: 16px;
		gap: 16px;
		height: fit-content;
		padding: 24px;

		&-description {
			color: $c-blue-dark;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}

		&-author {
			color: $c-blue-dark;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}
	}
}
