.IndividualSection06 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--title {
		color: $c-white !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--row {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, 1fr);

		@include mobile-large-media() {
			grid-template-columns: 1fr;
		}
	}

	&--card {
		background-color: $c-background;
		border-radius: 16px;
		padding: 24px;

		&-title {
			color: $c-blue-dark;
			font-size: 34px;
			font-family: 'Bai Jamjuree', sans-serif;
			font-weight: 500;
			line-height: 40px;
			margin-bottom: 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 32px;
				line-height: 38px;
			}
		}

		&-description {
			color: $c-blue-dark;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 24px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	&--image {
		border-radius: 16px;
		overflow: hidden;
		position: relative;

		@include mobile-large-media() {
			height: 300px;
		}

		img {
			height: 100%;
			object-fit: cover;
			position: absolute;
			width: 100%;
		}
	}
}
