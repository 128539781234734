.Download {
	display: block;
}

.Download--img {
	width: rem(64);
	margin: 0 auto;
}

.Download--filename {
	@include space(margin-top, s);
	@include space(margin-bottom, xs);
}
