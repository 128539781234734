/* TextCentered Component Styles */
.TextCentered {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.TextCentered--row {
	@include mobile {
		display: block;
	}
}

.TextCentered--content {
	@include grid-col($cols: 8);
	margin: auto;
	text-align: center;

	@include tablet {
		@include grid-col($cols: 9);
	}

	@include tablet-portrait {
		@include grid-col($cols: 8);
	}

	@include mobile {
		@include grid-col($cols: 12);
	}
}

.TextCentered--title {
	@include space(margin-bottom, m);
}

.TextCentered--cta {
	@include space(padding-top, m);
}

@include collapse-comp('TextCentered') {
	@include space(margin-top, xl, true);
}

.Hero.text-under-lead + .TextCentered {
	margin-top: 0;
}
