/* InfoTeaser Component Styles */
.InfoTeaserUser {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--title {
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		margin: 0 auto 32px;
		max-width: 634px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--content {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(4, 1fr);
		justify-content: space-between;

		@include tablet-media() {
			display: grid;
			gap: 16px;
			grid-template-columns: repeat(2, 1fr);
		}

		@include mobile-media() {
			display: grid;
			gap: 16px;
			grid-template-columns: 1fr;
		}
	}

	&--col {
		align-items: center;
		color: $c-white;
		display: flex;
		flex-direction: column;

		& svg {
			height: 38px;
			width: 38px;

			@include mobile-media() {
				@include reset-scale();
			}
		}
	}

	&-type-font-bai {
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&-type-font-ibm {
		color: $c-white;
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: 16px;
		line-height: 22px;
		margin-top: 4px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 20px;
		}
	}
}
