.pictureContent05 {
	background-color: $c-white2 !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--maintitle {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin-bottom: 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--row {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: unset;
		}
	}

	&--lounges {
		background-color: $c-background;
		border-radius: 16px;
		padding: 24px;

		&-title {
			color: $c-blue-dark;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 22px;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 16px;
			width: 100%;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 28px;
			}
		}

		&-list {
			display: grid;
			gap: 16px;
			grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
		}

		&-lounge {
			background-color: $c-white2;
			border-radius: 16px;
			color: $c-blue-dark;
			padding: 16px;

			&-title {
				color: $c-blue-dark;
				font-size: 18px;
				font-weight: 500;
				line-height: 26px;
				margin-bottom: 8px;

				@include mobile-media() {
					@include reset-scale();

					font-size: 16px;
					line-height: 24px;
				}
			}

			&-detail {
				align-items: center;
				display: flex;
				font-size: 14px;
				gap: 8px;
				line-height: 22px;
				margin-bottom: 4px;

				@include mobile-media() {
					@include reset-scale();

					font-size: 12px;
					line-height: 20px;
				}

				&:last-child {
					margin-bottom: 0px;
				}

				u {
					text-decoration: underline;
				}
			}
		}
	}
}
