/* SocialMediaFeed Component Styles */
.SocialMediaFeed {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.SocialMediaFeed--heading {
	@include space(margin-bottom, l);

	text-align: center;
}

.SocialMediaFeed--twitch {
	width: 100%;
	height: 100%;
	position: relative;

	.Loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}

.SocialMediaFeed--ig,
.SocialMediaFeed--fb {
	position: relative;
	width: 100%;
	height: 100%;

	.Loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.preview {
		display: block;
		height: 100%;
	}
}

.SocialMediaFeed--cta {
	@include space(margin-top, l);

	text-align: center;
}

.SocialMediaFeed--ig {
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover'; /* stylelint-disable-line */
	}

	.preview article {
		height: 100%;
	}
}

.SocialMediaFeed--fb {
	.preview {
		position: relative;

		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: rem(32);
			background-image: linear-gradient(to bottom, rgba($c-blue-dark, 0), rgba($c-blue-dark, 1) 75%);
		}
	}

	.preview-header {
		display: flex;
		align-items: flex-start;
		margin-bottom: rem(12);

		time {
			display: block;
			margin-top: rem(4);
			opacity: 0.6;
		}

		img {
			height: rem(48);
			width: auto;
			margin-right: rem(10);
		}
	}
}

.SocialMediaFeed.layout-1 {
	@include tablet {
		.FeedGrid--cell.large {
			width: (9 / 12) * 100%;
		}

		.FeedGrid--cell.small {
			width: (3 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: 100%;

			&::before {
				padding-top: 25%;
			}
		}
	}

	@include tablet-portrait {
		.FeedGrid--cell.large {
			width: 100%;
		}

		.FeedGrid--cell.small {
			width: (4 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: (8 / 12) * 100%;

			&::before {
				padding-top: calc(((6 / 12) * 100%) - 1px); // Fixes rounding errors
			}
		}
	}

	@include mobile {
		.FeedGrid--cell.small {
			width: (6 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: 100%;

			&::before {
				content: none;
			}

			.FeedGrid--cellContent {
				position: static;
			}

			.FeedGrid--cellInner {
				overflow: visible;
			}

			.preview.facebook::after {
				content: none;
			}
		}
	}
}

@include collapse-comp('SocialMediaFeed') {
	@include space(margin-top, xl, true);
}
