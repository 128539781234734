/* FeedGrid Component Styles */
.FeedGrid--grid {
	@include cf;

	margin: rem($gutter / -2);

	@include mobile {
		margin: rem($gutter-mobile / -2);
	}
}

.FeedGrid--cell {
	float: left;
	position: relative;

	&::before {
		display: block;
		content: '';
		width: 100%;
	}

	// for twitch video embed
	&.large {
		width: 50%;

		&::before {
			padding-top: calc(((2 / 3) * 100%) - 1px); // Fixes rounding errors
		}
	}

	// small square box for instagram previews
	&.small {
		width: (2 / 12) * 100%;

		&::before {
			padding-top: 100%;
		}
	}

	// medium square box for instagram previews
	&.medium {
		width: (4 / 12) * 100%;

		&::before {
			padding-top: 100%;
		}
	}

	// for facebook posts
	&.double {
		width: (4 / 12) * 100%;

		&::before {
			padding-top: 50%;
		}
	}
}

.FeedGrid--cellContent {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: rem($gutter / 2);

	@include mobile {
		padding: rem($gutter-mobile / 2);
	}
}

.FeedGrid--cellInner {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	overflow: hidden;

	.Image {
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
		font-family: 'object-fit: cover'; /* stylelint-disable-line */
	}
}
