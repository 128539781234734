/* HeroLead Component Styles */
.HeroLead {
	@include space(padding-top, xl);
	//@include space(padding-bottom, xl);
	//
	//.Hero:last-child & {
	//	@include space(padding-bottom, l);
	//}
}

.HeroLead--lead {
	@include font-size-3;

	line-height: (30/20);
	text-align: center;

	@include mobile {
		padding-top: 0;
		font-size: rem(17);
	}
}

.HeroLead--cta {
	@include space(padding-top, l);

	text-align: center;
}
