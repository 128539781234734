/* InfoCards Component Styles */
.InfoCards:not(.old-design) {
	margin-top: 13px;
	@include space(padding-top, l);
	@include space(padding-bottom, l);

	.InfoCards--heading {
		@include space(margin-bottom, l);

		@include mobile {
			@include space(margin-bottom, m);
		}

		text-align: center;
		text-transform: uppercase;

		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 38px;
	}

	.InfoCards--list {
		@include space(margin-bottom, l, true);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		gap: 20px;

		@include mobile {
			display: none;
		}

		.InfoCards--card {
			width: 357px;
			padding: 0 30px;
			text-align: center;

			@include tablet {
				padding: 0 rem(16);
			}

			@include mobile {
				padding: 0;
			}

			.InfoCard--title {
				color: $c-blue-medium;
				@include space(margin-bottom, xs);
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 27px;
				transition: all $transition-duration-fast $ease-out-quad;
			}

			.InfoCard--text p {
				transition: all $transition-duration-fast $ease-out-quad;
			}

			.InfoCard--img {
				width: 100%;
				height: 64px;
				margin: 23px auto 11px;
				opacity: 1;
				transition: opacity $transition-duration-fast $ease-out-quad;

				&.inverted {
					margin-top: -75px;
					position: relative;
					opacity: 0;
				}

				.SvgIcon {
					height: 100%;
				}
			}

			.InfoCard--cta {
				opacity: 0;
				text-align: center;
				padding-top: 30px;
				padding-bottom: 40px;
				transition: opacity $transition-duration-fast $ease-out-quad;
			}

			.InfoCard--cta {
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;

				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;

				color: $c-blue-neon;

				&.mobile-only {
					display: none;
				}
			}
		}

		&:not(.mobile-only) {
			.InfoCards--card {

				background: transparent;
				color: $c-ink;
				transition: all $transition-duration-fast $ease-out-quad;

				&:hover {
					color: #00FFFF;

					position: relative;

					background: #000047;
					border-radius: 10px;

					.InfoCard--cta {
						opacity: 1;
					}

					.InfoCard--img:not(.inverted) {
						opacity: 0;
					}

					.InfoCard--img.inverted {
						opacity: 1;
					}

					.InfoCard--title {
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 27px;
						text-align: center;
						color: $c-blue-neon;
					}

					.InfoCard--text p {
						font-style: normal;
						font-weight: 400;
						font-size: 17px;
						line-height: 25px;
						color: $c-white;
					}
				}
			}
		}

		&.mobile-only {
			display: none;

			@include mobile {
				display: block;
			}

			.InfoCards--sliderContainer {
				width: 100%;

				.Slider .flickity-prev-next-button {
					top: 121.5px;
					-webkit-transform: none;
					transform: none;
					background: #FFFFFF;
					border: 2px solid #00FFFF;
					box-shadow: 0 10px 20px rgba(0, 255, 255, 0.15);
					height: 64px;
					width: 64px;

					@include mobile {
						top: 150px;
						height: 30px;
						width: 30px;
						opacity: 0.7;
					}
				}

				.InfoCards--card {
					width: 235px;
					margin: auto auto 30px;
					text-align: center;

					@supports (-webkit-touch-callout: none) {
						width: 357px;
					}

					.InfoCard--img {
						margin-bottom: 30px;
					}

					.InfoCard--text {
						font-style: normal;
						font-weight: 400;
						font-size: 17px;
						line-height: 25px;

						@supports (-webkit-touch-callout: none) {
							line-height: 42px;
						}
					}

					.InfoCard--cta:not(.mobile-only) {
						display: none;
					}

					.InfoCard--cta.mobile-only {
						visibility: visible;
						display: block;
						padding-top: 50px;
						opacity: 1;

						.Button.transparent {
							color: $c-blue-medium
						}
					}

				}

				.Slider--slide {
					margin: 0;
				}
			}
		}
	}

	.InfoCards--cta {
		margin-top: 90px;
		text-align: center;

		@include mobile {
			display: none;
		}

		.Button {
			box-shadow: 0 15px 40px rgba(62, 84, 185, 0.2);
			border-radius: 30px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 2px;
			text-transform: uppercase;

			color: $c-blue-neon;
		}
	}
}

@include collapse-comp('InfoCards') {
	@include space(margin-top, xl, true);
}


/* InfoCards Component Styles */
.InfoCards.old-design {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	.InfoCards--heading {
		@include space(margin-bottom, l);

		text-align: center;
	}

	.InfoCards--list {
		@include space(margin-bottom, l, true);

		&.mobile-only {
			display: none;
		}
	}

	.InfoCards--card {
		@include space(margin-top, m);
		@include space(margin-bottom, l);
	}

	.InfoCards--cta {
		@include space(margin-top, l);

		text-align: center;
	}

	.InfoCard {
		padding: 0 rem(20);
		text-align: center;

		@include tablet {
			padding: 0 rem(16);
		}

		@include mobile {
			padding: 0;
		}
	}

	.InfoCard--img {
		@include space(margin-bottom, s);

		margin-top: 0;
		margin-right: auto;
		margin-left: auto;
		width: rem(64);
	}

	.InfoCard--title {
		@include space(margin-bottom, xs);
	}
}
