.pictureContent02 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--smalltitle {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 18px;
		}
	}

	&--maintitle {
		color: $c-white !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: $c-white !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--grid {
		display: grid;
		gap: 16px;
		grid-template-areas:
			'card1 img'
			'card2 img';
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: min-content;

		@include tablet-media() {
			grid-template-areas:
				'card1'
				'card2'
				'img';
			grid-template-columns: 1fr;
		}

		&[data-fourcards='1'] {
			grid-template-areas:
				'card1 img'
				'card2 card3';

			@include tablet-media() {
				grid-template-areas:
					'card1'
					'img'
					'card2'
					'card3';
			}
		}
	}

	&--leftColumn {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		padding-bottom: 8px;

		@include tablet-media() {
			flex-direction: row;
			padding-bottom: unset;
		}

		@include mobile-media() {
			flex-direction: column;
		}
	}

	&--rightColumn {
		flex: 1;
		display: flex;
	}

	&--img {
		height: 100%;
		position: relative;
		width: 100%;

		@include tablet-media() {
			height: 350px;
		}

		img {
			border-radius: 16px;
			height: 100%;
			object-fit: cover;
			position: absolute;
			width: 100%;
		}
	}

	&--grid[data-fourcards='1'] &--img {
		@include tablet-media() {
			height: 350px;
		}

		@include mobile-media() {
			height: 250px;
		}
	}

	&--card {
		background-color: $c-white2;
		padding: 24px;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		flex: 1;
		position: relative;
		overflow: hidden;

		&-black {
			background-color: $c-black;

			& * {
				color: $c-white !important;
			}
		}

		&1 {
			grid-area: card1;
		}

		&2 {
			grid-area: card2;
			margin-bottom: 8px;
		}

		&3 {
			grid-area: card3;
			height: fit-content;
		}

		&-img {
			grid-area: img;
		}
	}

	&--cardTitle {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		word-wrap: break-word;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&--cardsubtitle {
		margin-bottom: 16px;
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $c-blue-dark;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--indexedDescription {
		color: $c-blue-dark !important;
		display: inline-flex;
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 24px;
		}
	}

	&--cardDescription {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 0px !important;
		color: $c-blue-dark;
		overflow: hidden;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--arrowIcon {
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 600;
		font-size: 14px;
		margin: 3px 4px 0 0;
		min-width: 16px;

		@include mobile-media() {
			@include reset-scale();

			margin-top: 2px;
		}
	}

	&--button {
		margin-top: auto;

		&-mt8 {
			margin-top: 8px;
		}
	}

	&--scroll-button {
		color: $c-blue-medium;
		cursor: pointer;

		@include mobile-media() {
			@include reset-scale();
		}

		&,
		& svg {
			height: 44px;
			width: 44px;
		}
	}
}
