/* InfoTeaser Component Styles */
.StatsSection {
	background-color: $c-white2 !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--content {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(3, 1fr);
		justify-content: space-between;

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--col {
		align-items: center;
		color: $c-blue-dark;
		display: flex;
		flex-direction: column;
	}

	.type-font-bai {
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 34px;
		font-weight: 500;
		line-height: 40px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	.type-font-ibm {
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		text-align: center;
		margin-top: 4px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 20px;
		}
	}
}
