/* CardsRow Component Styles */
.CardsRow {
	font-family: $base-font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	color: $c-gray-darker;

	.CardsRow--row {
		width: 100%;
		display: flex;
		padding-top: 13px;
		//padding-bottom: 13px;
		gap: 13px;
		flex-wrap: wrap;

		@include below-desktop {
			gap: 0;
			padding-top: 0;
		}
	}

	.CardsRow--container {
		background: linear-gradient(180deg, #f7f7f8 24.56%, #dcdcde 87.69%);

		height: auto;
		width: calc(50% - 7px);
		position: relative;
		overflow: hidden;

		@include below-desktop {
			width: 100%;
			margin-top: 13px;
		}

		.CardsRow--cta-1,
		.CardsRow--cta-2 {
			z-index: 9;
			padding-bottom: 50px;
			position: relative;
		}

		&.voucher {
			@include below-desktop {
				height: 500px;

				@supports (-webkit-touch-callout: none) {
					height: 600px;
				}
			}

			.CardsRow--contentWrap {
				position: relative;
				z-index: 9;
			}

			.CardsRow--cta-2 {
				display: none;
			}

			.CardsRow--background-image {
				bottom: 0;

				img {
					height: 100%;
					width: auto;
				}

				@include ios-mobile-portrait {
					img {
						height: 100%;
						width: 100vw;
					}
				}

				@include ios-tablet {
					img {
						height: 100%;
						width: 100vw;
					}
				}

				&.image-mobile {
					display: none;
				}

				@include below-desktop {
					display: none;

					&.image-mobile {
						top: auto;
						bottom: -300px;
						height: auto;
						width: 125%;
						left: 0;
						display: flex;
						justify-content: right;
					}

					@include ios-mobile-portrait {
						&.image-mobile {
							bottom: -200px;
							left: -50px;
						}
					}

					@supports (-webkit-touch-callout: none) {
						bottom: -350px;
					}

					@include ios-tablet {
						&.image-mobile {
							bottom: -500px;
						}
					}
				}
			}

			.CardsRow--heading {
				@include below-desktop {
					padding-bottom: 30px;
				}
			}
		}

		&.get-the-app {
			background: linear-gradient(180deg, #f7f7f8 24.56%, #dcdcde 87.69%);

			@include below-desktop {
				height: 500px;

				@supports (-webkit-touch-callout: none) {
					height: 600px;
				}
			}

			.CardsRow--heading {
				padding-bottom: 20px;
			}

			.CardsRow--text {
				@include below-desktop {
					padding-bottom: 30px;
				}
			}

			.CardsRow--background-image {
				right: 50%;
				transform: translate(50%, 0);
				width: 50%;
				height: 40%;
				top: auto;
				bottom: 0;

				.Image.lazyautosizes.lazyloaded {
					height: 100%;
					object-fit: contain;
				}

				@include mobile {
					height: auto;
					right: auto;
					bottom: -25%;

					@supports (-webkit-touch-callout: none) {
						line-height: 66px;
					}
				}
			}

			.CardsRow--cta-1,
			.CardsRow--cta-2 {
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;

				color: $c-blue-medium;
				bottom: 60px;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);

				.Button {
					display: flex;
					align-items: center;
					gap: 0;
				}

				span.SvgIcon {
					width: 20px;
					height: 20px;
					display: inline-block;
					z-index: 999;
					margin-right: 10px;

					&.apple {
						top: -2px;
						position: relative;
					}
				}

				@include below-desktop {
					position: relative;
					bottom: auto;
					left: auto;
					transform: none;
					margin: auto;
					display: inline-block;
				}
			}

			.CardsRow--cta-1 {
				margin-left: -250px;
				bottom: 0;
				@include below-desktop {
					margin-left: auto;
				}
			}

			.CardsRow--cta-2 {
				margin-left: 250px;
				bottom: 0;
				@include below-desktop {
					margin-left: auto;
				}
			}
		}

		&.podcast {
			z-index: 0;

			@include below-desktop {
				height: 500px;

				@supports (-webkit-touch-callout: none) {
					height: 660px;
				}

				@include ios-tablet {
					height: 500px;
				}
			}

			.CardsRow--contentWrap {
				margin-top: 70px;
				width: 100%;
				max-width: 100%;
				padding-right: 50px;
				padding-left: 50px;
				margin-left: auto;
				margin-right: auto;

				@include mobile {
					padding-right: 20px;
					padding-left: 20px;
				}
			}

			.CardsRow--heading,
			.CardsRow--text {
				padding-bottom: 30px;
				color: $c-white;
				font-family: $secondary-font-family;
			}

			.CardsRow--heading {
				@include mobile {
					font-style: normal;
					font-weight: 500;
					font-size: 30px;
					line-height: 1.3;
					text-align: center;
				}
			}

			.CardsRow--text {
				@include mobile {
					padding-bottom: 40px;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 1.5;
					text-align: center;
				}
			}

			.CardsRow--background-image {
				position: relative;
				height: auto;
				width: 400px;
				margin: auto auto 35px;

				@include mobile {
					width: 100%;
				}

				&:not(.image-mobile) {
					img {
						left: 20px;
						position: relative;

						@include mobile {
							left: auto;
							padding-left: 40px;
							padding-right: 40px;
						}
					}
				}
			}

			.CardsRow--background-image.image-mobile {
				position: absolute;
				width: 100%;
				object-fit: cover;
				height: 100%;
				z-index: -1;

				&::before {
					content: ' ';
					display: block;
					background: linear-gradient(0deg, rgba(0, 0, 71, 0) 0%, #000047 100%);
					height: 100%;
					width: 100%;
					position: absolute;
					z-index: 99;
				}

				&:after {
					background: #000000;
					mix-blend-mode: multiply;
					opacity: 0.69;
					content: ' ';
					display: block;
					width: 100%;
					position: absolute;
					height: 100%;
					top: 0;
				}

				img {
					height: 100%;
				}
			}

			.CardsRow--cta-1 {
				position: absolute;
				margin: auto;
				bottom: -15px;
				right: 50%;
				transform: translate(50%, 0);
			}

			.CardsRow--cta-2 {
				display: none;
			}
		}

		&.subscribe {
			background: $c-blue-dark;
			padding-left: 25px;
			padding-right: 25px;
			z-index: 1;

			@include below-desktop {
				height: auto;
			}

			.CardsRow--contentWrap {
				margin-top: 70px;
				width: 100%;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
			}

			.ContactForm {
				padding: 0;
			}

			.Input--label,
			.CardsRow--heading,
			.CardsRow--text {
				color: $c-white;
			}

			.CardsRow--heading,
			.CardsRow--text {
				padding-bottom: 30px;

				@supports (-webkit-touch-callout: none) {
					line-height: 50px;
				}
			}

			.CardsRow--background-image {
				z-index: -1;
				width: 450px;
				object-fit: contain;

				@include mobile {
					width: 100%;
					height: 100%;
					opacity: 0.75;

					.Image {
						height: 100%;
						object-position: center;
						object-fit: cover;
					}
				}

				&:before {
					content: ' ';
					display: block;
					background: linear-gradient(270deg, rgba(0, 0, 71, 0) 0%, #000047 100%);
					height: 100%;
					width: 100%;
					position: absolute;
					z-index: 99;
				}
			}

			.ContactForm--row {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 35px;
				padding-left: 90px;
				padding-right: 90px;

				&:not(:first-of-type) {
					padding-top: 20px;
				}

				@include mobile {
					flex-direction: column;
				}
			}

			.Input {
				width: 100%;
				text-align: left;

				.Input--input {
					color: white;
				}
			}

			.ContactForm--button--container {
				text-align: center;
				@include space(padding-top, l);

				@include mobile {
					padding-top: 30px;
				}
			}

			.NewsletterForm--notice {
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color: $c-white;
				padding-top: 23px;
				@include space(padding-bottom, s);

				@include mobile {
					padding-bottom: 25px;
				}

				a {
					text-decoration: underline;
					text-decoration-color: $c-blue-neon;
					text-decoration-thickness: 2px;
					text-underline-offset: 2px;
				}
			}
		}
	}

	.CardsRow--contentWrap {
		margin: 75px auto auto;
		max-width: 430px;

		@include below-desktop {
			max-width: 100%;
			width: 100%;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.CardsRow--heading {
		font-family: $secondary-font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 38px;
		text-align: center;
		color: $c-blue-dark;
		text-transform: uppercase;
		padding-bottom: 50px;
	}

	.CardsRow--text {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		text-align: center;
		color: $c-gray-darker;
		padding-bottom: 60px;

		@supports (-webkit-touch-callout: none) {
			line-height: 42px;
		}
	}

	.CardsRow--background-image {
		position: absolute;
		top: 0;
		right: 0;
	}
}
