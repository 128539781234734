/* Testimonials Component Styles */
.Testimonials {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	text-align: center;

	.Slider--slide {
		padding: 0 ((2/12) * 100%);
		top: 50%;
		transform: translateY(-50%);
	}

	.flickity-viewport {
		z-index: 2;
	}

	@include tablet-portrait {
		.Slider--slide {
			padding: 0 ((1/12) * 100%);
		}
	}

	@include mobile {
		.Slider--slide {
			padding: 0 rem(24);
		}
	}
}

.Testimonials--background {
	position: relative;

	&::before,
	&::after {
		@include background;

		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		z-index: 0;
		width: calc(((100vw - #{rem($site-width)}) / 2) + #{rem($site-width * (2 / 12))});
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	@media (max-width: ($site-width + (2 * $side-padding))) {
		&::before,
		&::after {
			width: (3 / 12) * 100%;
		}
	}

	@include tablet {
		&::before,
		&::after {
			width: (2 / 12) * 100%;
		}
	}

	@include mobile {
		&::before,
		&::after {
			content: none;
		}
	}
}

.Testimonials--heading {
	@include space(margin-bottom, l);

	text-align: center;
}

.Testimonial--quote {
	@include space(margin-bottom, m);
	@include tx-title-xl;

	@include mobile {
		font-size: rem(30);
		line-height: 1.25;
	}
}

.Testimonials--cta {
	@include space(margin-top, l);
}

.Testimonial {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Testimonial--rating {
	@include space(margin-bottom, xs);
}

@include collapse-comp('Testimonials') {
	@include space(margin-top, xl, true);
}
