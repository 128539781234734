/* HeroImage Component Styles */
.HeroImage {
	position: relative;
	width: 100%;
	height: 80vh;
	max-height: rem(800);
	background-color: $c-blue-dark;

	@include tablet {
		height: 75vh;
	}

	@include tablet-portrait {
		height: 60vh;
	}

	@include mobile {
		height: 50vh;
	}
}

.HeroImage--filter {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.HeroImage--background {
	position: relative;
	width: 100%;
	height: 100%;

	.Image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover'; // stylelint-disable-line
		opacity: 0.5;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: radial-gradient(50% 100% at 0 0, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 71, 0) 100%),
			radial-gradient(50% 100% at 0 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
		opacity: 0.2;
	}
}

@supports (mix-blend-mode: screen) {
	.HeroImage--background {
		mix-blend-mode: screen;

		.Image {
			opacity: 1;
		}

		&::after {
			opacity: 1;
		}
	}
}

.HeroImage--content {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 50%;
	transform: translate(-50%, -50%);
	text-align: center;

	@include tablet-portrait {
		width: 66.66%;
	}

	@include mobile {
		padding-left: $side-padding;
		padding-right: $side-padding;
		width: 100%;
	}
}

// Don't use this at the moment because of performance issues.
.HeroImage--scrollIndicator {
	position: absolute;
	display: block;
	bottom: rem(-30);
	left: calc(50% - 1px);
	width: 1px;
	height: 60px;
	overflow: hidden;

	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 33%,
		$c-blue-neon 33%,
		$c-blue-neon 66%,
		rgba(0, 0, 0, 0) 66%
	);
	background-size: 200% 300%;
	animation: heroImage-scroll 2.5s $ease-in-out-cubic infinite;

	/*
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		animation-name: heroImage-scroll;
		animation-duration: 3s;
		animation-timing-function: ease-in-out;
		animation-delay: 1s;
		animation-iteration-count: infinite;
		background-color: $c-blue-neon;
	}
	 */
}
