/* TextImageTeaser Component Styles */
$textImageTeaser-overlap: 208px;
$textImageTeaser-overlap-tablet: 128px;
$textImageTeaser-overlap-mobile: 50px;

.TextImageTeaser {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	opacity: 0;

	&.show {
		opacity: 1;
	}
}

.TextImageTeaser--row {
	flex-direction: row-reverse;
	justify-content: flex-end;

	@include mobile {
		display: block;
	}
}

.TextImageTeaser--textColumn {
	@include grid-offset($cols: 1);
	@include grid-col($cols: 4);

	@include tablet {
		@include grid-col($cols: 5);
	}

	@include tablet-portrait {
		@include grid-offset($cols: 0);
		@include grid-col($cols: 6);
	}

	@include mobile {
		@include grid-col($cols: 12);
	}
}

.TextImageTeaser--content {
	display: flex;
	flex-direction: column;
	height: 100%;

	@include mobile {
		padding-top: rem(map_get($spaces-mobile, m));
	}
}

.TextImageTeaser--imageColumn {
	@include grid-offset($cols: 2);
	@include grid-col($cols: 5);

	@include tablet {
		@include grid-offset($cols: 1);
	}

	@include mobile {
		@include grid-col($cols: 12);
		@include grid-offset($cols: 0);
	}
}

.TextImageTeaser--image {
	margin-left: rem($gutter * -1);
	overflow: hidden;
	border-radius: 5px;

	@include mobile {
		margin-left: 0;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			padding-top: 100%;
		}
	}
}

.TextImageTeaser--imageInner {
	@include mobile {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.TextImageTeaser--title {
	@include space(margin-bottom, s);

	.color-scheme-light & {
		color: $c-blue-medium;
	}

	.color-scheme-dark & {
		color: $c-blue-neon;
	}
}

.TextImageTeaser--cta {
	@include space(margin-top, l);

	@include mobile {
		text-align: center;
	}
}

// Layout
.TextImageTeaser {
	padding-bottom: rem(map_get($spaces, xl));
	@include mobile {
		padding-bottom: rem(map_get($spaces-mobile, xl));
	}
}

.TextImageTeaser:not(:first-of-type) {
	padding-top: unset;
}

.TextImageTeaser:last-of-type {
	padding-bottom: 100px;
}

@include collapse-comp('TextImageTeaser', ':not(.TextImageTeaser)') {
	@include space(margin-top, xl, true);
}

// Variants
.TextImageTeaser.flipped {
	.TextImageTeaser--row {
		flex-direction: row;
		justify-content: flex-start;
	}

	.TextImageTeaser--imageColumn {
		@include grid-offset($cols: 0);
	}

	.TextImageTeaser--textColumn {
		@include grid-offset($cols: 2);

		@include tablet {
			@include grid-offset($cols: 1);
		}

		@include mobile {
			@include grid-offset($cols: 0);
		}
	}

	.TextImageTeaser--image {
		margin-left: 0;
		margin-right: rem($gutter * -1);

		@include mobile {
			margin-right: 0;

			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
