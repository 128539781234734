/* Panorama Component Styles */
.Panorama {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.Panorama--heading {
	@include space(margin-bottom, l);
}

.Panorama--app {
	position: relative;
	height: 80vh;
	max-height: rem(700);
	overflow: hidden;

	.Loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}

@include collapse-comp('Panorama') {
	@include space(margin-top, xl, true);
}
