/* Logo Component Styles */
.Logo {
	position: relative;
	display: block;
	height: rem(108);
	width: rem(204);
}

.Logo--text {
	position: absolute;
	top: 50%;
	left: rem(12);
	width: 100%;
	transform: translateY(-50%);
}

.Logo--racing path,
.Logo--unleashed path {
	opacity: 0;
}

.Logo--RU {
	width: rem(131);
	height: rem(108);
	background-repeat: no-repeat;
	background-image: url('../img/logo-sprite.png');
	background-size: (2160px / 2.59) (1800px / 2.59);
	background-position: 340px 280px;

	&[data-sprite='0'] {
		background-position: 340px 280px;
	}

	&[data-sprite='1'] {
		background-position: (-10px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='2'] {
		background-position: (-370px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='3'] {
		background-position: (-10px / 2.59) (-310px / 2.59);
	}

	&[data-sprite='4'] {
		background-position: (-370px / 2.59) (-310px / 2.59);
	}

	&[data-sprite='5'] {
		background-position: (-730px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='6'] {
		background-position: (-730px / 2.59) (-310px / 2.59);
	}

	&[data-sprite='7'] {
		background-position: (-10px / 2.59) (-610px / 2.59);
	}

	&[data-sprite='8'] {
		background-position: (-370px / 2.59) (-610px / 2.59);
	}

	&[data-sprite='9'] {
		background-position: (-730px / 2.59) (-610px / 2.59);
	}

	&[data-sprite='10'] {
		background-position: (-1090px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='11'] {
		background-position: (-1090px / 2.59) (-310px / 2.59);
	}

	&[data-sprite='12'] {
		background-position: (-1090px / 2.59) (-610px / 2.59);
	}

	&[data-sprite='13'] {
		background-position: (-10px / 2.59) (-910px / 2.59);
	}

	&[data-sprite='14'] {
		background-position: (-370px / 2.59) (-910px / 2.59);
	}

	&[data-sprite='15'] {
		background-position: (-730px / 2.59) (-910px / 2.59);
	}

	&[data-sprite='16'] {
		background-position: (-1090px / 2.59) (-910px / 2.59);
	}

	&[data-sprite='17'] {
		background-position: (-1450px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='18'] {
		background-position: (-1450px / 2.59) (-310px / 2.59);
	}

	&[data-sprite='19'] {
		background-position: (-1450px / 2.59) (-610px / 2.59);
	}

	&[data-sprite='20'] {
		background-position: (-1450px / 2.59) (-910px / 2.59);
	}

	&[data-sprite='21'] {
		background-position: (-10px / 2.59) (-1210px / 2.59);
	}

	&[data-sprite='22'] {
		background-position: (-370px / 2.59) (-1210px / 2.59);
	}

	&[data-sprite='23'] {
		background-position: (-730px / 2.59) (-1210px / 2.59);
	}

	&[data-sprite='24'] {
		background-position: (-1090px / 2.59) (-1210px / 2.59);
	}

	&[data-sprite='25'] {
		background-position: (-1450px / 2.59) (-1210px / 2.59);
	}

	&[data-sprite='26'] {
		background-position: (-10px / 2.59) (-1510px / 2.59);
	}

	&[data-sprite='27'] {
		background-position: (-370px / 2.59) (-1510px / 2.59);
	}

	&[data-sprite='28'] {
		background-position: (-730px / 2.59) (-1510px / 2.59);
	}

	&[data-sprite='29'] {
		background-position: (-1090px / 2.59) (-1510px / 2.59);
	}

	&[data-sprite='30'] {
		background-position: (-1450px / 2.59) (-1510px / 2.59);
	}

	&[data-sprite='31'] {
		background-position: (-1810px / 2.59) (-10px / 2.59);
	}

	&[data-sprite='32'] {
		background-position: (-1810px / 2.59) (-310px / 2.59);
	}
}

.Logo.default {
	.Logo--RU {
		background-position: (-1810px / 2.59) (-310px / 2.59);
	}

	.Logo--racing path,
	.Logo--unleashed path {
		opacity: 1;
	}
}
