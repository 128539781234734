@font-face {
	font-display: swap;
	font-family: 'Bai Jamjuree';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/BaiJamjuree-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/assets/fonts/BaiJamjuree-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	url('/assets/fonts/BaiJamjuree-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/assets/fonts/BaiJamjuree-Regular.svg#BaiJamjuree-Regular') format('svg'); /* Legacy iOS */
}

@font-face {
	font-display: swap;
	font-family: 'Bai Jamjuree';
	font-style: normal;
	font-weight: 500;
	src: local('Bai Jamjuree Medium'), local('BaiJamjuree-Medium'),
	url('/assets/fonts/BaiJamjuree-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/assets/fonts/BaiJamjuree-Medium.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	url('/assets/fonts/BaiJamjuree-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/assets/fonts/BaiJamjuree-Medium.svg#BaiJamjuree-Medium') format('svg'); /* Legacy iOS */
}

@font-face {
	font-display: swap;
	font-family: 'Bai Jamjuree';
	font-style: normal;
	font-weight: 600;
	src: local('Bai Jamjuree SemiBold'), local('BaiJamjuree-SemiBold'),
	url('/assets/fonts/BaiJamjuree-SemiBold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/assets/fonts/BaiJamjuree-SemiBold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	url('/assets/fonts/BaiJamjuree-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/assets/fonts/BaiJamjuree-SemiBold.svg#BaiJamjuree-SemiBold') format('svg'); /* Legacy iOS */
}

@font-face {
	font-display: swap;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	src: local('IBM Plex Sans'), local('IBMPlexSans'),
	url('/assets/fonts/IBMPlexSans.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/assets/fonts/IBMPlexSans.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 500;
	src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
	url('/assets/fonts/IBMPlexSans-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/assets/fonts/IBMPlexSans-Medium.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
