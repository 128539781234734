/* DiagonalTeaser Component Styles */
$diagonalTeaser-img-offset: 239px;
$diagonalTeaser-img-offset-tablet: 166px;

.DiagonalTeaser {
	background-color: $c-background;
	opacity: 0;
	overflow: hidden;

	&.show {
		opacity: 1;
	}

	@include tablet-portrait {
		padding-bottom: map_get($spaces-mobile, l);
	}
}

.DiagonalTeaser--row {
	@include tablet-portrait {
		display: block;
	}
}

.DiagonalTeaser--imgColumn {
	@include grid-col($cols: 6);

	@include tablet-portrait {
		@include grid-col($cols: 12);

		margin-bottom: map-get($spaces-mobile, m);
	}
}

.DiagonalTeaser--contentColumn {
	@include grid-col($cols: 4);
	@include grid-offset($cols: 2);

	@include tablet-portrait {
		@include grid-col($cols: 12);
		@include grid-offset($cols: 0);
	}
}

.DiagonalTeaser--img {
	position: relative;
	overflow: hidden;
	height: rem(700);
	/* stylelint-disable function-calc-no-invalid */
	margin-right: calc(-#{rem($diagonalTeaser-img-offset)} - #{rem($gutter)});
	/* stylelint-enable function-calc-no-invalid */
	margin-left: calc((100vw - #{$site-width}) / -2);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: -100%;
		bottom: 0;
		width: 100%;
		background-color: $c-background;
		transform: skewX(-20deg);
		transform-origin: 0 0;
	}

	.Image {
		object-fit: cover;
		height: 100%;
		width: 100%;
		font-family: 'object-fit: cover'; /* stylelint-disable-line */
	}

	@media (max-width: ($site-width + (2 * $side-padding))) {
		margin-left: -$side-padding;
	}

	@include tablet {
		height: rem(500);
		/* stylelint-disable function-calc-no-invalid */
		margin-right: calc(-#{rem($diagonalTeaser-img-offset-tablet)} - #{rem($gutter)});
		/* stylelint-enable function-calc-no-invalid */
	}

	@include tablet-portrait {
		height: rem(320);
		margin: 0 rem($side-padding * -1);

		&::before {
			content: none;
		}
	}
}

.DiagonalTeaser--content {
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;
	z-index: 1;
}

.DiagonalTeaser--title {
	@include space(margin-bottom, m);
}

.DiagonalTeaser--cta {
	@include space(margin-top, l);
	text-align: right;

	@include tablet-portrait {
		text-align: center;
	}

	@include mobile {
		text-align: center;
	}
}

// Variantss
.DiagonalTeaser.flipped {
	.DiagonalTeaser--row {
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	.DiagonalTeaser--contentColumn {
		@include grid-offset($cols: 0);
	}

	.DiagonalTeaser--imgColumn {
		@include grid-offset($cols: 2);

		@include tablet-portrait {
			@include grid-offset($cols: 0);
		}
	}

	.DiagonalTeaser--img {
		/* stylelint-disable function-calc-no-invalid */
		margin-left: calc(-#{rem($diagonalTeaser-img-offset)} - #{rem($gutter)});
		/* stylelint-enable function-calc-no-invalid */
		margin-right: calc((100vw - #{$site-width}) / -2);

		&::before {
			right: auto;
			left: -100%;
			transform-origin: 0 100%;
		}

		@media (max-width: ($site-width + (2 * $side-padding))) {
			margin-right: -$side-padding;
		}

		@include tablet {
			/* stylelint-disable function-calc-no-invalid */
			margin-left: calc(-#{rem($diagonalTeaser-img-offset-tablet)} - #{rem($gutter)});
			/* stylelint-enable function-calc-no-invalid */
		}

		@include tablet-portrait {
			margin: 0 rem($side-padding * -1);
		}
	}
}
