/* Sliderv3 Component Styles */
.Sliderv3 {
	opacity: 0;

	.flickity-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(44);
		height: rem(44);
		border: 2px solid #000047;
		//box-shadow: 0 10px 20px 0 rgba($c-blue-neon, 0.15);
		//background-color: $c-white;
		z-index: 3;

		&:disabled {
			display: none;
		}
	}

	.flickity-prev-next-button {
		top: calc(50% - #{rem(64/2)});
		transform: none;
	}

	.flickity-prev-next-button .flickity-button-icon {
		position: static;
		width: rem(12);
		height: rem(12);
		fill: $c-ink;
	}

	.flickity-prev-next-button.next {
		right: rem(-60);
	}

	.flickity-prev-next-button.previous {
		left: rem(-60);
	}

	&.flickity-enabled {
		opacity: 1;
	}

	.flickity-page-dots {
		& > .dot {
			background: $c-gray-light;
			height: 8px;
			margin: 0 4px;
			opacity: 1;
			width: 8px;

			&.is-selected {
				background: $c-blue-dark;
			}
		}
	}

	@include desktop {
		.flickity-prev-next-button.next {
			right: rem(-35);
		}

		.flickity-prev-next-button.previous {
			left: rem(-60);
		}
	}

	@include tablet-portrait {
		.flickity-button {
			width: rem(40);
			height: rem(40);
		}

		.flickity-prev-next-button {
			top: calc(50% - #{rem(40/2)});
			transform: none;
		}
	}
}

.Sliderv3--slide {
	width: 100%;
	margin-right: rem(30);
	.Button {
		display: none;
	}

	&.is-selected {
		.Button {
			display: inline-block;
		}
	}
}
