.Newpages{
    top: 117px;
    padding: 48px; 
    width: 959px;
    height: 176px;
    gap: 16px;
    display: flex;
   
    
    
  
    
    &--wrapper{
        width: 959px;
        height: 176px;
        gap: 16px;
    }
    
    &--header{
        width: 309px;
        height: 176px;
        padding: 24px;
        gap: 16px;
        border-radius: 16px;
        background-color:#EFEFF0 !important ;
    }

    &--content{
        width: 261px;
        height: 68px;
        gap: 4px;
    }

    &--smalltitle {
        width: 261px;
        height: 24px;
        gap: 0; 
        // opacity: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;      
        text-align: left;
}

    &--metatitle {
        width: 261px;
        height: 40px;
        gap: 0; 
        opacity: 1; 
        
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        // text-align: left;
    }
    &--img {
        width: 44px; 
        height: 44px; 
        padding: 10px; 
        gap: 8px; 
        border-radius: 100px; 
        border: 1px;    
    }

    ////////////////COMPONENT-2//////////////////////
    &--header2{
        width: 309px;
        height: 176px;
        padding: 24px;
        gap: 16px;
        border-radius: 16px;
        background-color:#EFEFF0 !important ;
    }

    &--content2{
        width: 261px;
        height: 68px;
        gap: 4px;
    }

    &--smalltitle2 {
        width: 261px;
        height: 24px;
        gap: 0; 
        // opacity: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;      
        text-align: left;
}

    &--metatitle2 {
        width: 261px;
        height: 40px;
        gap: 0; 
        opacity: 1; 
        
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        // text-align: left;
    }
    &--img2 {
        width: 44px; 
        height: 44px; 
        padding: 10px; 
        gap: 8px; 
        border-radius: 100px; 
        border: 1px;    
    }
    

    //////////////////////////////COMPONENT-3///////////////////////////////
    &--header3{
        width: 309px;
        height: 176px;
        padding: 24px;
        gap: 16px;
        border-radius: 16px;
        background-color:#EFEFF0 !important ;
    }

    &--content3{
        width: 261px;
        height: 68px;
        gap: 4px;
    }

    &--smalltitle3 {
        width: 261px;
        height: 24px;
        gap: 0; 
        // opacity: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;      
        text-align: left;
}

    &--metatitle3 {
        width: 261px;
        height: 40px;
        gap: 0; 
        opacity: 1; 
        
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        // text-align: left;
    }
    &--img3 {
        width: 44px; 
        height: 44px; 
        padding: 10px; 
        gap: 8px; 
        border-radius: 100px; 
        border: 1px;    
    }
}