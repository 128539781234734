.individualsection01 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto 32px;
		text-align: center;
	}

	&--small-title {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 18px;
		}
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px !important;
		margin-bottom: 16px;
		text-align: center;

		&:last-child {
			margin: 0;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 24px;
		}
	}

	&--cards {
		display: flex;
		gap: 16px;
		justify-content: center;
		margin-top: 32px;

		@include mobile-media() {
			flex-direction: column;
		}
	}
}

.individualsection01--card {
	background-color: $c-white2;
	border-radius: 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
	padding: 24px;
	text-align: left;

	&-title {
		color: $c-blue-dark;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		margin-bottom: 4px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 16px;
			line-height: 26px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 22px;
		}
	}

	&-icon {
		cursor: pointer;
		color: $c-blue-medium;
		margin-top: auto;

		&,
		& svg {
			height: 44px;
			width: 44px;
		}

		@include mobile-media() {
			@include reset-scale();

			& svg {
				overflow: visible;
			}
		}
	}
}
