/* LangSelect Component Styles */
.LangSelect {
	display: inline-block;
	position: relative;
	color: rgba($c-white, 0.6);
}

.LangSelect--current {
	text-transform: uppercase;
	transition: opacity $transition-duration-fast $ease-out-cubic;

	&::after {
		content: '';
		display: block;
		bottom: rem(-8);
		left: 50%;
		position: absolute;
		width: rem(8);
		height: rem(8);
		border-top: 1px solid rgba($c-white, 0.2);
		border-left: 1px solid rgba($c-white, 0.2);
		background-color: $c-blue-dark;
		transform: translateY(6px) translateX(-50%) rotate(45deg);
		z-index: 11;
		opacity: 0;
		transition: opacity $transition-duration-default $ease-out-cubic 0.1s,
			transform $transition-duration-default $ease-out-cubic 0.1s;
	}

	.SvgIcon {
		transition: transform $transition-duration-fast $ease-out-cubic;
	}

	.SvgIcon,
	.LangSelect--link {
		vertical-align: middle;
	}

	.SvgIcon > svg {
		fill: rgba($c-white, 0.6);
	}

	.LangSelect[data-state='open'] & {
		&::after {
			transform: translateY(0) translateX(-50%) rotate(45deg);
			opacity: 1;
		}

		.SvgIcon {
			opacity: 0.6;
			transform: rotateX(180deg);
		}
	}
}

.LangSelect--list {
	@include list-reset;

	position: absolute;
	padding: rem(20) rem(30);
	top: rem(36);
	left: 0;
	background-color: $c-blue-dark;
	min-width: rem(142);
	box-shadow: 0 rem(10) rem(20) 0 rgba($c-blue-neon, 0.15);
	border-radius: 4px;
	border: 1px solid rgba($c-white, 0.2);
	text-align: left;
	z-index: -1;
	opacity: 0;
	transform: translateY(6px);
	transition: opacity $transition-duration-default $ease-out-cubic 0.1s,
		transform $transition-duration-default $ease-out-cubic 0.1s, z-index 0.1ms linear 0.35s;

	.LangSelect[data-state='open'] & {
		z-index: 10;
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
		transition: opacity $transition-duration-default $ease-out-cubic 0.1s,
			transform $transition-duration-default $ease-out-cubic 0.1s;
	}
}

.LangSelect--item {
	@include space(margin-bottom, xs);

	&:last-child {
		margin-bottom: 0;
	}
}

.LangSelect--link {
	white-space: nowrap;
	display: inline-block;

	&.is-active {
		position: relative;
		color: $c-white;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: rem(-20);
			transform: translateY(-50%);
			width: rem(10);
			height: rem(10);
			border-radius: 100%;
			background-color: $c-blue-neon;
		}
	}
}
