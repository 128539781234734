/* ImageSlider Component Styles */
.ImageSlider {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	.flickity-prev-next-button {
		top: calc(50% - #{rem(30 + (64/2))});
	}

	@include tablet-portrait {
		.flickity-prev-next-button {
			top: calc(50% - #{rem(30 + (40/2))});
		}
	}
}

.ImageSlide {
	position: relative;
}

.ImageSlide--caption {
	@include space(margin-top, s);
}

.ImageSlide--img {
	overflow: hidden;
	border-radius: 5px;
}

@include collapse-comp('ImageSlider') {
	@include space(margin-top, xl, true);
}
