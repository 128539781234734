/* Component container */
.eventstestimonialgrid {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--content {
		position: relative;
		width: 100%;

		&::after {
			background: linear-gradient(180deg, rgba(247, 247, 248, 0) 0%, #f7f7f8 75.85%);
			bottom: -44px;
			content: '';
			height: 244px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&[data-expanded='1']::after {
			content: unset;
		}
	}

	&--buttons {
		align-items: center;
		display: flex;
		gap: 16px;
		justify-content: center;
		padding-top: 16px;

		@include mobile-media() {
			flex-direction: column;
		}

		&::after {
			content: '';
			background: linear-gradient(180deg, rgba(247, 247, 248, 0) 0%, #f7f7f8 75.85%);
		}

		& > #eventsTestimonialGridShowMore {
			svg {
				transform: rotate(90deg);
			}

			&[data-expanded='1'] svg {
				transform: rotate(-90deg);
			}
		}
	}
}
