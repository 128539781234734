.tx-center {
	text-align: center;
}

.tx-right {
	text-align: right;
}

.tx-left {
	text-align: left;
}
