$mq-mobile-portrait: 400px !default;
$mq-mobile-narrow: 580px !default;
$mq-mobile: 750px !default;
$mq-tablet-portrait: 1000px !default;
$mq-tablet: 1200px !default;
$mq-desktop: 1400px !default;
$mq-desktop-large: 1600px;

@mixin mobile-portrait {
	@media (max-width: $mq-mobile-portrait) {
		@content;
	}
}

@mixin above-mobile-portrait {
	@media (min-width: ($mq-mobile-portrait + 1px)) {
		@content;
	}
}

@mixin mobile-narrow {
	@media (max-width: $mq-mobile-narrow) {
		@content;
	}
}

@mixin above-mobile-narrow {
	@media (min-width: ($mq-mobile-narrow + 1px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $mq-mobile) {
		@content;
	}
}

@mixin above-mobile {
	@media (min-width: ($mq-mobile + 1px)) {
		@content;
	}
}

@mixin tablet-portrait {
	@media (max-width: $mq-tablet-portrait) {
		@content;
	}
}

@mixin above-tablet-portrait {
	@media (min-width: ($mq-tablet-portrait + 1px)) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $mq-tablet) {
		@content;
	}
}

@mixin above-tablet {
	@media (min-width: ($mq-tablet + 1px)) {
		@content;
	}
}

@mixin below-desktop {
	@media (max-width: ($mq-tablet)) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: $mq-desktop) {
		@content;
	}
}

@mixin ios-tablet {
	@supports (-webkit-touch-callout: none) {
		@media (min-device-width: 768px) and (max-device-width: 1025px){
			@content;
		}
	}
}

@mixin ios-mobile-portrait {
	@supports (-webkit-touch-callout: none) {
		@media (max-device-width: $mq-mobile-portrait){
			@content;
		}
	}
}

@mixin above-desktop {
	@media (min-width: ($mq-desktop + 1px)) {
		@content;
	}
}

@mixin desktop-large {
	@media (max-width: $mq-desktop-large) {
		@content;
	}
}

// Detect screens that are very wide
@mixin letterbox {
	@media (min-aspect-ratio: 16/10) {
		@content;
	}
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
	@media only screen and (min-resolution: 144dpi) {
		@content;
	}
}

// printer
@mixin print {
	@media print {
		@content;
	}
}
// printer
@mixin not-print {
	@media only screen {
		@content;
	}
}
