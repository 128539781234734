.site-header {
	.details.no-hero &,
	.landingpage & {
		height: rem($header-height);
		background-color: $c-blue-dark;
	}

	@include tablet {
		height: rem($header-height-mobile);
		background-color: $c-blue-dark;

		.details.no-hero &,
		.landingpage & {
			height: rem($header-height-mobile);
		}
	}
}
