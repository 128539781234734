/* Richtext Component Styles */
.richtext {
	@include tx-body;
	p {
		@include p;
	}

	a {
		@include link;
	}

	ul {
		@include ul;
	}

	ol {
		@include ol;
	}

	h2 {
		@include h2;
	}

	h3 {
		@include h3;
	}

	> *:first-child {
		margin-top: 0 !important;
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}
}
