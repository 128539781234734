$mobile-small-mw: 375px;
$mobile-mw: 500px;
$mobile-large-mw: 625px;
$tablet-mw: 786px;
$desktop-mw-small: 1024px;

@mixin mobile-small-media {
	@media (max-width: $mobile-small-mw) {
		@content;
	}
}

@mixin mobile-media {
	@media (max-width: $mobile-mw) {
		@content;
	}
}

@mixin mobile-large-media {
	@media (max-width: $mobile-large-mw) {
		@content;
	}
}

@mixin tablet-media {
	@media (max-width: $tablet-mw) {
		@content;
	}
}

@mixin desktop-small-media {
	@media (max-width: $desktop-mw-small) {
		@content;
	}
}

@mixin reset-scale {
	@media (max-device-width: 500px) {
		// Scale down the content
		@media (-webkit-min-device-pixel-ratio: 1.09) {
			zoom: 1.09;
		}

		@media (-webkit-min-device-pixel-ratio: 1.1) {
			zoom: 1.1;
		}

		@media (-webkit-min-device-pixel-ratio: 1.2) {
			zoom: 1.2;
		}

		@media (-webkit-min-device-pixel-ratio: 1.25) {
			zoom: 1.25;
		}

		@media (-webkit-min-device-pixel-ratio: 1.5) {
			zoom: 1.5;
		}
	}
}
