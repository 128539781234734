/* NextArticleTeaser Component Styles */
/* stylelint-disable no-duplicate-selectors */
.NextArticleTeaser {
	@include color-scheme-dark;

	position: relative;
	display: flex;
	width: 100%;
	height: rem(288);
	overflow: hidden;
	align-items: center;

	&:hover {
		.NextArticleTeaser--background {
			transform: scale(1.05);
		}
	}

	@include tablet {
		height: rem(248);
	}

	@include tablet-portrait {
		height: rem(228);
	}
}

.NextArticleTeaser--background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $c-white;
	}

	.Image {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		font-family: 'object-fit: cover; object-position: center;'; // stylelint-disable-line
		opacity: 0.7;
	}
}

.NextArticleTeaser--inner {
	width: 100%;
}

.NextArticleTeaser--line {
	position: relative;
	display: inline-block;
	margin: 0 rem(20);
	height: 1px;
	width: rem(130);
	overflow: hidden;
	background-color: $c-blue-neon;
	transform-origin: 0% 50%;
	transform: scaleX(0);
}

.NextArticleTeaser--next {
	opacity: 0;
}

.NextArticleTeaser--title {
	opacity: 0;

	@include mobile {
		margin-top: rem(map_get($spaces-mobile, s));
		display: block;
	}
}

// Animation
.NextArticleTeaser--background {
	transition: opacity 2.25s ease-out, transform 1s ease-out; // second transition is for hover animation
}

.NextArticleTeaser--next {
	transition: opacity 1s ease-out 0.5s;
}

.NextArticleTeaser--line {
	transition: transform 1s ease-out 0.75s;
}

.NextArticleTeaser--title {
	transition: opacity 1s ease-out 1s;
}

// States
.NextArticleTeaser[data-state='visible'] {
	.NextArticleTeaser--background {
		opacity: 0.25;
	}

	.NextArticleTeaser--next {
		opacity: 1;
	}

	.NextArticleTeaser--line {
		transform: scaleX(1);
	}

	.NextArticleTeaser--title {
		opacity: 1;
	}
}

@supports (mix-blend-mode: screen) and (mix-blend-mode: luminosity) {
	.NextArticleTeaser--background {
		mix-blend-mode: screen;

		.NextArticleTeaser[data-state='visible'] & {
			opacity: 0.347;
		}
	}

	.NextArticleTeaser--background .Image {
		mix-blend-mode: luminosity;
		opacity: 1;
	}
}
