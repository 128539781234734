/* NewsSlider Component Styles */
body.newsletter-popup-open {
	overflow: hidden;
	margin-right: 18px;

	.Header--main {
		margin-right: 18px;
	}

	@include below-desktop {
		position: fixed;
		margin-right: 0;
	}
}

.NewsSlider {
	@include color-scheme-light;

	@include mobile {
		height: auto;
	}

	.NewsSlider--title {
		font-family: $secondary-font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 38px;
		text-align: center;

		@include space(padding-top, l);
		@include space(padding-bottom, l);

		color: #000080;
		text-transform: uppercase;

		@include mobile {
			padding-top: 70px;
			padding-bottom: 60px;
			font-size: 30px;
			line-height: 38px;
		}
	}

	.contentWrap--spacer {
		height: 40px;

		@include mobile {
			height: 28px;
		}
	}

	.contentWrap {
		padding-right: 20px;
		padding-left: 20px;

		@include mobile {
			padding-left: 48px;
			padding-right: 48px;
		}
	}

	.Slider .flickity-prev-next-button {
		top: 121.5px;
		-webkit-transform: none;
		transform: none;
		background: #FFFFFF;
		border: 2px solid #00FFFF;
		box-shadow: 0 10px 20px rgba(0, 255, 255, 0.15);
		height: 64px;
		width: 64px;

		@include mobile {
			top: 117px;
			height: 50px;
			width: 50px;
			opacity: 0.7;
		}

		&:hover {
			background: $c-blue-medium;

			.flickity-button-icon {
				fill: #ffffff;
			}

			@include mobile {
				opacity: 1;
			}
		}

		&.previous {
			left: -90px;
			@include below-desktop {
				left: 0;
			}

			@include mobile {
				left: 12px;
			}

			.flickity-button-icon {
				right: 1px;
			}
		}

		&.next {
			right: -90px;
			@include below-desktop {
				right: 0;
			}

			@include mobile {
				right: 12px;
			}

			.flickity-button-icon {
				left: 1px;
			}
		}

		.flickity-button-icon {
			position: relative;
			height: 12px;
			width: 12px;
			fill: #70707D;
			top: 0;
			left: 0;

			@include mobile {
				height: 15px;
				width: 15px;
			}

		}
	}

	.Slider {
		opacity: 1 !important;
		max-width: 1105px;
		margin: auto;

		@include mobile {
			width: 100%;
			margin: auto;
		}

		.Slider--slide {
			height: 520px;
			margin-right: 36px;
			border-radius: 5px;
			background-color: transparent;
			transition: all $transition-duration-slow $ease-out-quad;

			@supports (-webkit-touch-callout: none) {
				height: 690px;
			}

			@include tablet {
				height: 540px;

				@supports (-webkit-touch-callout: none) {
					height: 720px;
				}
			}

			@include mobile {
				height: 610px;
				width: 100%;
				margin-right: 0;

				@supports (-webkit-touch-callout: none) {
					//height: 810px;
				}
			}

			@include above-mobile {
				width: 30%;
			}

			@include above-tablet {
				width: 345px;

				&:hover {
					background-color: $c-blue-dark;

					.NewsSlide--cta {
						opacity: 1;
					}

					.NewsSlide--title, .NewsSlide--cta {
						color: $c-blue-neon;
					}

					.NewsSlide--text, .NewsSlide--date {
						color: $c-white;
					}
				}
			}
		}
	}

	.flickity-page-dots {
		@include mobile {
			bottom: -30px;
		}
	}

	.NewsSlide--date {
		font-family: $base-font-family;
		font-size: 14px;
		line-height: 21px;
		color: $c-blue-medium;
		font-weight: 500;
		transition: color $transition-duration-slow $ease-out-quad;
	}

	.NewsSlide--title {
		margin-top: 24px;
		font-family: $secondary-font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		display: flex;
		align-items: center;
		color: $c-blue-medium;
		transition: color $transition-duration-slow $ease-out-quad;

		@supports (-webkit-touch-callout: none) {
			line-height: 42px;
		}
	}

	.NewsSlide--text {
		margin-top: 24px;
		font-family: $base-font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #515050;
		transition: color $transition-duration-slow $ease-out-quad;

		@supports (-webkit-touch-callout: none) {
			line-height: 42px;
		}
	}

	.NewsSlide--cta {
		cursor: pointer;
		font-family: $base-font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: $c-blue-medium;
		text-transform: uppercase;
		position: relative;
		top: 15px;
		opacity: 0;
		transition: all $transition-duration-fast $ease-out-quad;

		@include tablet {
			opacity: 1;
		}

		@include mobile {
			opacity: 1;
		}
	}

	.NewsSlide--img--background {
		width: 100%;
		height: 185px;
		display: flex;
		align-items: center;
		overflow: hidden;
		border-radius: 5px;

		@include mobile {
			height: 263px;
			border-radius: 0;
		}

		img {
			flex-shrink: 0;
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
		}
	}

	.NewsSlider--newsletter-cta {
		margin-top: 80px;
		padding-bottom: 100px;

		.row {
			margin: 0 !important;
		}

		.col-6 {
			padding-left: 10px;
			padding-right: 10px;
		}

		.row > div:first-child {
			text-align: right;
		}

		@include mobile {
			margin-top: 90px;
			padding-bottom: 60px;

			.row > div:first-child {
				order: 2;
			}

			.row > div {
				text-align: center !important;
			}
		}

		button {
			display: inline-block;

			&.newsletter {
				white-space: nowrap;
				background: #FFFFFF;
				padding: 13px 32px;
				gap: 10px;
				border: 2px solid $c-blue-medium;
				border-radius: 30px;
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;

				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;

				color: $c-blue-medium;
			}

		}

		a.more-news {
			white-space: nowrap;
			position: absolute;
			padding: 16px 31px;
			gap: 10px;

			background: $c-blue-medium;
			box-shadow: 0 14px 40px rgba(62, 84, 185, 0.2);
			border-radius: 30px;

			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;

			color: #00FFFF;

			@include mobile {
				position: relative;
				margin-bottom: 20px;
				display: inline-block;
			}
		}
	}

	.NewsSlider--popup {
		display: none;
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		z-index: 999999;
		font-family: $secondary-font-family;
		color: $c-white;
		overflow-y: scroll;
		opacity: 0;
		transition: opacity $transition-duration-slow $ease-out-quad 0.2s;

		&::before {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background: black;
			opacity: 0.5;
		}

		&.active {
			opacity: 1;
		}

		@include below-desktop {
			position: fixed;
		}

		span.semi-bold-font-weight {
			font-weight: 600 !important;
		}

		.NewsSlider--popup-content {
			background: linear-gradient(90deg, #000047 -1.57%, rgba(0, 0, 71, 0.22) 81.89%);
			padding: 50px 100px;
			width: auto;
			margin: auto;
			vertical-align: center;
			align-self: center;
			position: relative;

			&::after {
				content: ' ';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: linear-gradient(90deg, #000047 -1.57%, rgba(0, 0, 71, 0.22) 81.89%);
				z-index: -1;
			}

			@include mobile {
				width: 100%;
				padding: 100px 50px 50px 50px;
				background: $c-blue-dark;
			}
		}

		.Input:not(:first-child) {
			padding-top: 30px;
		}

		.Input--label {
			color: $c-white;
		}

		.Input--decoration {
			background-color: $c-blue-neon;
		}

		.ContactForm {
			padding: 0;
			text-align: left;

			@include mobile {
				padding-top: 100px;
			}
		}

		.ContactForm--message {
			margin-bottom: 30px;
		}

		.NewsSlider--popup-heading {
			font-style: italic;
			font-weight: 400;
			font-size: 52px;
			line-height: 50px;
			text-transform: uppercase;
			padding-bottom: 60px;
			z-index: 1;

			@include mobile {
				font-style: normal;
				font-weight: 400;
				font-size: 52px;
				line-height: 50px;
				padding-bottom: 50px;

				@supports (-webkit-touch-callout: none) {
					line-height: 67px;
				}
			}
		}

		.NewsSlider--popup-content-wrap {
			z-index: 1;
			position: relative;
		}

		.NewsSlider--popup-text {
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			max-width: 390px;
			z-index: 1;

			@include mobile {
				font-weight: 400;
				font-size: 17px;
				line-height: 24px;
				text-align: center;
				margin: auto;

				@supports (-webkit-touch-callout: none) {
					line-height: 32px;
				}
			}
		}

		.ContactForm--row {
			max-width: 335px;
		}

		.ContactForm--button--container {
			padding-top: 30px;
			padding-bottom: 40px;
			right: 80px;
			position: relative;


			font-family: $base-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: $c-white;
		}

		.NewsSlider--popup-background {
			top: 0;
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			z-index: -1;

			img {
				top: 0;
				width: 100%;
				height: 100%;
				position: relative;
				display: block;
			}

			@include mobile {
				display: none;
			}
		}

		.NewsSlider--popup-form-note {
			a {
				text-decoration: underline;
				text-decoration-color: $c-blue-neon;
				text-decoration-thickness: 2px;
				text-underline-offset: 2px;
			}
		}

		.NewsSlider--close {
			position: absolute;
			height: 25px;
			width: 25px;
			top: 20px;
			right: 25px;
			cursor: pointer;
		}

		.NewsSlider--popup-form-note {
			margin-left: -50px;

			@include mobile {
				margin-left: 0;
			}
		}

		.NewsSlider--text-overlay {
			font-style: italic;
			font-weight: 600;
			font-size: 120px;
			line-height: 92px;

			display: flex;
			align-items: center;
			text-transform: uppercase;
			text-align: left;
			mix-blend-mode: normal;
			opacity: .4;
			width: 1200px;

			-webkit-text-fill-color: transparent;
			-webkit-text-stroke: 1px $c-blue-neon;
			z-index: 0;
			position: absolute;
			left: 10px;
			bottom: auto;
			top: 100px;

			@include mobile {
				display: none;
			}
		}
	}

	&.dark-blue {
		.ContactForm--button--container:not(.mobile-only) {
			@include mobile {
				display: none;
			}
		}

		.ContactForm--button--container.mobile-only {
			display: none;
			width: 100%;
			margin: auto;
			text-align: center;
			right: auto;
			left: auto;

			@include mobile {
				display: block;
			}
		}
	}
}
