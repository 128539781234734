.individualsection03 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--main-title {
		color: $c-white !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin: 0 auto 32px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--cards {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, 1fr);

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--action {
		display: flex;
		justify-content: center;
		margin-top: 32px;
	}
}

.individualsection03--card {
	background-color: $c-background;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	padding: 24px 24px 32px 24px;
	position: relative;

	&-title {
		color: $c-blue-dark !important;
		font-size: 22px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 8px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&-list {
		list-style-type: disc;
		padding-left: 22px;
		margin-bottom: 16px;

		& > li {
			color: $c-blue-dark !important;
			font-size: 14px;
			line-height: 24px;
		}

		@include mobile-media() {
			@include reset-scale();

			& > li {
				font-size: 12px;
				line-height: 22px;
			}
		}
	}

	&-action {
		bottom: 24px;
		color: $c-blue-medium;
		cursor: pointer;
		transform-origin: center center;
		transform: rotate(-135deg);
		position: absolute;
		right: 24px;

		@include mobile-media() {
			@include reset-scale();
			top: 24px;
		}

		&,
		& svg {
			height: 44px;
			width: 44px;
		}
	}

	&-times {
		color: $c-blue-dark !important;
		display: flex;
		flex-wrap: wrap;
		gap: 8px 16px;
		margin-top: auto;
	}

	&-time {
		display: flex;
		font-size: 14px;
		gap: 4px;
		line-height: 24px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 22px;
		}

		&-icon {
			color: $c-blue-dark;
			margin-top: 3px;

			&,
			& svg {
				height: 16px;
				width: 16px;
			}
		}
	}

	&-action ~ &-times {
		margin-right: 60px;

		@include mobile-media() {
			margin-right: unset;
		}
	}

	&-action ~ &-title {
		@include mobile-media() {
			margin-right: 60px;
		}
	}
}
