.Button {
	@include tx-title-xs;

	align-items: center;
	position: relative;
	display: inline-flex;
	gap: 8px;
	justify-content: center;
	padding: rem(16) rem(30);
	border-radius: 100px;
	color: #fff;
	text-align: center;
	border-color: #fff;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $c-blue-dark;
		border: 2px solid $c-blue-dark;
		border-radius: 100px;
		border-color: #fff;
		z-index: 0;
		transition: border-color $transition-duration-slower $ease-out-cubic,
			box-shadow $transition-duration-slower $ease-out-cubic,
			background-color $transition-duration-slower $ease-out-cubic;
	}

	&.disabled,
	&:disabled {
		&::after {
			box-shadow: none;
		}

		opacity: 0.35;
		pointer-events: none;
		cursor: none;
	}

	span.SvgIcon {
		height: 24px;
		position: relative;
		width: 24px;
		z-index: 1;
	}
}

.Button--text {
	position: relative;
	display: block;
	z-index: 1;
	text-shadow: none;
	line-height: 20px;
	font-weight: 500;

	&::after {
		display: none;
	}
}

.Button.primary-light {
	&::after {
		background-color: $c-blue-medium;
		border-color: $c-blue-medium;
	}
}

.Button.secondary {
	background-color: $c-white;
	color: $c-blue-dark;

	&::after {
		background-color: transparent;
		border: 2px solid #000047;
	}
}

.Button.text-white {
	color: $c-white;
}

.Button.transparent {
	background-color: transparent;

	&::after {
		background-color: transparent;
		top: 1px;
		border: 2px solid $c-blue-dark;
	}

	&:hover {
		&::after {
			background-color: transparent;
		}
	}

	&.white::after {
		border-color: $c-white;
	}

	&.neon {
		color: $c-blue-neon;

		&::after {
			border-color: $c-blue-neon;
		}
	}
}

.Button.small {
	@include button-small;
}

.Button.full-width {
	width: 100%;
}

.Button.reset-scale {
	@include mobile-media() {
		@include reset-scale();
	}
}

.Dropdown {
	background-color: $c-white;
	border-radius: 8px;
	height: 0px;
	left: 0;
	list-style: none;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 110%;
	transform: translateY(6px);
	transition: opacity $transition-duration-default $ease-out-cubic 0.1s,
		transform $transition-duration-default $ease-out-cubic 0.1s, z-index 0.1ms linear 0.35s;
	z-index: -1;

	&[data-state='open'] {
		height: auto;
		opacity: 1;
		transform: translateY(0);
		transition: opacity $transition-duration-default $ease-out-cubic 0.1s,
			transform $transition-duration-default $ease-out-cubic 0.1s;
		z-index: 2;
	}

	&:hover > &--item {
		opacity: 0.5;
	}

	&--item {
		cursor: pointer;

		&:hover {
			opacity: 1 !important;
		}
	}

	&--link {
		color: $c-blue-dark;
		display: block;
		font-weight: 500 !important;
		padding: 9px 18px;
		text-align: start;
	}

	&--item:first-child > &--link {
		padding-top: 18px;
	}

	&--item:last-child > &--link {
		padding-bottom: 18px;
	}
}
.Button.secondary-blue {
	background-color: $c-white; // or #fff
	color: $c-blue-medium; // or the specific blue shade you want
	border-color: $c-blue-medium; // or the specific blue shade you want

	&::after {
		background-color: transparent;
		border-color: $c-blue-medium;
	}

	// Style the icon inside this button variant
	.SvgIcon {
		fill: $c-blue-medium !important; // Set the SVG icon color to blue
	}

	&:hover {
		&::after {
			background-color: $c-blue-neon; // Optional: Change background color on hover
			border-color: $c-blue-neon; // Optional: Change border color on hover
		}

		// Change icon color on hover, if needed
		.SvgIcon {
			fill: $c-blue-neon; // Optional: Change SVG icon color on hover
		}
	}
}
