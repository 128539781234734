@mixin link {
	@include tx-body;

	position: relative;
	display: inline-block;
	color: $c-ink;
	transition: color $transition-duration-default $ease-out-cubic;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $c-blue-medium;
	}

	&:hover {
		color: $c-blue-medium;
	}

	.color-scheme-dark & {
		color: $c-white;

		&::after {
			background-color: $c-blue-neon;
		}
	}
}
