.video-background-component {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 1);

	@include mobile-media() {
		height: 100%;
		min-height: 100vh;
	}
}

.video-background-component .Full--video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-background-component .Full--video--background {
	width: 100%;
	height: 100%;
}

.video-background-component .Full--video--background video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.62;
}

.video-background-component .VideoBg--header {
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 634px;
	text-align: center;
	padding: 140px 16px;
}

.video-background-component .VideoBg--small-title {
	color: $c-white;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 8px;
	text-transform: uppercase;

	@include mobile-media() {
		@include reset-scale();

		font-size: 12px;
		line-height: 20px;
	}
}

.video-background-component .VideoBg--main-title {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 50px;
	font-weight: 500;
	line-height: 62px;
	color: $c-white;
	margin-bottom: 24px;

	@include mobile-media() {
		@include reset-scale();

		font-size: 48px;
		line-height: 60px;
	}
}

.VideoBg--wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
