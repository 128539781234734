.corporate-section {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-white !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 32px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--content {
		display: flex;
		gap: 16px;

		@include mobile-media() {
			flex-direction: column;
		}
	}

	&--right,
	&--left {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 16px;
	}

	&--right > &--image-container {
		@include mobile-media() {
			order: 3;
		}
	}

	&--image-container {
		border-radius: 16px;
		overflow: hidden;
		flex-grow: 1;
		max-height: 450px;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--footer {
		display: flex;
		justify-content: center;
		margin-top: 32px;

		@include mobile-media() {
			margin-top: 16px;
		}
	}
}

.corporate-section-card {
	background-color: $c-white2;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	padding: 24px;
	height: fit-content;

	&--title {
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree';
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 8px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&--description {
		color: $c-blue-dark;
		display: grid;
		font-size: 14px;
		gap: 4px;
		grid-template-columns: 16px 1fr;
		line-height: 22px;
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0px;
		}

		& > span:first-child {
			margin-top: 3px;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;

			& > span:first-child {
				margin-top: 2px;
			}
		}
	}
}
