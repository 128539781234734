.color-blue-dark {
	color: $c-blue-dark;
}

.color-blue-medium {
	color: $c-blue-medium;
}

.color-blue-neon {
	color: $c-blue-neon;
}
