$base-font-size: 16px; // This value is needed for correct rem calculations. Should be like browser default, 16px.
//$body-font-size: rem(18); // This value reflects the desired 'base' font size of the theme.
$base-line-height: 1.3334;
$base-font-color: $c-black;
$base-font-family: 'IBM Plex Sans', 'Helvetica Neue', 'Helvetica', sans-serif;
$secondary-font-family: 'Bai Jamjuree', 'Helvetica Neue', 'Helvetica', sans-serif;

// Grid settings
$site-width: 1280px;
$gutter: 30px;
$gutter-mobile: 20px;
$side-padding: 30px;
$grid-columns: 12;

// Transition durations
$transition-duration-very-slow: 0.5s;
$transition-duration-slower: 0.4s;
$transition-duration-slow: 0.3s;
$transition-duration-default: 0.2s;
$transition-duration-fast: 0.1s;

// Easings
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);

// Header
$header-height: 148px;
$header-height-mobile: 68px;
