/* ImageTextBlend Component Styles */
.ImageTextBlendMoon {
	background-color: $c-black !important;
	color: $c-white;
	font-family: 'IBM Plex Sans', sans-serif;
	overflow: hidden;
	padding: 48px 0;
	position: relative;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	@include tablet-media() {
		display: flex;
		justify-content: center;
		padding-bottom: 215px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 718px;
		margin: 0 auto;
		position: relative;
		text-align: center;
		z-index: 2;
	}

	&--main-title {
		color: $c-white !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--text {
		color: $c-white !important;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 24px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 21px;
		}

		&-bold {
			font-weight: 600;
		}
	}

	&--moon {
		bottom: -716px;
		height: 1400px;
		right: -635px;
		object-fit: contain;
		position: absolute;
		width: 1400px;

		@include tablet-media() {
			bottom: -850px;
			height: 1400px;
			right: unset;
			width: 1400px;
		}
	}
}
