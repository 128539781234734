@mixin tx-title-xl {
	@include font-size-0;

	font-family: $secondary-font-family;
}

@mixin tx-title-l {
	@include font-size-1;

	font-family: $secondary-font-family;
}

@mixin tx-title-m {
	@include font-size-2;

	font-family: $secondary-font-family;

	> small {
		@include font-size-5;

		font-family: $secondary-font-family;
		text-transform: uppercase;
		letter-spacing: 0.16em;
	}
}

@mixin tx-title-s {
	@include font-size-3;

	font-family: $secondary-font-family;
}

@mixin tx-title-xs {
	@include font-size-5;

	font-family: $secondary-font-family;
	text-transform: uppercase;
	letter-spacing: 0.16em;
}

@mixin tx-body {
	@include font-size-4;
}

@mixin tx-body-s {
	@include font-size-6;
}
