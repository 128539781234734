/* Location Component Styles */
.Location {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.Location--heading {
	@include space(margin-bottom, l);

	text-align: center;
}

.Location--content {
	display: flex;
	align-items: center;
	height: 100%;
}

.Location--contentInner {
	@include mobile {
		margin-bottom: rem(map_get($spaces-mobile, l));
	}
}

.Location--mapContainer {
	display: flex;
	align-items: center;
	height: 100%;
}

.Location--map {
	position: relative;
	width: 100%;

	&::before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 100%;
	}

	@include tablet-portrait {
		&::before {
			padding-top: (4 / 3) * 100%;
		}
	}
}

.Location--mapInner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	border-radius: 5px;
	background-color: $c-white;

	.Loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.Location--mapIframe {
	width: 100%;
	height: 100%;
}

.Location--cta {
	@include space(margin-top, l);

	@include mobile {
		text-align: center;
	}
}

@include collapse-comp('Location') {
	@include space(margin-top, xl, true);
}
