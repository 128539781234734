@function rem($size, $base-size: $base-font-size) {
	$rem-size: ($size * 1px) / $base-size;

	@if index('px', unit($size)) != null and type_of($size) == 'number' {
		$rem-size: ($size) / $base-size;
	}

	@return #{$rem-size}rem;
}

/**
	Converts pixel value to em value

	- $size: Value with or without px unit, e.g. em(20) or em(20px)
	- $base-size: Pixel value. $base-font-size is default.
 */
@function em($size, $base-size: $base-font-size) {
	$em-size: ($size * 1px) / $base-size;

	@if index('px', unit($size)) != null and type_of($size) == 'number' {
		$em-size: ($size) / $base-size;
	}

	@return #{$em-size}em;
}
