.pictureContent03 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--smalltitle {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 18px;
		}
	}

	&--maintitle {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--row {
		align-items: stretch;
		display: flex;
		justify-content: space-between;
		gap: 16px;

		@include mobile-media() {
			flex-direction: column;
		}
	}

	&--card {
		background-color: $c-white2;
		padding: 24px;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		flex: 1;
		position: relative;
		overflow: hidden;
		width: 100%;
	}

	&--cardTitle {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		word-wrap: break-word;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&--cardsubtitle {
		margin-bottom: 16px;
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $c-blue-dark;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--indexedDescription {
		display: inline-flex;
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 24px;
		}
	}

	&--cardDescription {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 0px !important;
		color: $c-blue-dark;
		overflow: hidden;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--arrowIcon {
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 600;
		font-size: 14px;
		margin: 3px 4px 0 0;
		min-width: 16px;

		@include mobile-media() {
			@include reset-scale();

			margin-top: 2px;
		}
	}

	&--button {
		margin-top: auto;
	}

	&--leftColumn,
	&--rightColumn {
		align-items: flex-start;
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 16px;
		height: fit-content;
	}

	&--rightColumn {
		@include mobile-media() {
			flex-direction: column-reverse;
		}
	}

	&--img {
		border-radius: 16px;
		height: 350px;
		overflow: hidden;
		width: 100%;

		& > img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
}
