.IndividualSection04 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--title {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		color: $c-blue-dark !important;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		margin: 0 auto 32px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--cards-container {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(4, 1fr);

		@include tablet-media() {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}
}

.IndividualSection04--card {
	background-color: $c-white2;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin-top: 38px;

	&[data-offer='1'] {
		margin-top: unset;
	}

	@include tablet-media() {
		margin-top: unset;

		&[data-offer='1']:nth-child(odd) + & {
			margin-top: 38px;
		}

		&:has(+ &[data-offer='1']:nth-child(even)) {
			margin-top: 38px;
		}
	}

	@include mobile-media() {
		margin-top: unset !important;
	}

	&-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		height: 100%;
		padding: 16px 24px 24px 24px;
	}

	&-offer {
		background-color: $c-blue-neon;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		padding: 8px 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-title {
		font-size: 16px;
		font-weight: 400;
		color: $c-blue-dark;
		margin-bottom: 8px;
		line-height: 24px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&-time {
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
			margin-bottom: 12px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		margin-bottom: 8px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-point {
		color: $c-blue-dark;
		display: grid;
		gap: 4px;
		grid-template-columns: 16px 1fr;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 24px;
		}

		&-icon {
			margin-top: 5px;
		}

		&-text {
			align-items: center;
			display: flex;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 18px;
			margin-bottom: 4px;
		}
	}

	&-button {
		margin-top: auto;

		& > a {
			width: 100%;
		}
	}
}
