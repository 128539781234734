.IndividualSection05 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--title {
		color: $c-white !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin: 0 auto 32px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--cards-container {
		display: flex;
		gap: 16px;

		@include mobile-media() {
			flex-direction: column;
		}
	}
}

.IndividualSection05--card {
	background-color: $c-background;
	border-radius: 16px;
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
	margin-top: 38px;

	&[data-offer='1'] {
		margin-top: unset;
	}

	@include mobile-media() {
		margin-top: unset !important;
	}

	&-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		height: 100%;
		padding: 16px 24px 24px 24px;
	}

	&-offer {
		background-color: $c-blue-neon;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		padding: 8px 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&-name {
		border-radius: 20px;
		color: $c-blue-dark;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 4px;
		padding: 4px 12px;
		width: fit-content;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}

		&[data-type='package'] {
			background-color: $c-white2;
		}

		&[data-type='membership'] {
			background-color: $c-blue-dark;
			color: $c-white;
		}
	}

	&-title {
		color: $c-blue-dark;
		font-family: 'Bai Jamjuree', sans-serif;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 4px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 16px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 21px;
		}
	}

	&-list {
		color: $c-blue-dark !important;
		margin-bottom: 16px;
		padding-left: 24px;

		& > li {
			color: $c-blue-dark !important;
			font-size: 14px;
			line-height: 22px;
		}

		@include mobile-media() {
			@include reset-scale();

			& > li {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	&-button {
		margin-top: auto;

		& > a {
			width: 100%;
		}
	}
}
