$c-white: rgb(255, 255, 255);
$c-white2: rgb(239, 239, 240);
$c-background: rgb(247, 247, 248);
$c-black: rgb(0, 0, 0);
$c-blue-neon: rgb(0, 255, 255);
$c-blue-dark: rgb(0, 0, 71);
$c-blue-medium: rgb(20, 0, 107);
$c-gray-dark: rgb(73, 73, 82);
$c-gray-darker: rgb(80, 80, 80);
$c-gray-light: rgb(211, 211, 215);
$c-ink: rgb(112, 112, 125);
$c-red: rgb(255, 100, 100);
