.corporate-section06 {
	background-color: $c-blue-dark !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--small-title {
		color: $c-white !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 20px;
		}
	}

	&--main-title {
		color: $c-white !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 32px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--content {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: min-content;

		@include mobile-large-media() {
			grid-template-columns: 1fr;
		}
	}

	&--section {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: min-content;

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--image {
		border-radius: 16px;
		grid-column-start: 1;
		grid-column-end: 3;
		min-height: 390px;
		overflow: hidden;
		position: relative;

		@include mobile-large-media() {
			min-height: 340px;
		}

		@include mobile-media() {
			grid-column-end: 1;
			min-height: 275px;
		}

		img {
			height: 100%;
			object-fit: cover;
			position: absolute;
			width: 100%;
		}
	}

	&--section:nth-child(2n) > &--image {
		order: -1;

		@include mobile-large-media() {
			order: 1;
		}
	}

	&--card {
		background-color: $c-white2;
		border-radius: 16px;
		height: fit-content;
		padding: 24px;

		.SvgIcon {
			color: $c-blue-dark;
			margin-bottom: 8px;
		}

		&-title {
			color: $c-blue-dark;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 22px;
			font-weight: 500;
			line-height: 28px;
			margin-bottom: 8px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 30px;
			}
		}

		&-description {
			color: $c-blue-dark;
			font-size: 14px;
			line-height: 22px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	&--action {
		display: flex;
		justify-content: center;
		margin-top: 32px;
	}
}
