/* RankingTable Component Styles */
.CustomTable {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.CustomTable--table {
	thead tr {
		border-bottom: 1px solid $c-blue-medium;
	}

	tbody tr {
		border-top: 1px solid $c-gray-light;
	}

	th {
		padding: rem(20);
	}

	td {
		padding: rem(12) rem(20);
	}

	// th,
	// td {
	// 	text-align: left;

	// 	&:last-child {
	// 		text-align: right;
	// 	}
	// }

	@include mobile {
		th {
			padding: rem(14);
		}

		td {
			padding: rem(12) rem(14);
		}
	}
}
