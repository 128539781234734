.tx-title-xl {
	@include tx-title-xl;
}

.tx-title-l {
	@include tx-title-l;
}

.tx-title-m {
	@include tx-title-m;
}

.tx-title-s {
	@include tx-title-s;
}

.tx-title-xs {
	@include tx-title-xs;
}

.tx-body {
	@include tx-body;
}

.tx-body-s {
	@include tx-body-s;
}
