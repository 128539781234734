$x-min: -0.15;
$x-max: 0.15;
$y-min: -0.15;
$y-max: 0.15;
$deg-min: -0.6;
$deg-max: 0.6;

@keyframes home-experience-wiggle {
	@for $i from 0 through 100 {
		#{$i * 1%} {
			transform: translate(#{randomNum($x-min, $x-max) * 1%}, #{randomNum($y-min, $y-max) * 1%})
				rotate(#{randomNum($deg-min, $deg-max)}deg);
		}
	}
}
