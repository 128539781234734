$spaces: (
	xxl: 180px,
	xl: 120px,
	l: 60px,
	m: 30px,
	s: 20px,
	xs: 10px
);

$spaces-tablet: (
	xxl: 140px,
	xl: 100px,
	l: 50px,
	m: 30px,
	s: 20px,
	xs: 10px
);

$spaces-mobile: (
	xxl: 100px,
	xl: 70px,
	l: 50px,
	m: 30px,
	s: 20px,
	xs: 10px
);

@mixin space($prop, $size, $negative: false) {
	#{$prop}: rem(map-get($spaces, $size) * (if($negative, -1, 1)));

	@include tablet {
		#{$prop}: rem(map-get($spaces-tablet, $size) * (if($negative, -1, 1)));
	}

	// Use mobile spacings already on tablet-portrait
	@include tablet-portrait {
		#{$prop}: rem(map-get($spaces-mobile, $size) * (if($negative, -1, 1)));
	}
}
