/* VideoEmbed Component Styles */
.VideoEmbed {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.VideoEmbed--wrapper {
	width: 100%;

	@include letterbox {
		@include grid-offset($cols: 1);
		@include grid-col($cols: 10);
	}
}

.VideoEmbed--media {
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: (100% / 16 * 9);

	@supports (padding-bottom: calc(100% * var(--ratio))) {
		padding-bottom: calc(100% * var(--ratio));
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
