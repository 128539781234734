/* SocialMediaFeed2 Component Styles */
.SocialMediaFeed.layout-2 {
	.FeedGrid--cell:nth-child(2),
	.FeedGrid--cell:nth-child(5) {
		margin-left: (2 / 12) * -100%;
		margin-top: (2 / 12) * 100%;
	}

	@include tablet {
		.FeedGrid--cell.small {
			width: (3 / 12) * 100%;
		}

		.FeedGrid--cell.medium {
			width: (3 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: 50%;
		}

		.FeedGrid--cell:nth-child(2),
		.FeedGrid--cell:nth-child(5) {
			margin-top: 0;
			margin-left: 0;
		}
	}

	@include tablet-portrait {
		.FeedGrid--cell.medium,
		.FeedGrid--cell.small {
			width: (4 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: (8 / 12) * 100%;
		}
	}

	@include mobile {
		.FeedGrid--cell.small {
			width: (6 / 12) * 100%;
		}

		.FeedGrid--cell.medium {
			width: (12 / 12) * 100%;
		}

		.FeedGrid--cell.double {
			width: 100%;

			&::before {
				content: none;
			}

			.FeedGrid--cellContent {
				position: static;
			}

			.FeedGrid--cellInner {
				overflow: visible;
			}

			.preview.facebook::after {
				content: none;
			}
		}
	}
}
