/* InfoTeaser Component Styles */
.InfoTeaser {
	@include space(padding-top, l);
	@include space(padding-bottom, l);
}

.InfoTeaser--background {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	position: relative;

	&::before,
	&::after {
		@include background;

		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		width: calc(((100vw - #{rem($site-width)}) / 2) + #{rem($site-width * (2 / 12))});
		z-index: 0;
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	@media (max-width: ($site-width + (2 * $side-padding))) {
		&::before,
		&::after {
			width: (3 / 12) * 100%;
		}
	}

	@include tablet {
		&::before,
		&::after {
			width: (2 / 12) * 100%;
		}
	}

	@include tablet-portrait {
		&::before,
		&::after {
			width: (1 / 12) * 100%;
			background-size: cover;
		}
	}

	@include mobile {
		&::before,
		&::after {
			display: none;
		}
	}
}

.InfoTeaser--content {
	text-align: center;
	position: relative;
	z-index: 1;
}

.InfoTeaser--title {
	@include space(margin-bottom, m);

	.color-scheme-light & {
		color: $c-blue-medium;
	}

	.color-scheme-dark & {
		color: $c-blue-neon;
	}
}

.InfoTeaser--cta {
	@include space(margin-top, l);
}

@include collapse-comp('InfoTeaser') {
	@include space(margin-top, l, true);
}
