.pictureContent05Grid {
	display: flex;
	gap: 16px;

	@include desktop-small-media() {
		flex-direction: column;
	}

	&[data-reversed='1'] {
		flex-direction: row-reverse;

		@include desktop-small-media() {
			flex-direction: column-reverse;
		}
	}

	&--column {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;
		flex: 1;
		height: fit-content;

		@include mobile-media() {
			grid-template-columns: 1fr;
		}

		&-desktop {
			@include desktop-small-media() {
				display: none;
			}
		}

		&-mobile {
			display: none;

			@include desktop-small-media() {
				display: grid;
			}
		}

		&-item-full {
			grid-column: 1/3;

			@include mobile-media() {
				grid-column: 1/1;
			}
		}
	}

	&--card {
		background-color: $c-background;
		border-radius: 16px;
		gap: 16px;
		height: fit-content;
		padding: 24px;
		position: relative;

		&-title {
			color: $c-blue-dark;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 30px;
			font-weight: 500;
			line-height: 38px;
			margin-bottom: 16px;

			&-small {
				font-size: 22px;
				line-height: 30px;
			}

			@include mobile-media() {
				@include reset-scale();

				font-size: 28px;
				line-height: 36px;

				&-small {
					font-size: 20px;
					line-height: 28px;
				}
			}
		}

		&-description {
			color: $c-blue-dark;
			display: grid;
			font-size: 14px;
			gap: 4px;
			grid-template-columns: 16px 1fr;
			line-height: 22px;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: unset;
			}

			& > span {
				margin-top: 3px;

				& svg {
					height: 16px !important;
				}
			}

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;

				& > span {
					margin-top: 2px;
				}
			}
		}

		&-scroll-link {
			position: absolute;
			bottom: 14px;
			right: 24px;

			& > span {
				height: 32px;
				width: 32px;
			}

			@include mobile-media() {
				@include reset-scale();

				bottom: 4px;
				right: 16px;

				& > span {
					width: 34px;
				}
			}
		}

		&-scroll-link ~ &-description:last-child {
			padding-right: 40px;
		}
	}

	&--img {
		border-radius: 16px;
		height: 265px;
		overflow: hidden;

		& > img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	&--file-card {
		background-color: $c-blue-dark;
		border-radius: 16px;
		gap: 16px;
		padding: 24px;

		&-title {
			color: $c-white;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 22px;
			font-weight: 500;
			line-height: 30px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 28px;
			}
		}

		&-description {
			color: $c-white;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 24px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	&--file-row {
		display: flex;
		gap: 8px;

		&-icon {
			color: $c-blue-neon;
			height: 24px;
			margin-top: 2px;
			min-width: 24px;
			width: 24px;

			@include mobile-media() {
				@include reset-scale();
			}

			& svg {
				height: 24px !important;
			}
		}

		&-name {
			color: $c-white;
			font-size: 18px;
			font-weight: 500;
			line-height: 26px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 16px;
				line-height: 24px;
			}
		}

		&-size {
			color: $c-white;
			font-size: 14px;
			line-height: 22px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}

		&-action {
			color: $c-white;
			cursor: pointer;
			display: block;
			height: 46px;
			margin-left: auto;
			min-width: 46px;
			width: 46px;

			& svg {
				height: 44px !important;
			}

			@include mobile-media() {
				@include reset-scale();

				& svg {
					overflow: visible;
				}
			}
		}
	}
}
