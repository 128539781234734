.individualsection02 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--main-container {
		display: flex;
		gap: 16px;

		@include tablet-media() {
			flex-direction: column;
		}
	}

	&--video {
		border-radius: 16px;
		flex: 2;
		height: 400px;
		overflow: hidden;
		position: relative;

		@include tablet-media() {
			flex: unset;
		}

		@include mobile-media() {
			height: 300px;
		}

		& > video {
			@include reset-scale();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}

		&-overlay {
			align-items: center;
			background-color: rgba($c-blue-dark, 0.4);
			bottom: 0;
			cursor: pointer;
			display: flex;
			justify-content: center;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}

		&-play {
			background-color: $c-blue-dark;
			border-radius: 100%;
			color: $c-white;
			height: 56px;
			padding: 12px;
			width: 56px;

			@include mobile-media() {
				@include reset-scale();
			}

			& svg {
				height: 32px;
			}
		}
	}

	&--cards-container {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 16px;
		height: fit-content;

		@include tablet-media() {
			flex-direction: row;
		}

		@include mobile-media() {
			flex-direction: column;
		}
	}
}

.individualsection02--card {
	background-color: $c-white2;
	border-radius: 16px;
	flex: 1;
	padding: 24px;

	&-title {
		color: $c-blue-dark;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 8px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 20px;
			line-height: 28px;
		}
	}

	&-description {
		color: $c-blue-dark;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}
}
