/* TeaserCards Component Styles */

.TeaserCards--list {
	@include space(margin-bottom, l, true);

	.row {
		justify-content: center;
	}

	@include mobile {
		.row {
			margin: auto;
		}
	}
}

.TeaserCards__Teaser {
	@include space(margin-bottom, s);
}

.TeaserCards--heading {
	text-align: center;

	@include space(margin-bottom, l);

	&.has-text {
		@include space(margin-bottom, m);
	}

	@include tablet {
		@include space(margin-bottom, m);
	}

	@include mobile {
		@include space(margin-bottom, m);
	}
}

.TeaserCards--text {
	margin: auto;

	@include space(margin-bottom, m);
}

.TeaserCard--img {
	@include space(margin-bottom, s);

	border-radius: 5px;
	overflow: hidden;
}

.TeaserCard--title {
	@include space(margin-bottom, xs);

	text-align: center;
}

.TeaserCard--text {
	padding: 0 rem(30);
	line-height: (30/20);
	text-align: center;
	@include font-size-3;

	@include tablet {
		padding: 0 rem(20);
	}

	@include mobile {
		font-size: rem(17);
		padding: 0;
	}
}

.TeaserCard--cta {
	text-align: center;
}

.TeaserCards__listColumn {
	display: inherit;
	flex-direction: column;
	justify-content: space-between;

	@include space(margin-bottom, l);

	&:not(.has-cta) {
		@include space(margin-bottom, m);
	}
}

@include collapse-comp('TeaserCards') {
	@include space(margin-top, xl, true);
}

.TeaserCards {
	.wrapper {
		@include space(padding-top, xl);
		@include space(padding-bottom, xl);
	}

	&.three-columns-packages, &.three-columns-memberships {
		padding-bottom: 80px;
	}

	&.three-columns-packages, &.three-columns-rides {

		background-color: $c-white;

		.wrapper {
			padding-top: 70px;
			padding-bottom: 70px;
		}

		@include mobile {

			background-color: $c-blue-dark;
			.wrapper {
				padding: 0;
			}

			.TeaserCards--heading {
				background: $c-blue-dark;
				margin: 0;
				padding-top: 40px;
				padding-bottom: 40px;
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 38px;
				text-align: center;
				color: $c-blue-neon;
			}

			.TeaserCards--list:not(.mobile-only) {
				display: none;
			}
		}

		.TeaserCards--heading {
			text-transform: uppercase;
			font-weight: 600;
		}

		.TeaserCard {
			position: relative;

			height: 640px;

			box-shadow: 0 15px 40px rgba(62, 84, 185, 0.2);
			border-radius: 5px;

			@include ios-tablet {
				height: 740px;
			}
		}

		.TeaserCard--img {
			height: 365px;
			-webkit-mask-image: -webkit-gradient(linear, center top, center bottom, color-stop(0, transparent), color-stop(0.3, #000));

			&:before {
				content: " ";
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background: $c-blue-neon;
				mix-blend-mode: color;
				border-radius: 5px;
			}

			&:after {
				content: " ";
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background: $c-blue-dark;
				mix-blend-mode: soft-light;
				border-radius: 5px;
			}

			img {
				max-height: 365px;
				object-fit: cover;
				border-radius: 5px;
			}
		}

		.TeaserCard--img-overlay {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000047;
			opacity: .2;
			mix-blend-mode: color;
		}

		.TeaserCard--content-bg {
			background-color: $c-blue-dark;
			border-radius: 5px;

			&:before {
				content: ' ';
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180deg, rgba(0, 0, 70, 0.6), rgba(0, 0, 71, 0) 30%);
				//background: linear-gradient(180deg, rgba(0, 0, 70, 2), rgba(0, 0, 71, 0) 50%);
				border-radius: 5px;
			}

			&:after {
				content: ' ';
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180deg, rgba(0, 0, 71, 0) 0%, #000047 57%);
				z-index: 0;
				border-radius: 5px;
			}
		}

		.TeaserCard--text {
			position: relative;
			z-index: 1;

			@include ios-tablet {
				font-size: 1rem;
			}
		}

		.TeaserCard--title-wrap {
			position: absolute;
			top: 93px;
			left: 38px;
			width: calc(100% - 40px);
			z-index: 1;

			.TeaserCard--title {
				text-align: left;
				font-family: $secondary-font-family;
				font-style: normal;
				font-size: 40px;
				line-height: 90%;
				text-transform: uppercase;
				color: $c-white;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin-bottom: 20px;

				@include ios-tablet {
					font-size: 26px;
				}
			}

			.TeaserCard--price {
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 21px;
				color: $c-blue-neon;
			}
		}

		.TeaserCard--text {
			text-align: left;
			margin-left: 30px;
			margin-top: -50px;
			padding-right: 70px;

			ul > li {
				padding-left: 0;
				padding-bottom: 0;
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: $c-white;
				margin-bottom: 12px;

				@include mobile {
					@supports (-webkit-touch-callout: none) {
						line-height: 32px;
					}
				}

				&::before {
					content: none;
				}

				&::marker {
					color: white;
				}
			}
		}

		.TeaserCard--cta {
			position: absolute;
			bottom: 0;
			padding-top: 70px;
			padding-bottom: 60px;
			left: 50%;
			transform: translate(-50%, 0%);
			white-space: nowrap;

			a.secondary {
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: $c-blue-medium;
			}
		}

		.TeaserCard--share {
			position: absolute;
			top: 22px;
			right: 22px;
			width: 47px;
			height: 47px;
			z-index: 99;
			cursor: pointer;
			padding: 15px;
		}

		.TeaserCard--notice {
			font-family: $base-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: $c-ink;
			text-align: center;
		}

		.TeaserCards--list.mobile-only {
			display: none;


			@include mobile {
				display: block;
			}

			.Slider--slide {
				margin-right: 0;
			}

			.TeaserCard {
				//height: auto;
				height: 640px !important;
				margin: auto;
				padding: 0;

				@supports (-webkit-touch-callout: none) {
					height: 660px !important;
				}
			}

			.TeaserCard--text {
				text-align: center;
				margin: auto 30px;
				padding: 0;
				position: absolute;
				margin-top: 0;
				top: 330px;

				ul {
					text-align: center;
					list-style-position: inside;

					& > li::marker {
						margin-right: 10px !important;
					}
				}
			}

			.TeaserCard--price {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 21px;
				text-align: center;
			}

			.TeaserCard--cta {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 40px;
				padding-top: 20px;

				padding-bottom: 30px;
				margin: auto;

				.Button {
					display: inline-block;
				}
			}

			ol.flickity-page-dots {
				bottom: 40px;
			}

			.TeaserCard--img {

				height: 460px;
				width: auto;

				img {
					height: auto;;
					object-fit: cover;
					max-height: none;
				}

				&:after {
					background: linear-gradient(0deg, rgba(0, 0, 71, 0) 70%, rgba(0, 0, 71, 01) 100%);
					mix-blend-mode: normal;
				}

				&:before {
					height: 540px;
				}
			}

			.TeaserCard--content-bg {
				&:before {
					//background: linear-gradient(180deg, rgba(0, 0, 70, 2), rgba(0, 0, 71, 0) 50%);
				}

				&:after {
					background: linear-gradient(180deg, rgba(0, 0, 71, 0) 0%, #000047 80%);
				}
			}

			.TeaserCard--title-wrap {
				top: 50px;
				left: 0;
				text-align: center;
				margin: auto;
				width: 100%;
				padding-left: 20px;
				padding-right: 20px;
			}

			.TeaserCard--title {
				font-style: normal;
				font-size: 40px;
				line-height: 90%;
				text-align: center;
				text-transform: uppercase;
			}

			button.flickity-button.flickity-prev-next-button {
				margin-top: 10px;
				height: 50px;
				width: 50px;

				.flickity-button-icon {
					height: 1rem;
					width: 1rem;
				}

				&.next {
					right: 25px !important;
				}

				&.previous {
					left: 25px !important;
				}
			}

			.flickity-slider {
			}

			.flickity-page-dots {
				position: relative;
				bottom: auto;
			}

			.flickity-page-dots .dot {
				background: $c-white;
			}

			.TeaserCard--notice {
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: $c-white;
				opacity: 0.6;
				padding: 40px 50px 30px;

				@supports (-webkit-touch-callout: none) {
					line-height: 32px;
				}
			}
		}
	}

	&.three-columns-memberships {
		background: linear-gradient(180deg, #F7F7F8 24.56%, #DCDCDE 87.69%);
		padding-bottom: 60px;

		.TeaserCards--text {
			font-family: $base-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: $c-black;
			margin-bottom: 70px;

			@include mobile {
				margin: auto;
				background: white;
				padding: 0 40px 40px;

				@supports (-webkit-touch-callout: none) {
					line-height: 32px;
				}
			}

			@include ios-tablet {
				font-size: 1rem;
			}
		}

		.wrapper {
			padding-top: 70px;
			padding-bottom: 70px;
		}

		@include mobile {
			background-color: $c-white;
			background: $c-white;

			.wrapper {
				padding: 0;
			}

			.TeaserCards--heading {
				background: $c-white;
				margin: 0;
				padding-top: 40px;
				padding-bottom: 40px;
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 38px;
				text-align: center;
				color: $c-blue-medium;
			}

			.TeaserCards--list:not(.mobile-only) {
				display: none;
			}

			.row {
				margin: 0 !important;
			}
		}

		.TeaserCards--heading {
			text-transform: uppercase;
			font-weight: 600;
		}

		.TeaserCard {
			position: relative;
			height: 700px;
			background: $c-white;

			box-shadow: 0 15px 40px rgba(62, 84, 185, 0.2);
			border-radius: 5px;

			max-width: 400px;
			margin: 0 auto;

			@include ios-tablet {
				height: 800px;
			}
		}

		.TeaserCard--img {
			height: 130px;
			width: 100%;
			position: relative;
			margin-bottom: 35px;
			top: 30px;
			right: 30px;

			img {
				object-fit: cover;
				border-radius: 5px;
				position: absolute;
				right: 0;
				width: auto;
				height: 100%;
				display: block;
			}
		}

		.TeaserCard--img-overlay {
			display: none;
		}

		.TeaserCard--content-bg {
			background-color: $c-white;
			border-radius: 5px;
		}

		.TeaserCard--text {
			position: relative;
			z-index: 1;
		}

		.TeaserCard--title-wrap {
			z-index: 1;
			padding-left: 40px;

			.TeaserCard--title {
				text-align: left;
				font-family: $secondary-font-family;
				font-style: normal;
				font-size: 40px;
				line-height: 90%;
				text-transform: uppercase;
				color: $c-blue-medium;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin-bottom: 20px;

				@include ios-tablet {
					font-size: 26px;
					line-height: 40px;
				}
			}

			.TeaserCard--price {
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 21px;
				color: $c-blue-medium;

				.billing-info {
					font-family: $base-font-family;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 21px;
					color: $c-ink;
				}
			}
		}

		.TeaserCard--text {
			margin-top: 30px;
			margin-left: 30px;
			padding-left: 10px;

			ul {
				margin-top: 10px;
				margin-left: 30px;
			}

			p, ul {
				text-align: left;
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: $c-ink;
			}


			ul > li {
				padding-left: 0;
				padding-bottom: 0;
				margin-bottom: 12px;

				@supports (-webkit-touch-callout: none) {
					line-height: 32px;
				}

				@include ios-tablet {
					margin-bottom: 0;
				}

				&::before {
					content: none;
				}

				&::marker {
					color: $c-ink;
				}
			}
		}

		.TeaserCard--cta {
			position: absolute;
			bottom: 0;
			padding-top: 70px;
			padding-bottom: 60px;
			left: 50%;
			transform: translate(-50%, 0%);
			white-space: nowrap;

			a.primary {
				font-family: $secondary-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: $c-blue-neon;

				@include ios-tablet {
					white-space: normal;
				}
			}
		}

		.TeaserCard--share {
			position: absolute;
			top: 29px;
			left: 25px;
			width: 47px;
			height: 47px;
			z-index: 99;
			cursor: pointer;
			padding: 15px;

			svg path {
				fill: $c-blue-dark;
			}
		}

		.TeaserCards--list.mobile-only {
			display: none;

			@include mobile {
				display: block;
			}

			.Slider--slide {
				margin-right: 0;
			}

			.TeaserCard {
				height: auto;
				margin: auto;
				padding: 0;
				box-shadow: none;
			}

			.TeaserCard--text {
				text-align: center;
				margin: 0 30px auto;
				position: relative;
				padding: 40px 0 0;
				height: 400px;

				@supports (-webkit-touch-callout: none) {
					height: 532px;
				}

				p {
					text-align: center;
				}

				ul {
					text-align: center;
					list-style-position: inside;
					margin-left: 0;
					margin-top: 20px;

					li {
						margin-bottom: 0;
					}

					& > li::marker {
						margin-right: 10px !important;
					}
				}
			}

			.TeaserCard--price {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 21px;
				text-align: center;
				width: 180px;
				margin: auto;

				@supports (-webkit-touch-callout: none) {
					line-height: 42px;
				}
			}

			.TeaserCard--cta {
				padding: 0;
				bottom: 60px;

				@supports (-webkit-touch-callout: none) {
					bottom: 100px;
				}

				.Button {
					display: inline-block;
				}
			}

			.TeaserCard--img {
				height: 100px;
				width: auto;
				margin-bottom: auto;
				top: auto;
				right: auto;
				text-align: center;

				img {
					border-radius: initial;
					right: auto;
					position: relative;

					display: inline-block;
				}
			}

			.TeaserCard--title-wrap {
				top: 50px;
				left: 0;
				text-align: center;
				margin: auto;
				width: 100%;
				padding-left: 20px;
				padding-right: 20px;
			}

			.TeaserCard--title {
				font-style: normal;
				font-size: 40px;
				line-height: 90%;
				text-align: center;
				text-transform: uppercase;
				padding-top: 25px;
			}

			button.flickity-button.flickity-prev-next-button {
				margin-top: -40px;
				height: 50px;
				width: 50px;

				.flickity-button-icon {
					height: 1rem;
					width: 1rem;
				}

				&.next {
					right: 15px !important;
				}

				&.previous {
					left: 15px !important;
				}
			}

			.flickity-slider {
			}

			.flickity-page-dots {
				position: relative;
				bottom: 20px;
			}

			.flickity-page-dots .dot {
				background: $c-blue-dark;
			}
		}
	}

	&.three-columns-rides {
		//margin-top: 13px;
		//background: linear-gradient(360deg, rgba(247, 247, 248, 0) 21.38%, #F7F7F8 99.99%);

		@include mobile {
			//background: $c-white;
			//
			//.TeaserCards--heading {
			//	background: $c-white;
			//}

			.row {
				margin: 0;
			}

			.TeaserCards--list.mobile-only {
				margin-bottom: 0;

				.TeaserCard {
					height: 660px;
				}

				.TeaserCard--content-bg {
					border-radius: 10px;

					&::before {
						border-radius: 10px;
					}
				}

				.TeaserCards--text {
					padding-left: 20px;
					padding-right: 20px;
				}

				.TeaserCard--img-overlay {
					border-radius: 10px;
				}

				//.TeaserCard {
				//	margin-right: 15px;
				//	margin-left: 15px;
				//	border-radius: 10px;
				//	box-shadow: none;
				//}

				.TeaserCard--title-wrap {
					top: auto;
					padding-top: 230px;
				}

				.TeaserCard--text {
					position: relative;
					top: auto;
					padding-left: 50px;
					padding-right: 50px;
					padding-top: 40px;
				}

				.TeaserCard--img {
					width: 100%;
					height: 100%;
					border-radius: 10px;

					.Image {
						object-position: top;
					}
				}
			}
		}

		.TeaserCard--title-wrap {
			position: relative;
			top: auto;
			margin-top: 75%;
		}

		.TeaserCard--text {
			margin-top: 20px;
			color: $c-white;
			margin-left: 10px;
			padding-right: 30px;
			z-index: 99;

			@include ios-tablet {
				font-size: 1rem;
			}
		}

		.TeaserCard--img {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
		}

		.Image {
			max-height: 610px !important;
		}

		.TeaserCard--content-bg {
			background-color: #000047;
		}

		.TeaserCard--content-bg:after {
			background: linear-gradient(180deg, rgba(0, 0, 71, 0) 0%, #000047 95%);
		}

		.TeaserCard--text-overlay {
			position: absolute;
			width: 100%;
			height: auto;

			padding-top: 20px;
			padding-right: 10px;

			font-family: $secondary-font-family;
			font-style: italic;
			font-weight: 600;
			font-size: 400px;
			line-height: 80%;
			display: flex;
			align-items: center;
			text-align: right;
			text-transform: uppercase;
			mix-blend-mode: normal;
			opacity: 0.6;
			-webkit-text-fill-color: transparent;
			-webkit-text-stroke: 2px #00FFFF;
			overflow: hidden;
			justify-content: right;

			z-index: 1;
		}
	}
}
