/* Input Component Styles */
.Input {
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.has-error {
		.Input--decoration {
			background-color: $c-red;
		}

		.Input--error {
			display: block;
		}
	}
}

.Input--wrapper {
	position: relative;
}

.Input--label {
	color: $c-ink;
}

.Input--select,
.Input--input {
	width: 100%;
	padding: rem(10) 0;
	border: 0;
	background: none;
	color: $c-blue-medium;

	&:focus ~ .Input--decoration {
		background-color: $c-blue-medium;
	}

	&::-webkit-input-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&::-moz-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&:-ms-input-placeholder {
		color: rgba($c-ink, 0.5);
	}
}

.Input--select {
	padding-right: rem(32);
	text-align: left;

	&::-ms-expand {
		display: none;
	}
}

.Wrapper-checkboxGroup {
	padding-top: 10px;

	&.one-line {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.container {
			margin-top: 10px;
		}
	}

	/* Customize the label (the container) */
	.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		//background-color: #000047;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 7px;
		top: 0;
		width: 10px;
		height: 20px;
		border: solid #000047;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.Input--selectIcon {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.Input--decoration {
	display: block;
	//margin-top: rem(5);
	width: 100%;
	height: 2px;
	background-color: $c-gray-light;
	transition: background-color $transition-duration-default $ease-out-cubic;
}

.Input--error {
	position: absolute;
	bottom: rem(-26);
	left: 0;
	display: none;
	color: $c-red;
}
