@mixin font-size-0 {
	font-size: rem(55);
	line-height: 1.2;

	@include tablet {
		font-size: rem(48);
		line-height: 1.3;
	}

	@include mobile {
		font-size: rem(40);
		line-height: 1.3;
	}
}

@mixin font-size-1 {
	font-size: rem(40);
	line-height: 1.2;

	@include tablet {
		font-size: rem(38);
	}

	@include mobile {
		font-size: rem(36);
	}
}

@mixin font-size-2 {
	font-size: rem(30);
	line-height: 1.27;

	@include tablet {
		font-size: rem(28);
	}
}

@mixin font-size-3 {
	font-size: rem(20);
	line-height: 1.35;
}

@mixin font-size-4 {
	font-size: rem(17);
	line-height: 1.47;
}

@mixin font-size-5 {
	font-size: rem(14);
	line-height: 1.71;
}

@mixin font-size-6 {
	font-size: rem(14);
	line-height: 1.5;
}
