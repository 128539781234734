/* HomeExperience Component Styles */
.HomeExperience {
	@include color-scheme-dark;

	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	&[data-state='ready'] {
		.HomeExperience--animation {
			opacity: 1;
			transition: opacity $transition-duration-very-slow $ease-out-quad 0.2s;
		}
	}

	@include tablet {
		height: calc(100vh - #{rem($header-height-mobile)});
	}
}

.HomeExperience--animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	opacity: 0;

	canvas {
		width: 100%;
		height: 100%;
		user-select: none;
	}

	@include tablet {
		height: calc(100vh - #{rem($header-height-mobile)});
	}
}

.HomeExperience--content {
	position: absolute;
	left: 0;
	bottom: 33%;
	width: 100%;
	user-select: none;

	&.wiggle {
		animation-name: home-experience-wiggle;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	//@include tablet {
	//	bottom: 24%;
	//}

	//@include mobile {
	//	bottom: 10%;
	//}
}

.HomeExperience--contentInner {
	text-align: center;
}

.HomeExperience--title {
	@include space(margin-bottom, m);
}
