/* ImagesAroundText Component Styles */
.ImagesAroundText {
	font-family: $secondary-font-family;
	overflow: hidden;
	margin-top: 13px;

	span.semi-bold-font-weight {
		font-weight: 600 !important;
	}

	.ImagesAroundText--container {
		background: linear-gradient(179.51deg, #F7F7F8 59.02%, #DCDCDE 116.43%);
		height: 500px;
		max-height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		@include mobile {
			flex-direction: column;
			height: auto;
			max-height: 100%;
		}

		.contentWrap {
			width: 530px;
			z-index: 999;
			position: relative;

			font-style: normal;
			font-weight: 400;
			font-size: 17px;
			line-height: 28px;
			text-align: center;

			color: $c-gray-dark;

			@include mobile {
				padding: 0 50px 60px;
				width: 100%;
				margin: 300px 0 0;

				font-style: normal;
				font-weight: 400;
				font-size: 17px;
				line-height: 28px;
			}
		}
	}

	.ImagesAroundText--image-left {
		left: 0;
		display: flex;
		justify-content: left;
	}

	.ImagesAroundText--image-right {
		right: 0;
		display: flex;
		justify-content: right;

		@include tablet {
			@supports (-webkit-touch-callout: none) {
				display: block;
			}
		}
	}

	.ImagesAroundText--image-left, .ImagesAroundText--image-right {
		position: absolute;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		bottom: 0;

		img {
			height: auto;
			width: auto;
			float: right;
			max-width: 35%;
			object-position: bottom;
			object-fit: contain;
		}

		@include mobile {
			display: none;
		}
	}

	.ImagesAroundText--heading {
		font-style: normal;
		font-weight: 300;
		font-size: 52px;
		line-height: 50px;
		text-transform: uppercase;
		color: $c-blue-medium;
		font-family: $secondary-font-family;

		margin-bottom: 52px;

		@include mobile {
			margin-bottom: 40px;
			text-align: center;

			@supports (-webkit-touch-callout: none) {
				line-height: 66px;
			}
		}

		@include ios-tablet {
			font-size: 35px;
		}
	}

	.ImagesAroundText--text {
		font-family: $base-font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;

		margin-bottom: 41px;

		@include mobile {
			text-align: center;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			@supports (-webkit-touch-callout: none) {
				line-height: 42px;
			}
		}

		@include ios-tablet {
			font-size: 12px;
		}
	}

	.ImagesAroundText--cta {
		@include mobile {
			text-align: center;
		}
	}

	.ImagesAroundText--image-mobile {
		display: none;
		position: absolute;
		top: 0;
		width: 100%;
		-webkit-mask-image: linear-gradient(0deg, rgba(247, 247, 248, 0) 10%, #DCDCDE 30%);

		&:before {
			content: ' ';
			display: block;
			background: linear-gradient(0deg, #C4C4C4 60.42%, rgba(196, 196, 196, 0) 100%);
			width: 100%;
			position: absolute;
			bottom: 0;
			opacity: .5;
			height: 75%;
		}

		@include mobile {
			display: block;
		}
	}
}
