html {
	text-size-adjust: 100%;

	// We assume browser default font size is 16px. Also set $base-font-size to 16px for correct rem calculations.
	font-size: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;

	-ms-overflow-style: -ms-autohiding-scrollbar;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	@include textantialiasing;

	font-family: $base-font-family;
	color: $base-font-color;
	line-height: $base-line-height;
}

::-moz-selection {
	@include text-selection;
}

::selection {
	@include text-selection;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	appearance: none;
	border: 0;
	background: none;
	cursor: pointer;
}

input,
select,
textarea {
	appearance: none;
}

h1 {
	margin: 0; // reset margin set by normalize.css
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
