@mixin color-scheme-white {
	background-color: $c-white;
	color: $c-ink;
}

@mixin color-scheme-light {
	background-color: $c-background;
	color: $c-ink;
}

@mixin color-scheme-dark {
	background-color: $c-blue-dark;
	color: $c-white;
}
