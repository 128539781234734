/* SocialMediaFeed4 Component Styles */
.SocialMediaFeed.layout-4 {
	padding-top: 70px;
	padding-bottom: 0;

	.SocialMediaFeed--heading {
		margin-bottom: 120px;
		text-transform: uppercase;

		@include below-desktop {
			margin-bottom: 50px;
		}
	}

	.FeedGrid--grid {
		margin: 0;
	}

	.Slider {
		max-width: 1280px;
		margin: auto;
	}

	.Slider--slide {
		width: 390px;

		@include mobile {
			width: calc(150vw - 80px);

			@supports (-webkit-touch-callout: none) {
				width: calc(100vw - 80px);
			}
		}
	}

	.FeedGrid--cell {
		width: 100%;
		border: 1px solid $c-blue-neon;
		border-radius: 10px;

		height: 390px;
		@include mobile {
			height: calc(150vw - 80px);

			@supports (-webkit-touch-callout: none) {
				height: calc(100vw - 80px);
			}
		}
	}

	.FeedGrid--cellContent {
		padding: 0;
	}

	.Slider .flickity-prev-next-button.next {
		@include below-desktop {
			right: 15px;
		}
	}

	.Slider .flickity-prev-next-button.previous {
		@include below-desktop {
			left: 15px;
		}
	}

	.flickity-page-dots {
		bottom: -55px;

		@include above-tablet {
			display: none;
		}

		.dot {
			background: $c-white;
		}
	}

	.Footer--social {
		margin-top: 75px;
		text-align: center;
		color: $c-blue-neon;

		@include below-desktop {
			margin-top: 140px;
		}

		.tx-body-s {
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			text-align: center;

			@include below-desktop {
				display: none;
			}
		}

		.FooterSocial--link {
			border: 1px solid $c-blue-neon;
		}

		.FooterSocial--link .SvgIcon > svg {
			fill: $c-blue-neon;
		}
	}

	.FeedGrid--cellInner {
		border-radius: 10px;
	}

	.SocialMediaFeed--ig {
		article:hover {
			transition: visibility 0s, opacity 0.3s linear;

			.on-hover {
				opacity: 1;
				visibility: visible;
			}
		}

		.on-hover {
			display: block;
			opacity: 0;
			visibility: hidden;
			height: 100%;
			width: 100%;
			z-index: 99;
			position: absolute;
			transition: visibility 0s, opacity 0.3s linear;
			top: 0;

			&::before, &::after {
				content: ' ';
				display: block;
				mix-blend-mode: multiply;
				opacity: 0.8;
				height: 200px;
				width: 100%;
				position: absolute;
				z-index: -1;
			}

			&::before {
				top: 0;
				background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 97.63%);
			}

			&::after {
				bottom: 0;
				background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 97.63%);
			}
		}
	}

	.header {
		padding-left: 30px;
		padding-top: 15px;
		display: flex;
		align-items: center;
		gap: 13px;
	}

	.SocialMediaFeed--igUserImage {
		position: relative;

		img {
			width: 42px;
			height: 42px;
			border-radius: 50%;
			border-width: 2.5px;
			border-color: transparent;
			border-style: solid;
		}

		svg {
			position: absolute;
			top: 0;
			z-index: 9;
		}
	}

	.SocialMediaFeed--igUserName {
		font-family: $base-font-family;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 28px;
		color: $c-white;
	}

	.SocialMediaFeed--igText {
		position: absolute;
		bottom: 54px;
		padding-left: 28px;
		padding-right: 28px;
	}
}
