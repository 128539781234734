/* ImageTextBlend Component Styles */
body:not(.home) {
	//.ImageTextBlend {
	//	margin-top: 0;
	//	border-right: 15px $c-white solid ;
	//	border-left: 15px $c-white solid ;
	//}
}

.ImageTextBlend {
	font-family: $secondary-font-family;
	overflow: hidden;
	margin-top: 13px;

	span.semi-bold-font-weight {
		font-weight: 600 !important;
	}

	.ImageTextBlend--container {
		background-color: #000047;
		height: 500px;
		max-height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		@include mobile {
			flex-direction: column;
			height: auto;
			max-height: 100%;

		}

		.contentWrap {
			margin-left: 260px;
			width: 450px;
			z-index: 999;
			position: relative;

			@include mobile {
				margin: 0;
				padding: 0 30px 60px;
				width: 100%;
			}
		}

		.ImageTextBlend--heading {
			font-style: normal;
			font-weight: 300;
			font-size: 52px;
			line-height: 50px;
			text-transform: uppercase;

			font-family: $secondary-font-family;
			text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);

			margin-bottom: 52px;

			b, strong {
				font-weight: bold;
			}

			@include mobile {
				margin-bottom: 64px;
				text-align: center;

				@supports (-webkit-touch-callout: none) {
					line-height: 70px;
				}
			}

			@include ios-tablet {
				font-size: 35px;
			}
		}

		.ImageTextBlend--text {
			font-family: $base-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 28px;

			margin-bottom: 41px;

			@include mobile {
				text-align: center;
				font-weight: 400;
				font-size: 16px;
				line-height: 32px;

				@supports (-webkit-touch-callout: none) {
					line-height: 42px;
				}
			}

			@include ios-tablet {
				font-size: 12px;
			}
		}

		.ImageTextBlend--cta {
			@include mobile {
				text-align: center;
			}
		}

		.ImageTextBlend--text-column, .ImageTextBlend--image-column {
			display: inline-block;
			flex-grow: 0;

		}

		.ImageTextBlend--text-column {
			color: white;
			width: 40%;
			@include mobile {
				margin-top: -100px;
				position: relative;
				order: 2;
				width: 100%;
			}
		}

		.ImageTextBlend--image-column {
			width: 70%;
			height: 500px;

			display: flex;
			align-items: center;
			overflow: hidden;
			max-height: 100%;
			z-index: 1;

			@include mobile {
				height: 264px;
				width: 100%;
			}

			.Image {
				height: 100%;
			}

			img {
				object-fit: cover;
				display: inline-block;

				@include mobile {
					height: 100%;
					width: 100%;
				}
			}

			.image-overaly {
				top: 0;
				width: 100%;
				height: 500px;
				position: absolute;
				background: linear-gradient(90deg, #000047 2.71%, rgba(0, 0, 71, 0) 35.32%);

				@include mobile {
					height: 264px;
					background: linear-gradient(0deg, #000047 2.71%, rgba(0, 0, 71, 0) 35.32%);
				}
			}
		}

		.ImageTextBlend--text-overlay {
			font-style: normal;
			font-weight: 600;
			font-size: 120px;
			line-height: 50px;

			display: flex;
			align-items: center;
			text-transform: uppercase;
			mix-blend-mode: normal;
			opacity: .4;
			width: 1200px;

			-webkit-text-fill-color: transparent;
			-webkit-text-stroke: 1px #8064FF;
			z-index: 99;
			position: absolute;
			bottom: -5px;
			left: 54px;

			@include mobile {
				top: -135px;
				bottom: 0;
				left: 0;
				font-style: normal;
				text-align: left;
				text-transform: uppercase;
				color: #fff;
				mix-blend-mode: normal;
				height: 100%;
				font-size: 120px;
				line-height: 90%;
				opacity: 0.5;
			}
		}
	}

	&.bottom-aligned {
		.ImageTextBlend--text-overlay {
			bottom: 20px;
		}
	}

	&.gray-overlay {
		background: linear-gradient(180deg, #F7F7F8 24.56%, #DCDCDE 87.69%);

		.ImageTextBlend--container {
			background-color: transparent;
		}

		.ImageTextBlend--image-column {
			.Image {
				-webkit-mask-image: linear-gradient(270deg, rgba(247, 247, 248, 0) 3%, #DCDCDE 30%);
				-webkit-mask-repeat: no-repeat;
				mask-repeat: no-repeat;

				@include mobile {
					-webkit-mask-image: linear-gradient(0deg, rgba(247, 247, 248, 0) 3%, #DCDCDE 30%);
				}
			}
		}

		.ImageTextBlend--container .ImageTextBlend--image-column .image-overaly {
			display: none;
		}

		&.voucher {
			.ImageTextBlend--heading {
				color: $c-blue-medium;
				text-shadow: none;

				b, strong {
					font-weight: bold;
				}

				@include mobile {
					font-style: normal;
					font-weight: 400;
					font-size: 40px;
					line-height: 40px;
					text-align: center;
					text-transform: uppercase;

					@supports (-webkit-touch-callout: none) {
						line-height: 53px;
					}
				}
			}

			.ImageTextBlend--container .contentWrap {
				margin-left: 150px;
				width: 550px;

				@include mobile {
					width: auto;
					margin-left: auto;
				}
			}

			.ImageTextBlend--container .ImageTextBlend--text-column {
				@include mobile {
					margin-top: 30px;
				}

				.ImageTextBlend--text {
					color: $c-gray-darker;

					@include mobile {
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 32px;
						text-align: center;

						@supports (-webkit-touch-callout: none) {
							line-height: 43px;
						}
					}
				}
			}

			.ImageTextBlend--container .ImageTextBlend--image-column {
				width: auto;
				right: -100px;
				position: relative;
				display: inline-block;
				align-self: flex-end;
				max-height: 96%;

				@include mobile {
					width: auto;
					right: auto;
					position: relative;
					display: inline-block;
					align-self: flex-end;
					max-height: 100%;
					left: 15%;

					@supports (-webkit-touch-callout: none) {
						width: 100%;
					}
				}

				.Image {
					@include mobile {
						-webkit-mask-image: none;
					}
				}
			}
		}

		&.group-ride {
			max-width: 1280px;
			margin: 25px auto 75px;

			.ImageTextBlend--container{
				height: 460px;
			}

			b, strong {
				font-weight: bold;
			}

			.ImageTextBlend--text-column {
				width: 50%;

				color: $c-blue-dark;

				.contentWrap {
					margin-left: 110px;
					width: 540px;
					top: -15px;
				}

				.ImageTextBlend--heading {
					text-shadow: none;
					margin-bottom: 40px;
				}
			}

			.ImageTextBlend--image-column {
				width: 50%;

				.Image {
					-webkit-mask-image: linear-gradient(90deg, rgba(247, 247, 248, 0) 0%, #DCDCDE 80%);
				}
			}

			.ImageTextBlend--text-overlay {
				bottom: 40px;
				font-style: italic;
				left: 320px;
				-webkit-text-stroke-color: #AFAFAF;
				-webkit-text-stroke-width: 2px;

				br {
					display: none;
				}
			}
		}
	}

	&.image-left-text-right {
		.ImageTextBlend--text-column {
			width: 44%;
			order: 2;

			@include mobile {
				width: 100%;
				order: 1;
			}

			.contentWrap {
				text-align: right;
				margin-left: 0;
				margin-right: 190px;
				float: right;

				@include mobile {
					text-align: center;
					float: inherit;
					margin: auto;
				}

				.ImageTextBlend--heading {
					padding-right: 30px;
					color: $c-blue-medium;
					text-shadow: none;

					@include mobile {
						color: $c-blue-dark;
						padding: 0;
						margin-bottom: 50px;
					}
				}

				.ImageTextBlend--text {
					color: $c-gray-dark;
				}
			}

			.ImageTextBlend--text-overlay {
				bottom: 20px;
				left: auto;
				right: -15px;
				text-align: right;
				justify-content: flex-end;
				-webkit-text-stroke: 1px white;
				opacity: 0.6;

				@include mobile {
					text-align: center;
					bottom: 0;
					top: -230px;
				}
			}
		}

		.ImageTextBlend--image-column {
			width: 56%;

			@include mobile {
				height: 375px;
				width: 100%;
			}
		}
	}

	&.youtube {
		background-color: #000024;
		@include mobile {
			background-color: #000047;
		}

		.ImageTextBlend--container {
			height: 480px;
			@include mobile {
				height: 650px;
				justify-content: flex-start;

				@supports (-webkit-touch-callout: none) {
					height: 750px;
				}
			}

			&:after {
				position: absolute;
				content: ' ';
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 1;
				opacity: 0.6;
				background: linear-gradient(180deg, rgba(0, 0, 71, 0) 0%, #000047 100%);
				transform: rotate(-180deg);

				@include mobile {
					opacity: 0.3;
				}
			}
		}

		.contentWrap {
			width: 100%;
		}

		.ImageTextBlend--heading {
			margin-bottom: 20px;
			@include mobile {
				margin-bottom: 30px;
			}
		}

		.ImageTextBlend--text {
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 500;
			font-size: 30px;
			line-height: 38px;
			color: $c-white;
			margin-bottom: 60px;

			@supports (-webkit-touch-callout: none) {
				line-height: 50px;
			}

			@include mobile {
				position: relative;
				top: 10px;
			}

			@include ios-tablet {
				font-size: 12px;
				margin-bottom: 20px;
			}
		}

		.ImageTextBlend--cta {
			@include mobile {
				position: relative;
				top: 20px;
			}
		}

		.Button.secondary {
			font-weight: 600;
		}

		//.ImageTextBlend--text-overlay {
		//	left: 0;
		//	bottom: 5px;
		//	-webkit-text-stroke: 1px $c-blue-neon;
		//	@include mobile {
		//		top: 15px;
		//		-webkit-text-stroke: 1px rgba(128, 100, 255, 0.7);
		//	}
		//}

		.ImageTextBlend--text-column {
			@include mobile {
				order: 2;
				margin: 0;
				top: -130px;
			}
		}

		.ImageTextBlend--image-column {
			background: $c-blue-neon;
			position: relative;
			top: 0;

			@include mobile {
				height: 320px;
				min-height: 320px;
			}

			.Image {
				mix-blend-mode: hard-light;
				height: 100%;
				filter: grayscale(100%);
				object-position: right;

				@include mobile {
					object-position: center;
				}
			}

			.image-overaly::before {
				position: absolute;
				content: ' ';
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 9;
				background: #000047;
				mix-blend-mode: overlay;
				opacity: 0.7;
			}

			.image-overaly {
				//background: linear-gradient(90deg, #000032 4%, rgba(0, 0, 71, 0) 20%);
				background: linear-gradient(90deg,#000067 7%,rgba(0,0,71,0) 35.32%);

				@include mobile {
					height: 320px;
					background: linear-gradient(00deg, #000070 4%, rgba(0, 0, 71, 0) 20%);
				}
			}
		}

		.ImageTextBlend--live-countdown {
			width: 415px;
			background: rgba(3, 5, 6, 0.5);
			border-radius: 5px;
			height: 60px;
			position: absolute;
			margin-top: 25px;
			color: $c-white;
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 16px;

			@supports (-webkit-touch-callout: none) {
				line-height: 22px;
			}

			@include mobile {
				position: relative;
				left: -25px;
				margin: auto;
				top: 60px;
				max-width: calc(100vw - 30px);
			}

			& > div {
				display: inline-block;
			}

			.live-icon {
				padding-left: 23px;
				padding-right: 23px;
			}

			.set-reminder-cta {
				font-family: $base-font-family;
				width: 130px;
				height: 34px;
				right: 12px;
				position: absolute;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-evenly;

				@supports (-webkit-touch-callout: none) {
					.svg-bell-dims{
						margin-left: 10px;
						margin-right: 10px;
					}
				}

				background: rgba(65, 66, 67, 0.6);
				border-radius: 2px;

				.text {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					display: flex;
					align-items: center;

					@supports (-webkit-touch-callout: none) {
						line-height: 22px;
						padding-top: 5px;
						padding-bottom: 5px;
					}
				}
			}
		}
	}
}
