/* Testimonials Component Styles */
.TestimonialEvents {
	display:flex;
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
	max-width: 120%;
	//width: calc(((100vw ) * 1) );
	text-align: center;

	.Slider--slide {
		padding: 0 ;
		top: 50%;
		transform: translateY(-50%);
	}

	.flickity-viewport {
		z-index: 2;
	}

	@include tablet-portrait {
		.Slider--slide {
			//padding: 0 ((1/12) * 100%);
			padding: 0;
		}
	}

	@include mobile {
		.Slider--slide {
			padding: 0 rem(24);
		}
	}
	img {
		//width: calc(((100vw - #{rem($site-width)}) / 2) + #{rem($site-width * (2 / 4))});
		//height: 288px;
		object-fit: cover;
		border-radius: 16px;
	}
	@media (max-width: ($site-width + (2 * $side-padding))) {
		&::before,
		&::after {
			width: (3 / 12) * 100%;
		}
	}
}
.TestimonialEvents-column1{
	width: 49%;
	flex : 1;
	padding: 24px;    
	background: #EFEFF0;
	border-radius: 16px;	
}
.TestimonialEvents-column2{
	width: 49%;
	padding: 24px;    
	background: none;
	border-radius: 16px;
}
.TestimonialEvents--background1 {
	position: relative;

	&::before,
	&::after {
		

		content: '';
		position: absolute;
		top: 0;
		height: 50%;
		z-index: 0;
		//width: calc(((100vw - #{rem($site-width)}) / 2) + #{rem($site-width * (2 / 12))});
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	/*@media (max-width: ($site-width + (2 * $side-padding))) {
		&::before,
		&::after {
			width: (1 ) * 100%;
		}
	}*/

	@include tablet {
		&::before,
		&::after {
			width: (1 / 2) * 100%;
		}
	}

	@include mobile {
		&::before,
		&::after {
			content: none;
		}
	}
}
.type-font-bai{
	font-family: 'Bai Jamjuree', sans-serif;
}
.type-font-ibm{
	font-family: 'IBM Plex Sans', sans-serif;
}

.color-blue-47{	
    color: #000047 !important;
}
.color-grey-grey7d{
	color: #70707D;

}
.TestimonialEvents--smalltitle{
	font-size: 15px;
    margin-bottom: 10px;
    font-weight: 400;
}
.TestimonialEvents--metatitle{
	font-size: 34px;    
    font-weight: bold;    
    font-weight: 500;
    margin-bottom: 20px;
}

.TestimonialEvents--description {    
    //max-width: 800px;
    margin: 0 auto 40px;    
    font-size: 17px;    
    line-height: 1.6;
    font-weight: 400;    
}

.TestimonialEvents--quote {
	@include space(margin-bottom, m);
	
	@include mobile {
		font-size: rem(30);
		line-height: 1.25;
	}
}

.TestimonialEvents--cta {
	@include space(margin-top, l);
}

.TestimonialEvents {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* The switch - the box around the slider */
.switch
    {
            width: 170px;
            height: 53px;
            position: relative;
            display:inline-block;
			background-color: #EFEFF0;
			border-radius: 99px;
    }

    .switchslider input
    {
            display: none;
    }

    .switchslider
    {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            cursor: pointer;
            background-color: #EFEFF0;
            border-radius: 30px;
			

    }
    .switchslider:before
    {
		content: "Corporate";
        position: absolute;
		color: white;		
        height: 40px;
        width: 95px;
        background-color: #000047;
        left: 1px;
        bottom: 5px;
        border-radius: 99px;
        transition: ease-in-out .5s;
		padding-top: 5px;
    }

    .switchslider:after
    {
        content: "";
		
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 70%;
        transition: all .5s;
        //font-size: 10px;
        font-family: Verdana,sans-serif;
    }

    input:checked + .switchslider:after
    {
        transition: all .5s;
        left: 50%;
		content: "";
    }
    input:checked + .switchslider
    {
        
		content: "";
    }

    input:checked + .switchslider:before
    {
        transform: translateX(20px);
		content: "";
    }
  

