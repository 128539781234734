.Faq {
	background-color: $c-white2 !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	@include mobile-media() {
		background-color: $c-white !important;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 30px;
			line-height: 38px;
		}
	}

	&--small-title {
		margin: 0 auto 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--content {
		gap: 16px;
		display: grid;
		grid-template-columns: 228px 1fr;
		justify-content: center;

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--sidebar {
		color: $c-blue-dark;
		display: flex;
		flex-direction: column;
		height: fit-content;
		gap: 16px;
	}

	&--search {
		align-items: center;
		background-color: $c-background;
		border-radius: 4px;
		display: flex;
		gap: 8px;
		justify-content: center;
		padding: 10px 8px;

		@include mobile-media() {
			@include reset-scale();
			border: 1px solid $c-gray-light;
		}

		&-icon {
			background: transparent;
			height: 16px;
			position: relative;
			width: 16px;

			& > span {
				display: inline;
				min-width: 16px;
				width: 16px;

				& > svg {
					fill: $c-ink;
				}
			}
		}

		& > input {
			height: 24px;
			flex: 1;
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			background-color: transparent;
			border: none;
			outline: none;
			color: $c-ink;
			box-sizing: content-box;
			padding: 0;
		}
	}

	&--navigation {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: fit-content;
		padding: 24px 16px;
		gap: 4px;
		border-radius: 16px;
		list-style: none;
		text-decoration: none;
		list-style-type: none;
		background-color: $c-background;

		@include mobile-media() {
			display: none;
		}

		&-item {
			border-radius: 8px;
			display: block;
			font-size: 18px;
			line-height: 26px;
			padding: 8px 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 16px;
				line-height: 24px;
			}

			&:hover {
				background-color: $c-white2;
			}
		}
	}

	&--container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 100%;
	}

	&--section {
		display: none;
		flex-direction: column;

		& > h3 {
			color: $c-blue-dark;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 22px;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 28px;
			}
		}

		&:has(> div[data-hidden='false']) {
			display: flex;
		}
	}

	&--accordion {
		align-items: center;
		border: 0 0 1px 0;
		border: none;
		color: $c-blue-dark;
		cursor: pointer;
		display: flex;
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: 16px;
		font-weight: 500;
		gap: 16px;
		justify-content: space-between;
		padding: 16px 8px;
		text-align: left;
		outline: none;
		line-height: 23px;
		text-align: left;
		width: 100%;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 21px;
		}

		& > span:last-child {
			transition: all 0.2s ease;
			min-width: 24px;
			width: 24px;
		}
	}

	&--accordion-panel {
		padding: 0px 48px 0px 8px;
		background-color: transparent;
		height: 100%;
		overflow: hidden;
		border-bottom: 1px solid $c-gray-light;

		& > p {
			color: $c-blue-dark;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	&--row {
		display: grid;
		grid-template-rows: min-content 0fr;
		transition: all 0.2s ease-out;

		&[data-hidden='true'] {
			display: none;
		}

		&.Faq--expanded {
			grid-template-rows: min-content 1fr;

			& > .Faq--accordion > span:last-child {
				transform: rotate(180deg);
			}

			& > .Faq--accordion-panel {
				padding-bottom: 8px;
			}
		}
	}

	&--custom-button {
		margin: 16px 0;
	}

	&--extra-section {
		display: grid;
		grid-template-rows: min-content 0fr 0fr;

		&.Faq--extra-expanded {
			grid-template-rows: 0fr 1fr min-content;
		}
	}

	&--showmore,
	&--showless {
		align-items: center;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		gap: 8px;
		line-height: 22px;
		color: $c-blue-dark;
		text-decoration: underline;
		overflow: hidden;

		@include mobile-media() {
			@include reset-scale();

			font-size: 11px;
			line-height: 20px;
		}

		& > span {
			transform: rotate(90deg);
		}
	}

	&--showless {
		margin-top: 16px;

		& > span {
			transform: rotate(-90deg);
		}
	}

	&--more {
		color: $c-blue-dark;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		overflow: hidden;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}
}
