/* NewsItem Component Styles */
.NewsItem {
	border-top: 1px solid $c-gray-light;
}

.NewsItem--inner {
	@include space(padding-top, l);
	@include space(padding-bottom, l);
}

.NewsItem--link {
	display: block;
	transition: background-color $transition-duration-default $ease-out-cubic;

	&:hover {
		background-color: rgba($c-background, 0.5);

		.NewsItem--title {
			background-size: 100% 2px;
		}
	}
}

.NewsItem--pubdate {
	margin-top: rem(13);

	@include tablet {
		margin-top: rem(11);
	}
}

.NewsItem--title {
	display: inline;
	background-image: linear-gradient($c-blue-neon, $c-blue-neon);
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: 0 2px;
	transition: background-size $transition-duration-very-slow $ease-out-cubic;
}

.NewsItem--text {
	@include space(margin-top, m);
}

.NewsItem--image {
	overflow: hidden;
	border-radius: 5px;
}

.NewsItem--dateCol {
	@include mobile {
		order: 2;
		margin-bottom: rem(map_get($spaces-mobile, s));
	}
}

.NewsItem--contentCol {
	@include mobile {
		order: 3;
	}
}

.NewsItem--imageCol {
	@include mobile {
		order: 1;
		margin-bottom: rem(map_get($spaces-mobile, m));
	}
}
