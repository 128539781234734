/* Header Component Styles */
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
.Header {
	position: fixed;
	width: 100%;
	background: linear-gradient(180deg, #000047 0%, rgba(0, 0, 47, 0) 100%);
	opacity: 0;
	margin-top: 0;
	transition: background-color $transition-duration-very-slow $ease-in-cubic,
		margin-top $transition-duration-default ease-out;
	z-index: 9999;

	&.show {
		opacity: 1;
	}

	.details.no-hero &,
	.landingpage & {
		background-color: $c-blue-dark;
	}

	@include tablet {
		background-color: $c-blue-dark;
	}
}

.Background--black {
	background: linear-gradient(180deg, #000022 0%, rgba(0, 0, 47, 0) 100%);
}

.Header--top {
	border-bottom: 1px solid rgba($c-white, 0.15);

	@include tablet {
		display: none;
	}
}

.Header--main {
	@include tablet {
		display: none;
	}
}

.Header--topInner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: rem(40);
}

.Header--langSelect {
	margin-left: rem(20);
}

.Header--mainInner {
	position: relative;
	display: flex;
	align-items: center;
	height: rem(108);
	//overflow: hidden;
}

.Header--logo {
	display: block;
	margin-right: auto;
}

.Header--cta {
	margin-left: rem(50);
}

// Header mobile
.Header--mobile {
	display: none;

	@include tablet {
		display: flex;
	}
}

.Header--logoMobile {
	display: inline-block;
	height: rem(68);
	margin-left: 3.25rem;
	margin-right: auto;
}

// HeaderBurger
.HeaderBurger {
	padding: rem(20) rem(30) rem(30) rem(5);
	color: $c-white;
	position: absolute;
}

.HeaderBurger--icon {
	display: inline-block;
	margin-left: rem(10);
	position: relative;
	width: rem(20);
	height: rem(20);
	vertical-align: middle;

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background-color: $c-blue-neon;
		transition: transform $transition-duration-default $ease-in-out-cubic;
		transform-origin: center;
	}

	&::before {
		top: 5px;
	}

	&::after {
		bottom: 5px;
	}

	.HeaderBurger[data-state='open'] & {
		&::before {
			transform: translateY(4px) rotate(35deg);
		}

		&::after {
			transform: translateY(-4px) rotate(-35deg);
		}
	}
}

.HeaderBurger--text {
	&.close {
		display: none;
	}

	.HeaderBurger[data-state='open'] & {
		&.close {
			display: inline;
		}

		&.open {
			display: none;
		}
	}
}

.Header--overlay {
	display: none;
	position: absolute;
	top: rem($header-height-mobile);
	left: 0;
	width: 100%;
	background-color: $c-white;
	max-height: calc(100vh - 68px);
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	&[data-state='open'] {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
	}
}

.Header--navMobile,
.Header--metaNavMobile {
	@include space(padding-top, l);
	@include space(padding-bottom, l);
	overflow: hidden;

	padding-left: rem($side-padding);
	padding-right: rem($side-padding);
	width: 50%;
	border-bottom: 1px solid rgba($c-blue-dark, 0.15);

	@include mobile {
		width: 100%;
	}
}

.Header--langSelectMobile {
	@include space(padding-top, l);

	padding-left: rem($side-padding);
	padding-right: rem($side-padding);
	width: 50%;
	margin-right: 50%;
	padding-bottom: 40%;

	@include mobile {
		width: 100%;
		margin-right: 0;
	}
}

.Header--ctaMobile {
	width: 33.33%;
	padding: 1% 0 0 rem($side-padding);
	// white-space: nowrap;
	overflow: visible;
	text-align: left;
	color: #00002f;

	.Button {
		color: rgb(0, 0, 47);
		font-weight: bold;

		&::after {
			background-color: rgb(0, 255, 255);
		}
	}

	.Button--text {
	}

	@media (max-width: 1024px) {
		.Button--text {
			font-size: 20px;
		}
	}

	@media (max-width: 415px) {
		.Button--text {
			margin-right: auto;
			font-size: 14px;
		}
	}

	@media (max-width: 375px) {
		.Button--text {
			font-size: 12px;
		}
	}

	@include tablet {
		margin-right: 10px;
		display: flex;
		padding: 0;

		.Button {
			padding-bottom: 10px;
			padding-top: 10px;
			margin: auto;
		}

		.Button--text {
			padding: 0;
			margin: auto;
		}
	}

	@include mobile {
		margin-right: 10px;
		display: flex;
		padding: 0;

		.Button {
			padding: 10px;
			margin: auto;
		}

		.Button--text {
			padding: 0;
			margin: auto;
		}
	}
}

// MetaNav
.MetaNav {
	@include list-reset;
	@include cf;

	@include tablet {
		padding-left: rem(30);
	}
}

.MetaNav--item {
	float: left;
	margin-right: rem(20);

	&:last-child {
		margin-right: 0;
	}

	@include tablet {
		float: none;
		margin-bottom: rem(30);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.MetaNav--link {
	color: rgba($c-white, 0.6);
	transition: color $transition-duration-default $ease-out-cubic;

	@include above-tablet {
		&:hover {
			color: $c-white;
		}
	}

	@include tablet {
		color: $c-blue-dark;
		font-size: rem(19);
	}
}

// MainNav
.MainNav {
	@include list-reset;
	@include cf;

	color: $c-white;

	@include tablet {
		color: $c-blue-dark;
		padding-left: rem(30);
		animation: nav-close-mobile $transition-duration-slower forwards $ease-out-cubic;

		.Header--navMobile[data-state='open'] & {
			animation: none;
		}
	}
}

.MainNav--item {
	float: left;
	margin-right: rem(50);
	position: relative;

	&:last-child {
		margin-right: 0;
	}

	@include desktop {
		margin-right: rem(40);
	}

	@include above-tablet {
		&.has-subnav {
			&:before {
				content: '';
				position: absolute;
				background-color: transparent;
				bottom: rem(-10);
				right: rem(-25);
				height: 10px;
				width: 5px;
				border-bottom-left-radius: 5px;
				box-shadow: 0 5px 0 0 $c-white;
				margin-right: -5px;
				pointer-events: none;
				display: none;
			}

			&[data-state='open'] {
				&:before {
					display: block;
					animation: nav-open $transition-duration-slow forwards $ease-out-cubic;
				}
			}
		}

		&:last-child {
			&.has-subnav {
				&:before {
					right: auto;
					left: rem(-25);
					margin-right: 0;
					margin-left: -5px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 5px;
				}
			}
		}
	}

	@include tablet {
		float: none;
		margin-right: 0;
		margin-bottom: rem(30);

		&:last-child {
			margin-bottom: 0;
		}

		.Header--navMobile[data-state='open'] & {
			display: none;

			&[data-state='open'] {
				display: block;
				margin-bottom: 0;
			}
		}
	}
}

.MainNav--link {
	line-height: 1.47;
	font-size: rem(19);

	&.is-active {
		position: relative;
		color: $c-blue-neon;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: rem(-24);
			transform: translateX(-50%);
			width: rem(10);
			height: rem(10);
			border-radius: 100%;
			background-color: $c-blue-neon;
		}
	}

	@keyframes nav-open {
		from {
			transform: translateY(6px);
		}
		to {
			transform: translateY(0);
		}
	}

	@include above-tablet {
		display: block;

		.MainNav--item.has-subnav & {
			position: relative;
			z-index: 1;

			&:after {
				content: '';
				position: absolute;
				top: rem(-10);
				left: rem(-25);
				right: rem(-25);
				bottom: rem(-10);
				background: $c-white;
				z-index: -1;
				display: none;
				border-radius: 5px 5px 0 0;
			}
		}

		.MainNav--item.has-subnav[data-state='open'] & {
			color: $c-blue-dark;

			&:after {
				display: block;
				animation: nav-open $transition-duration-slow forwards $ease-out-cubic;
			}

			&.is-active {
				color: $c-blue-dark;

				&::before {
					display: none;
				}
			}
		}
	}

	@include tablet {
		display: flex;
		position: relative;
		font-size: rem(24) !important;

		&.is-active::before {
			top: 50%;
			left: rem(-30);
			bottom: auto;
			transform: translateY(-50%);
		}

		.Header--navMobile[data-state='open'] & {
			color: $c-blue-neon;

			&.is-active:before {
				display: none;
			}
		}

		.MainNav--item[data-state='open'] & {
			animation: nav-open-mobile $transition-duration-slower forwards $ease-out-cubic;
		}
	}
}

.MainNav--link--icon {
	display: none;
	position: relative;
	border-radius: 50%;
	background: $c-white;
	box-shadow: 0 10px 20px 0 rgba($c-blue-neon, 0.15);
	overflow: hidden;
	margin-right: rem(15);
	pointer-events: none;
	margin-top: -2px;
	height: 41px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 2px solid $c-blue-neon;
		border-radius: 50%;
	}

	.SvgIcon {
		display: block;

		svg {
			fill: $c-ink;
		}
	}

	.Header--navMobile[data-state='open'] & {
		display: block;
	}
}

// SubNav
.SubNav {
	@include list-reset;
	@include font-size-5;
	display: none;

	@include above-tablet {
		min-width: calc(100% + #{rem(70)});
		position: absolute;
		top: 100%;
		margin-top: rem(9);
		left: rem(-25);
		white-space: nowrap;
		background: $c-white;
		color: $c-blue-dark;
		border-radius: 0 5px 5px 5px;
		//box-shadow: 0 10px 20px rgba($c-blue-neon, 0.15);
		box-shadow: 0 15px 40px rgba($c-blue-dark, 0.2);

		.MainNav--item:last-child & {
			left: auto;
			right: rem(-25);
			border-radius: 5px 0 5px 5px;
		}

		.MainNav--item[data-state='open'] & {
			display: block;
			animation: nav-open $transition-duration-slow forwards $ease-out-cubic;
		}

		&:hover {
			color: rgba($c-blue-dark, 0.6);
		}
	}

	@keyframes nav-open-mobile {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(0);
		}
	}

	@keyframes nav-close-mobile {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(0);
		}
	}

	@include tablet {
		font-size: rem(24);
		line-height: 1.47;
		margin-top: rem(30);

		.MainNav--item[data-state='open'] & {
			display: block;
			animation: nav-open-mobile $transition-duration-slower forwards $ease-out-cubic;
		}
	}
}

.SubNav--item {
	@include tablet {
		margin-bottom: rem(30);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.SubNav--link {
	display: flex;

	@include above-tablet {
		align-items: center;
		padding: rem(7) rem(25);

		.SubNav--item:first-child & {
			padding-top: rem(25);
		}

		.SubNav--item:last-child & {
			padding-bottom: rem(25);
		}

		&:hover {
			color: $c-blue-dark;
		}
	}
}

.SubNav--icon {
	flex-shrink: 0;
	width: rem(32);
	height: rem(32);
	margin-right: rem(15);
	position: relative;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include tablet {
		width: 40px;
		height: 40px;
		margin-top: -2px;
	}
}

.SubNav--group {
	list-style: none;

	@include above-tablet {
		&:first-child {
			padding-top: rem(25);
		}

		&:last-child {
			padding-bottom: rem(25);
		}
	}
}

.SubNav--groupHeading {
	display: flex;
	align-items: center;
	font-size: rem(20);
	color: #757575;
	margin-bottom: rem(30);

	@include above-tablet {
		padding: rem(7) rem(25);
		margin-bottom: 0;
		font-size: rem(12);
	}
}

.SubNav--groupIcon {
	@extend .SubNav--icon;
	width: rem(16);
	height: rem(16);
	margin-right: rem(8);

	img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}

	@include tablet {
		width: 30px;
		height: 30px;
		margin-bottom: -2px;
	}
}

.SubNav--groupItem {
	@include tablet {
		margin-bottom: rem(30);
	}
}

.SubNav--groupLink {
	padding-left: rem(40);

	@include above-tablet {
		padding-left: rem(50);
		font-weight: bold;
	}
}

.SubNav--groupItems {
	list-style: none;
}

.SubNav--groupItemIcon {
	@extend .SubNav--icon;
	margin-right: rem(4);
}


// SubTabNav CSS 

.SubTabNav {
    @include list-reset;
    @include font-size-5;
    display: none;
	z-index: 10;

    @include above-tablet {
        min-width:900px;
        position: absolute;
        top: 100%;
        height: 260px; 
		// padding: 7px;
		padding-left: 5px;
        margin-top: rem(9);
        left: rem(-25);
        white-space: nowrap;
        background: $c-white;
        color: $c-blue-dark;
        border-radius: 0 8px 8px 8px;
        box-shadow: 0 15px 40px rgba($c-blue-dark, 0.2);

        .MainNav--item:last-child & {
            left: auto;
            right: rem(-25);
            border-radius: 5px 0 5px 5px;
        }

        .MainNav--item[data-state='open'] & {
            display: block;
            animation: nav-open $transition-duration-slow forwards $ease-out-cubic;
        }

        &:hover {
            color: rgba($c-blue-dark, 0.6);
        }
    }

    @keyframes nav-open-mobile {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes nav-close-mobile {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @include tablet {
        font-size: rem(24);
        line-height: 1.47;
        margin-top: rem(30);

        .MainNav--item[data-state='open'] & {
            display: block;
            animation: nav-open-mobile $transition-duration-slower forwards $ease-out-cubic;
        }
    }
}

.SubTabNav--content {
    @include above-tablet {
        display: flex;
		z-index: 11;
        flex-wrap: wrap;
		gap: 70px;
        padding: rem(25);
		font-size: 12px;
		font-family: 'IBM Plex Sans', sans-serif;
    }
}

.SubTabNav--item {
    @include tablet {
        margin-bottom: rem(30);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.SubTabNav--link {
    display: flex;

    @include above-tablet {
        align-items: center;
        padding: rem(5) 0;

        &:hover {
            color: $c-blue-dark;
        }
    }
}

.SubTabNav--icon,
.SubTabNav--groupIcon,
.SubTabNav--groupItemIcon {
    flex-shrink: 0;
    width: rem(24);
    height: rem(24);
    margin-right: rem(10);
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
    }

    @include tablet {
        width: 40px;
        height: 40px;
        margin-top: -2px;
    }
}

.SubTabNav--group {
    list-style: none;

    @include above-tablet {
        flex: 0 0 25%; 
        max-width: 25%;
        padding-right: rem(20);
        margin-bottom: rem(20);
    }
}

.SubTabNav--groupHeading {
    display: flex;
    align-items: center;
    font-size: 18px;
	font-weight: 500;
	line-height: 21px;
    color:#000047;
    margin-bottom: rem(15);
	font-family: 'IBM Plex Sans', sans-serif;
	// position: relative;
    // cursor: pointer; 

	// &::after { // Add this block
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     bottom: -2px;
    //     width: 0;
    //     height: 2px;
    //     background-color: #000047;
    //     transition: width 0.3s ease;
    // }

	// &:hover::after { // Add this block
    //     width: 100%;
    // }

    @include above-tablet {
        padding: 0;
        margin-bottom: rem(10);
    }
}

.SubTabNav--groupIcon {
    @extend .SubNav--icon;
    width: 28px;
    height: 28px;
    margin-right: rem(8);

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    @include tablet {
        width: 30px;
        height: 30px;
        margin-bottom: -2px;
    }
}

.SubTabNav--groupItem {
    @include tablet {
        margin-bottom: rem(15);
    }
}

.SubTabNav--groupLink {
    @extend .SubNav--link;
    // position: relative;

	// &::after { // Add this block
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     bottom: -2px;
    //     width: 0;
    //     height: 1px;
    //     background-color: #000047;
    //     transition: width 0.3s ease;
    // }

	// &:hover::after { // Add this block
    //     width: 100%;
    // }

    @include above-tablet {
		font-weight: 400; 
        font-size: 16px;
		padding-top: 7px;
        line-height: 20px; 
        color: #000047;
    }
}

.SubTabNav--groupItems {
    list-style: none;
    padding-left: 13px;
}

.SubTabNav--groupItemIcon {
    @extend .SubNav--icon;
    margin-right: rem(4);
}




// Langselect for mobile
.LangSelectMobile {
	@include list-reset;

	display: flex;
	justify-content: space-between;
	padding: 0 rem(15);
	color: $c-blue-dark;

	@include mobile-narrow {
		padding: 0 calc(#{rem(30)} - 4vw);
	}
}

.LangSelectMobile--lang-text {
	@include mobile-narrow {
		display: none;
	}
}

.LangSelectMobile--link {
	padding: rem(15);
	white-space: nowrap;
	text-transform: uppercase;

	@include mobile-narrow {
		padding: rem(15) 4vw;

		&:after {
			content: attr(data-short);
		}
	}

	&.is-active {
		&::before {
			content: '';
			display: inline-block;
			margin-right: rem(10);
			width: rem(10);
			height: rem(10);
			border-radius: 100%;
			background-color: $c-blue-neon;
		}
	}
}

// States
.Header[data-state='minified'] {
	margin-top: rem(-41);
	transform: translateY(0);
	transition: transform $transition-duration-default ease-out 0.1s,
		background-color $transition-duration-default ease-out;

	&.in-content {
		background-color: $c-blue-dark;
	}

	.Header--mainInner {
		height: rem(80);
	}

	.MainNav--link {
		font-size: rem(17);

		&.is-active::before {
			bottom: rem(-16);
		}
	}

	.Header--nav {
		margin-left: auto;
		margin-right: auto;
	}

	.Header--cta .Button {
		@include button-small;
	}

	.Logo--text {
		opacity: 0;
	}

	@include tablet {
		margin-top: 0;
	}
}

.Header[data-state='hidden-top'],
.Header[data-state='hidden'] {
	margin-top: rem(-41);
	transform: translateY(-100%);
	transition: transform $transition-duration-slow ease-out 0.1s, margin-top 1ms linear 0.5s;

	&.in-content {
		background-color: $c-blue-dark;
	}

	.Header--mainInner {
		transition: height 1ms linear 0.5s;
	}

	.MainNav--link {
		transition: font-size 1ms linear 0.5s;

		&.is-active::before {
			bottom: rem(-16);
			transition: bottom 1ms linear 0.5s;
		}
	}

	.Header--cta {
		.Button {
			transition: padding 1ms linear 0.5s;

			.Button--text {
				transition: font-size 1ms linear 0.5s;
			}
		}
	}

	.Logo--text {
		transition: opacity 1ms linear 0.5s;
	}

	@include tablet {
		margin-top: 0;
	}
}

.Header[data-state='hidden'] {
	.Header--nav {
		margin-left: auto;
		margin-right: auto;
	}
}

.header-no-scroll {
	overflow: hidden;
}

@mixin iframeZoomFix($zoom) {
	// Zooming of :root causing fixed iframe to trim content
	// By removing zoom from :root we will cause unpredictable layout shifts
	// So applying this fix as a compromise option to revert zoom specifically for iframe
	iframe {
		zoom: $zoom;
	}
}

// Scale down content when scaled up in OS
@media (-webkit-min-device-pixel-ratio: 1.09) {
	:root {
		zoom: 0.95;
	}

	@include iframeZoomFix(1.09);
}

@media (-webkit-min-device-pixel-ratio: 1.1) {
	:root {
		zoom: 0.91;
	}

	@include iframeZoomFix(1.1);
}

@media (-webkit-min-device-pixel-ratio: 1.2) {
	:root {
		zoom: 0.85;
	}

	@include iframeZoomFix(1.2);
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
	:root {
		zoom: 0.8;
	}

	@include iframeZoomFix(1.25);
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
	:root {
		zoom: 0.665;
	}

	@include iframeZoomFix(1.5);
}
