/* HomeExperience Component Styles */
.FullscreenSlider {
	@include color-scheme-dark;

	position: relative;
	width: 100%;

	&.show-navigation-text {
		.Slider {
			@include above-tablet {
				.dot {
					&:after {
						content: none !important;
					}
				}

				.flickity-page-dots {
					bottom: -2px;
					position: relative;
				}
			}
		}
	}

	.Slider {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.Slider--slide .Button {
			display: inline-block;
		}

		.Slider--slide {
			display: flex;
			flex-direction: column;

			margin-right: 0;
		}

		.flickity-viewport {
			width: 100%;
			margin-bottom: rem(-65);
		}

		.flickity-button:disabled {
			display: block;
		}

		.flickity-button {
			position: static;
			border: 0;
			width: auto;
			background: none;
			color: inherit;
			box-shadow: none;
			height: rem(25);
			transform: translateY(-100%) !important; // data-magnetic
			padding: 0 rem(10);
			margin: 0;
			margin-bottom: rem(40);

			&.next {
				order: 1;
			}
		}

		.flickity-prev-next-button .flickity-button-icon {
			fill: currentColor;
		}

		.flickity-page-dots {
			position: static;
			width: auto;
			height: rem(25);
			counter-reset: count;
			@include tx-title-xs;
			transform: translateY(-100%);
			margin-bottom: rem(40);
			max-width: calc(100% - #{rem(70)});
		}

		.flickity-page-dots .dot {
			width: auto;
			height: auto;
			margin: 0;
			padding: 0 rem(5);
			background: none;
			border-radius: 0;
			counter-increment: count;
			opacity: 1;

			&.is-selected {
				color: $c-blue-neon;
			}

			&:after {
				content: counter(count, decimal-leading-zero);
			}
		}
	}
}

.FullscreenSlider--sliderContainer {
	overflow: hidden;
}

.FullscreenSlide {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	text-shadow: 0px 4px 4px #000000;
	text-align: center;
	@include space(padding-top, xl);
	padding-bottom: rem(120px + 65px);

	@include tablet {
		min-height: calc(100vh - #{rem($header-height-mobile)});
		padding-bottom: rem(100px + 65px);
	}

	@include tablet-portrait {
		padding-top: rem(50px);
		padding-bottom: rem(70px + 65px);
	}

	&.blue-overlay {
		.FullscreenSlide--img {
			opacity: 0.75;
			background: rgba(3, 3, 26, 1);
		}
	}

	.FullscreenSlide--text {
		margin-bottom: 75px;

		@include mobile {
			margin-bottom: 30px;

			+ .wrapper {
				position: absolute;
				left: 50%;
				bottom: -75px;
				transform: translate(-50%, 0);
			}
		}
	}

	.FullscreenSlide--text.left-side-text {
		font-family: $secondary-font-family;
		font-weight: 400;
		font-size: 116px;
		line-height: 90px;
		text-transform: uppercase;
		color: #ffffff;
		text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);

		@include ios-tablet {
			line-height: 1;
		}

		p {
			padding: 0;
			margin: 0;

			span.semi-bold-font-weight {
				font-weight: 600 !important;
			}
		}
	}

	@include mobile {
		.FullscreenSlide--text.left-side-text {
			font-family: $secondary-font-family;
			font-weight: 300;
			font-size: 80px;
			line-height: 0.8;
		}
	}

	.slideWrap {
		margin-left: 60%;
		text-align: right;
		width: 40%;
		padding-right: 1.5%;
		z-index: 99;
		margin-top: 300px;

		.FullscreenSlide--video-control {
			display: none;
		}

		&.has-left-side-text {
			margin-top: 150px;
			margin-left: initial;
			margin-right: 60%;
			text-align: left;
			width: 40%;
			padding-right: initial;
			padding-left: 1%;

			.FullscreenSlide--cta {
				right: auto;

				@include mobile {
					@include space(margin-top, l);

					@media screen and (min-height: 650px) {
						@include space(margin-top, xl);
					}

					a.Button.transparent {
						background-color: $c-white;
						color: $c-blue-medium;

						&::after {
							background-color: transparent;
							border: 2px solid $c-blue-neon;
							transition: background-color $transition-duration-slower $ease-out-cubic,
								box-shadow $transition-duration-slower $ease-out-cubic;
							box-shadow: 0 10px 20px rgba($c-blue-neon, 0.15);
						}

						&:hover {
							&::after {
								background-color: $c-blue-neon;
								box-shadow: 0 10px 20px rgba($c-blue-neon, 0);
							}
						}
					}
				}
			}

			.FullscreenSlide--video-control {
				display: block;
				z-index: 9999999;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}

				.video-pause,
				.video-play {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					@include mobile {
						position: relative;
						top: auto;
						left: auto;
						transform: none;
					}
				}

				.SvgIcon {
					width: 120px;
					height: 120px;

					@include mobile {
						width: 77px;
						height: 77px;
					}
				}

				@include mobile {
					position: relative;
					top: initial;
					left: initial;
					transform: initial;
					margin: initial;
					padding: initial;

					@include space(margin-top, l);
				}
			}
		}

		@include mobile {
			margin-top: auto;

			&.has-left-side-text {
				text-align: center;
				margin: auto;
				width: 100%;
			}
		}
	}
}

.FullscreenSlide--img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.FullscreenSlide--img--background {
	height: 100%;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: radial-gradient(50% 100% at 0 0, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 71, 0) 100%),
			radial-gradient(50% 100% at 0 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
		opacity: 0.2;
	}

	.Image {
		@include object-fit-image;
	}
}

.FullscreenSlide--img--background-blend {
	height: 100%;
	margin-left: auto;
	position: relative;
	width: 60%;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(
			270.05deg,
			rgba(0, 0, 71, 0.28) 46.43%,
			rgba(0, 0, 71, 0.96) 97.96%,
			rgb(0, 0, 71)
		);
	}

	@include mobile {
		width: 100%;

		&::after {
			background-image: unset;
			background-color: rgba(0, 0, 47, 0.4);
		}
	}

	.Image {
		@include object-fit-image;
	}
}

@media (max-width: 1024px) {
	.FullscreenSlide {
		background: linear-gradient(0deg, #000047 0%, rgba(0, 0, 71, 0) 100%);

		.slideWrap {
			text-align: center;
			margin-left: 10%;
			width: 80%;
		}
	}

	.WrapperWithActions {
		margin: 0 10%;
	}
}

@supports (mix-blend-mode: screen) {
	.FullscreenSlide--img--background {
		mix-blend-mode: screen;
	}
}

.FullscreenSlide--title {
	@include space(margin-bottom, m);
}

.FullscreenSlide--cta {
	@include mobile {
		right: auto;
	}

	a {
		display: inline-block;
		position: relative;
		z-index: 1;
		margin: -2em;
	}
}

// Don't use this at the moment because of performance issues.
.FullscreenSlide--scrollIndicator {
	position: absolute;
	display: block;
	bottom: rem(-30);
	left: calc(50% - 1px);
	width: 1px;
	height: 60px;
	overflow: hidden;

	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 33%,
		$c-blue-neon 33%,
		$c-blue-neon 66%,
		rgba(0, 0, 0, 0) 66%
	);
	background-size: 200% 300%;
	animation: heroImage-scroll 2.5s $ease-in-out-cubic infinite;
}

.SlideWrapWithActions {
	display: flex;
	flex-direction: column;
	gap: 24px;
	text-align: left;
	margin-top: 40px;

	&--title {
		color: $c-blue-neon;
		font-weight: 500;
	}

	&--text {
		font-size: 18px;
		max-width: 500px;
	}

	&--actions {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	&--action {
		border: 1px solid $c-ink;
		border-radius: 8px;
		background-color: $c-blue-dark;
		display: flex;
		flex-direction: column;
		gap: 16px;
		justify-content: space-between;
		padding: 24px;
		min-width: 235px;
		width: min-content;

		&--text {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			max-width: 300px;
		}

		.Button {
			white-space: nowrap;
		}
	}
}
