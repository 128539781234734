/* Footer Component Styles */
body:not(.home) {
	.Footer {
		margin-top: 13px;
	}
}

.Footer--main {
	padding: rem(90) 0 rem(60);
	border-bottom: 1px solid rgba($c-white, 0.15);
}

.Footer--mainInner {
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	@include tablet {
		margin-bottom: map_get($spaces-tablet, xl) * -1;
	}

	@include tablet {
		margin-bottom: map_get($spaces-mobile, xl) * -1;
	}
}

.Footer--section {
	position: relative;
	width: 25%;
	max-width: 25%;
	flex: 1 0 25%;
	padding-right: $gutter;
	padding-bottom: rem(50);

	@include tablet {
		padding-right: 20%;
		width: 50%;
		max-width: 50%;
		flex: 1 0 50%;
		margin-bottom: map_get($spaces-tablet, xl);
	}

	@include mobile {
		padding-right: 0;
		width: 100%;
		max-width: 100%;
		flex: 1 0 100%;
		margin-bottom: map_get($spaces-mobile, xl);
	}

	&.newsletter {
		.Footer--sectionCta {
			@include tablet-portrait {
				bottom: -15px;
			}

			@include mobile {
				bottom: -15px;
				width: 100%;
				text-align: center;
			}
		}
	}
}

.Footer--sectionTitle {
	@include space(margin-bottom, s);
}

.Footer--sectionCta {
	position: absolute;
	bottom: 0;

	.Link {
		margin-right: $gutter;

		&:last-child {
			margin-right: 0;
		}
	}
}

.Footer--bottomInner {
	display: flex;
	align-items: center;
	height: rem(88);

	@include tablet {
		display: block;
		height: auto;
		padding: rem(24) 0;
	}
}

.Footer--nav {
	margin-right: auto;

	@include tablet {
		margin-bottom: map_get($spaces-tablet, m);
	}
}

.FooterNav {
	@include list-reset;
	@include cf;
}

.FooterNav--item {
	float: left;
	margin-right: rem(50);

	&:last-child {
		margin-right: 0;
	}

	@include mobile {
		float: none;
	}
}

.FooterNav--link {
	opacity: 0.6;
}

.Footer--social {
	> span {
		margin-right: rem(30);
	}
}

.FooterSocial {
	@include list-reset;
	@include cf;

	display: inline-block;
	vertical-align: middle;
}

.FooterSocial--item {
	float: left;
	margin-right: rem(20);

	&:last-child {
		margin-right: 0;
	}
}

.FooterSocial--link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: rem(48);
	height: rem(48);
	border: 1px solid rgb(38, 38, 128);
	border-radius: 100%;

	.SvgIcon > svg {
		fill: $c-white;
	}
}

.site-footer .Footer--social span.tx-body-s {
	display: none;
}
