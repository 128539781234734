/* VideoCta Component Styles */
.VideoCta {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	@include tablet {
		padding-top: 0;
		@include space(padding-bottom, m);
	}

	@include mobile {
		padding-top: 0;
		@include space(padding-bottom, m);
	}

	.wrapper {
		@include mobile {
			padding: 0;
		}
	}
}

.VideoCta--wrapper {
	display: block;
	max-width: 100%;
	margin: auto;
}

.VideoCta--title {
	@include space(margin-bottom, m);
	color: $c-blue-medium;

	.color-scheme-dark & {
		color: $c-blue-neon;
	}

	@include tablet {
		@include space(padding-top, m);
		@include space(padding-left, m);
		@include space(padding-right, m);
	}
}

.VideoCta--media {
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: (100% / 16 * 9);

	@supports (padding-bottom: calc(100% * var(--ratio))) {
		padding-bottom: calc(100% * var(--ratio));
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.VideoCta--background {
	position: relative;

	&::before,
	&::after {
		@include background;

		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		z-index: -1;
		background-repeat: repeat;
		width: 100%;
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	@media (max-width: ($site-width + (2 * $side-padding))) {
		&::before,
		&::after {
			width: (3 / 12) * 100%;
		}
	}

	@include tablet {
		&::before,
		&::after {
			width: (2 / 12) * 100%;
		}
	}

	@include mobile {
		&::before,
		&::after {
			content: none;
		}
	}
}

.VideoCta--cta {
	margin-top: rem(map_get($spaces, m));
	text-align: center;
}
