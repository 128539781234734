.PictureContent01 {
	background-color: $c-background;
	display: flex;
	font-family: 'IBM Plex Sans', sans-serif;
	margin: 0 auto;
	padding: 48px 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		margin: 0 auto;
		max-width: 765px;
	}

	&--smalltitle {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
		text-transform: uppercase;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 18px;
		}
	}

	&--metatitle {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin: 0 auto 32px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 14px;
			line-height: 22px;
		}
	}

	&--row {
		display: flex;
		gap: 16px;
		justify-content: center;
		width: 100%;

		@include tablet-media() {
			flex-direction: column;
		}
	}

	&--cardColumn {
		flex: 0 0 calc(30% - 40px);

		@include tablet-media() {
			flex: 0 0 100%;
		}
	}

	&--img {
		align-items: center;
		border-radius: 16px;
		display: flex;
		height: 400px;
		justify-content: center;
		overflow: hidden;
		position: relative;
		width: 100%;

		@include mobile-media() {
			height: 250px;
		}

		& > img {
			height: 100%;
			object-fit: cover;
		}
	}

	&--card {
		background-color: $c-blue-dark;
		border-radius: 8px;
		padding: 24px;
		text-align: left;
		border-radius: 16px;
		width: 390px;

		@include tablet-media() {
			width: 100%;
		}
	}

	&--cardtitle {
		font-size: 24px;
		color: $c-white;
		margin-bottom: 15px;
		line-height: 30px;
		font-weight: 500;

		@include mobile-media() {
			@include reset-scale();

			font-size: 22px;
			line-height: 28px;
		}
	}

	&--carddescription {
		font-size: 14px;
		color: $c-white !important;
		margin-bottom: 24px;
		line-height: 22px;

		> * {
			font-size: 14px;
			line-height: 22px;
			color: $c-white !important;
		}

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}
}
