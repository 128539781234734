/* CtaCentered Component Styles */
.CtaCentered {
	@include space(padding-top, m);
	@include space(padding-bottom, m);
}

.CtaCentered--row {
	@include mobile {
		display: block;
	}
}

.CtaCentered--content {
	@include grid-col($cols: 8);
	margin: auto;
	text-align: center;

	@include tablet {
		@include grid-col($cols: 9);
	}

	@include tablet-portrait {
		@include grid-col($cols: 8);
	}

	@include mobile {
		@include grid-col($cols: 12);
	}
}

@include collapse-comp('CtaCentered') {
	@include space(margin-top, xl, true);
}

.Hero.text-under-lead + .CtaCentered {
	margin-top: 0;
}
