/* Input Component Styles */
.Inputv2 {
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.has-error {
		.Inputv2--decoration {
			background-color: $c-red;
		}

		.Inputv2--error {
			display: block;
		}
	}
}

.Inputv2--wrapper {
	position: relative;
	width: 100%;
}

.Inputv2--label {
	color: #70707d;
	margin: rem(2);

	&-required {
		color: $c-red;
	}
}
.Inputv2--instruction {
	color: #70707d;
	margin: rem(2);
	font-weight: light;
}

.Inputv2--select,
.Inputv2--input {
	width: 90%;
	padding: rem(10);
	margin: rem(2);
	border: 0;
	background: none;
	color: $c-blue-medium;
	border: 2px $c-gray-light solid;
	border-radius: 5px;

	&:focus ~ .Inputv2--decoration {
		background-color: none;
	}

	&::-webkit-input-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&::-moz-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&:-ms-input-placeholder {
		color: rgba($c-ink, 0.5);
	}
}

.Inputv2--select {
	padding-right: rem(32);
	text-align: left;

	&::-ms-expand {
		display: none;
	}
}

.Inputv2--textarea {
	width: 95%;
	padding: rem(10);
	margin: rem(2);
	border: 0;
	background: none;
	color: $c-blue-medium;
	border: 2px $c-gray-light solid;
	border-radius: 5px;

	&:focus ~ .Inputv2--decoration {
		background-color: none;
	}

	&::-webkit-input-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&::-moz-placeholder {
		color: rgba($c-ink, 0.5);
	}

	&:-ms-input-placeholder {
		color: rgba($c-ink, 0.5);
	}
}
.Wrapper-checkboxGroup {
	padding-top: 10px;

	&.one-line {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.container {
			margin-top: 10px;
		}
	}

	/* Customize the label (the container) */
	.container {
		display: block;
		position: relative;
		padding-left: 15px;
		margin-bottom: 12px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		//background-color: #000047;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 7px;
		top: 0;
		width: 10px;
		height: 20px;
		border: solid #000047;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.Inputv2--selectIcon {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-42%);
	pointer-events: none;
	width: auto !important;
}

.Inputv2--decoration {
	display: block;
	//margin-top: rem(5);
	//width: 100%;
	//height: 2px;
	//background-color: $c-gray-light;
	//transition: background-color $transition-duration-default $ease-out-cubic;
}

.Inputv2--error {
	position: absolute;
	bottom: rem(-26);
	left: 0;
	display: none;
	color: $c-red;
}
