/* Circuits Component Styles */

.Drivers {
	margin-top: 13px;
	@include space(padding-top, l);
	@include space(padding-bottom, l);

	.Drivers--heading {
		@include space(margin-bottom, l);

		@include mobile {
			@include space(margin-bottom, m);
		}

		text-align: center;
		text-transform: uppercase;

		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 38px;
	}

	.Drivers--list {
		@include space(margin-bottom, l, true);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;;
		gap: 20px;

		@include mobile {
			margin-bottom: 0;
			gap: 25px;
		}

		.Drivers--card {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0;
			gap: 40px;
			flex: none;
			order: 0;
			//flex-grow: 1;
			width: 300px;
			height: 580px;
			background: #FFFFFF;
			box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
			border-radius: 5px;

			@supports (-webkit-touch-callout: none) {
				width: 400px;
				height: 670px;
			}
		}

		.Driver--content {
			padding: 25px 30px;
			align-self: flex-start;
			margin: 0 auto auto;
			width: 100%;
			height: 100%;
			position: relative;
		}

		.Driver--position {
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 90%;
			text-transform: uppercase;

			color: $c-blue-medium;
		}

		.Driver--image {
			position: relative;
		}

		.Driver--imageInner {

			.Image {
				border: 6px solid $c-blue-neon;
				border-radius: 100px;
				overflow: hidden;
				height: 150px;
				width: 150px;
				margin: auto;
				background: $c-blue-neon;
				box-shadow: inset 3px 3px 10px 0 $c-blue-neon;
			}
		}

		.Driver--country-flag {
			width: 85px;
			height: 85px;
			position: absolute;
			bottom: 0;
			right: 15px;
			align-items: flex-end;
			justify-content: right;
			display: flex;
		}

		.Driver--name {
			margin-top: 15px;
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 90%;
			text-align: center;
			text-transform: uppercase;
			color: $c-blue-medium;
		}

		.Driver--meta {
			width: 100%;
			padding-left: 20px;
			padding-right: 10px;
		}

		table {
			margin-top: 16px;
			color: $c-ink;
			font-family: $secondary-font-family;
			font-style: normal;
			width: 100%;

			td {
				&:not(:first-of-type) {
					text-align: right;
					padding-right: 17px;
				}
			}
		}

		.Driver--points {
			border-top: 1px solid $c-gray-light;
			border-bottom: 1px solid $c-gray-light;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 16px;

			td {
				padding-top: 16px;
				padding-bottom: 16px;
				text-transform: uppercase;

				&:not(:first-of-type) {
					font-weight: bold;
				}
			}
		}

		.Driver--starts, .Driver--wins, .Driver--podiums {
			td {
				padding-top: 6px;
				padding-bottom: 6px;
				text-transform: uppercase;
			}
		}

		.Driver--starts {
			td {
				padding-top: 20px;
			}
		}

		.Driver--instagram {
			position: absolute;
			bottom: 25px;
			left: 32px;
			display: flex;
			font-family: $base-font-family;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: $c-ink;
		}

		.Driver--instagram-icon {
			display: inline-block;
			margin-right: 12px;
		}

	}

	.Drivers--cta {
		margin-top: 90px;
		text-align: center;

		.Button {
			box-shadow: 0 15px 40px rgba(62, 84, 185, 0.2);
			border-radius: 30px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 2px;
			text-transform: uppercase;

			color: $c-blue-neon;
		}
	}
}
