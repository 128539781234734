/* SocialMediaFeed3 Component Styles */
.SocialMediaFeed.layout-3 {
	.FeedGrid--grid {
		width: calc(50% + #{rem($gutter)});
		max-width: calc(50% + #{rem($gutter)});
	}

	.FeedGrid--cell.small {
		width: (4 / 12) * 100%;
	}

	.FeedGrid--cell.fluid {
		float: none;
		width: 100%;

		&::before {
			content: none;
		}

		.FeedGrid--cellContent {
			position: relative;
		}
	}

	.SocialMediaFeed--fb {
		min-height: rem(100); // Needed so intersectionObserver can be triggered
	}

	.SocialMediaFeed--fb .preview::after {
		content: none;
	}

	@include tablet-portrait {
		.FeedGrid--cell.small {
			width: (6 / 12) * 100%;
		}
	}

	@include mobile {
		.FeedGrid--grid {
			width: auto;
			max-width: none;
		}
	}
}

.SocialMediaFeed--columns {
	display: flex;

	.FeedGrid--grid:nth-child(1) {
		padding-right: rem($gutter / 2);
	}

	.FeedGrid--grid:nth-child(2) {
		padding-left: rem($gutter / 2);
	}

	@include mobile {
		display: block;

		.FeedGrid--grid:nth-child(1) {
			padding-right: 0;
		}

		.FeedGrid--grid:nth-child(2) {
			padding-left: 0;
		}
	}
}
