@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

/* Common Card Styles */
.card {
    background-color: #EFEFF0;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    height: 292px;
    width: 473px;
    position: relative;
    overflow: hidden;
}

/* Index Card Styles */
.indexcard__number {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #000047;
}

.indexcard__title {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 20px;
    color: #000047;
    font-family: 'Bai Jamjuree', sans-serif;
    word-wrap: break-word;
}

.indexcard__subtitle {
    margin-bottom: 1rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000047;
}

.indexcard__indexedDescription {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.indexcard__index {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000047;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-right: 4px;
}

.indexcard__description {
    flex-grow: 1;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #000047;
}

.indexcard__button {
    margin-top: auto;
}

/* Arrow Card Styles */
.arrowcard-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.arrowcard__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #000047;
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: left;
}

.arrowcard__subtitle {
    margin-bottom: 1rem;
    color: #000047;
    font-size: 16px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: left;
}

.arrowcard__descriptionItem {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.arrowcard__arrowIcon {
    margin-right: 0.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.arrowcard__arrowIcon svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.arrowcard__descriptionItem p {
    margin: 0;
    flex-grow: 1;
    line-height: 1.5;
    display: flex;
    align-items: center;
}

.arrowcard__button {
    margin-top: auto;
    text-align: left !important;
}
