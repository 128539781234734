@keyframes langselect-open {
	0% {
		transform: translateY(10px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
