/* ContactFormUser Component Styles */
.ContactFormUser {
	background-color: $c-white2 !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		display: flex;
		flex-direction: column;
	}

	&--small-title {
		color: $c-blue-dark !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;
	}

	&--main-title {
		color: $c-blue-dark !important;
		font-size: 32px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
	}

	&--description {
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;
	}

	&--content {
		display: flex;
		gap: 16px;

		@include tablet-media() {
			flex-direction: column;
		}
	}

	&--card {
		background-color: $c-background;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: fit-content;
		padding: 24px;

		@include mobile-media() {
			@include reset-scale();
		}

		&-flex1 {
			flex: 1;
		}
	}

	&--button {
		margin-top: 12px;
	}

	&--form {
		transition: opacity $transition-duration-fast $ease-in-out-quad;
	}

	&--form-grid {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, 1fr);

		@include mobile-media() {
			grid-template-columns: 1fr;
		}
	}

	&--textarea {
		grid-column: 1 / 3;

		@include mobile-media() {
			grid-column: unset;
		}
	}

	&[data-state='submitting'] {
		.ContactFormUser--form {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&[data-state='success'] {
		.ContactFormUser--message.success {
			display: block;
		}
	}

	&[data-state='error'] {
		.ContactFormUser--message.error {
			display: block;
		}
	}

	&--message {
		@include space(margin-top, m);

		display: none;
		background-color: $c-blue-neon;
		color: $c-blue-medium;

		&.error {
			color: $c-white;
			background-color: $c-red;
		}
	}

	& .Inputv2--wrapper > * {
		width: 100%;
	}

	& .Inputv2--error {
		bottom: 0;
		position: relative;
	}

	.Inputv2--input,
	.Inputv2--select,
	.Inputv2--textarea {
		background-color: $c-white;
	}
}

.contactLounges {
	&--main-title {
		color: $c-blue-dark !important;
		font-size: 22px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 30px;
		margin: 0;
	}

	&--header {
		background-color: #efeff0;
		color: $c-blue-dark;
		font-size: 16px;
		font-weight: 500;
		line-height: 23px;
		margin-top: 8px;
		padding: 2px;

		&:first-child {
			margin: 0;
		}
	}

	&--container {
		overflow: auto;
	}

	&--accordion {
		align-items: center;
		border: 0 0 1px 0;
		color: $c-blue-dark;
		cursor: pointer;
		display: flex;
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: 14px;
		font-weight: 500;
		gap: 8px;
		justify-content: space-between;
		padding: 8px;
		text-align: left;
		transition: 0.4s;
		line-height: 22px;
		width: 100%;

		&.contactLounges--active > span {
			transform: rotate(180deg);
		}
	}

	&--active,
	&--accordion:hover {
		background-color: transparent;
	}

	&--panel {
		padding: 0 8px;
		background-color: transparent;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
		border-bottom: 1px solid $c-gray-light;

		& > div {
			align-items: center;
			color: $c-blue-dark;
			display: flex;
			gap: 8px;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 5px;

			&:last-child() {
				margin-bottom: unset;
			}
		}
	}

	&--email {
		text-decoration: underline;
		text-underline-offset: 2px;
	}
}
