@keyframes heroImage-scroll {
	0% {
		background-position: 0 0;
	}

	70% {
		background-position: 0 -100%;
	}

	80% {
		background-position: 0 -100%;
	}

	100% {
		background-position: 0 -150%;
	}
}

/*
@keyframes heroImage-scroll {
	0% {
		transform: translate3d(0, 0px, 0);
	}

	40% {
		transform: translate3d(0, 60px, 0);
	}

	41% {
		height: 0;
		transform: translate3d(0, -60px, 0);
	}

	80% {
		height: 60px;
		transform: translate3d(0, 0px, 0);
	}

	100% {
		height: 60px;
		transform: translate3d(0, 0px, 0);
	}
}
*/
