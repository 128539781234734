/* TextImage Component Styles */
.TextImage {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	opacity: 0;

	&.show {
		opacity: 1;
	}
}

.TextImage--row {
	flex-direction: row-reverse;
	justify-content: flex-end;

	@include mobile {
		display: block;
	}
}

.TextImage--textColumn {
	@include grid-offset($cols: 1);
	@include grid-col($cols: 4);

	@include tablet {
		@include grid-col($cols: 5);
	}

	@include tablet-portrait {
		@include grid-offset($cols: 0);
		@include grid-col($cols: 6);
	}

	@include mobile {
		@include grid-col($cols: 12);
	}
}

.TextImage--content {
	display: flex;
	height: 100%;
	align-items: center;
}

.TextImage--contentInner {
	@include space(padding-top, l);
	@include space(padding-bottom, l);

	@include mobile {
		padding-top: map_get($spaces-mobile, m);
		padding-bottom: 0;
	}
}

.TextImage--title {
	@include space(margin-bottom, s);
}

.TextImage--imageColumn {
	@include grid-offset($cols: 2);
	@include grid-col($cols: 5);

	@include tablet {
		@include grid-offset($cols: 1);
	}

	@include mobile {
		@include grid-col($cols: 12);
		@include grid-offset($cols: 0);
	}
}

.TextImage--image {
	display: flex;
	align-items: center;
	height: 100%;

	@include mobile {
		display: block;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			padding-top: 100%;
		}
	}
}

.TextImage--imageInner {
	margin-left: rem($gutter * -1);
	overflow: hidden;
	border-radius: 5px;
	width: calc(100% + #{rem($gutter)});

	@include mobile {
		position: absolute;
		margin: 0;
		width: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.TextImage--cta {
	@include space(margin-top, s);

	@include mobile {
		text-align: center;
	}
}

.TextImage + .TextImage {
	@include space(margin-top, xl, true);
}

@include collapse-comp('TextImage', ':not(.TextImage)') {
	@include space(margin-top, xl, true);
}

// Variants
.TextImage.flipped {
	.TextImage--row {
		flex-direction: row;
		justify-content: flex-start;
	}

	.TextImage--imageColumn {
		@include grid-offset($cols: 0);
	}

	.TextImage--textColumn {
		@include grid-offset($cols: 2);

		@include tablet {
			@include grid-offset($cols: 1);
		}

		@include mobile {
			@include grid-offset($cols: 0);
		}
	}

	.TextImage--imageInner {
		margin-left: 0;
		margin-right: rem($gutter * -1);

		@include mobile {
			margin-right: 0;
		}
	}
}
