.TestimonialSeasonals {
	background-color: $c-white2 !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px 48px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--maintitle {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 32px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--slider {
		margin: auto;
		width: 100%;
	}

	.flickity-button.next {
		right: -60px;

		@include desktop-small-media() {
			display: none;
		}
	}

	.flickity-button.previous {
		left: -60px;

		@include desktop-small-media() {
			display: none;
		}
	}
}

.TestimonialSeasonals--slide {
	display: flex;
	gap: 16px;
	height: min-content;

	@include tablet-media() {
		flex-direction: column;
	}

	&-card {
		background-color: $c-background;
		border-radius: 16px;
		flex: 1;
		padding: 24px;

		&-title {
			color: $c-blue-dark;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			margin-bottom: 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 30px;
				line-height: 38px;
			}
		}

		&-description {
			color: $c-blue-dark !important;
			font-size: 14px;
			line-height: 22px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;
			}
		}

		&-button {
			margin-top: 24px;
			width: fit-content;

			& > a {
				display: flex !important;
			}
		}
	}

	&-image {
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		position: relative;
		margin-bottom: 20px;

		@include tablet-media() {
			height: 250px;
			margin-bottom: unset;
			min-height: 250px;
		}

		& > img {
			bottom: 0;
			height: 100%;
			object-fit: cover;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
		}
	}
}
