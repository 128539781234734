/* Downloads Component Styles */
.Downloads {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);
}

.Downloads--heading {
	@include space(margin-bottom, xl);
}

.Downloads--text {
	@include space(margin-bottom, xl);
}

.Downloads--list {
	@include list-reset;
	@include space(margin-bottom, l, true);

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: rem($gutter / -2);
	margin-right: rem($gutter / -2);
}

.Downloads--item {
	@include space(margin-bottom, l);

	display: block;
	flex: 0 0 20%;
	max-width: 20%;
	padding: 0 rem($gutter / 2);

	@include tablet-portrait {
		flex: 0 0 25%;
		max-width: 25%;
	}

	@include mobile {
		flex: 0 0 50%;
		max-width: 50%;
		width: 50%;
	}
}
