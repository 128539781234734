.pictureContent04 {
	background-color: $c-background !important;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 48px 0;
	width: 100%;

	@include tablet-media() {
		padding: 24px 16px;
	}

	&--wrapper {
		@include tablet-media() {
			padding: 0;
		}
	}

	&--header {
		max-width: 765px;
		margin: 0 auto;
		text-align: center;
	}

	&--maintitle {
		color: $c-blue-dark !important;
		font-size: 34px;
		font-family: 'Bai Jamjuree', sans-serif;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: 16px;
		text-align: center;

		@include mobile-media() {
			@include reset-scale();

			font-size: 32px;
			line-height: 38px;
		}
	}

	&--description {
		margin-bottom: 32px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: $c-blue-dark !important;

		@include mobile-media() {
			@include reset-scale();

			font-size: 12px;
			line-height: 20px;
		}
	}

	&--container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 16px;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: unset;
		}

		@include desktop-small-media() {
			grid-template-columns: repeat(3, 1fr);
		}

		@include tablet-media() {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mobile-media() {
			grid-template-columns: 1fr;
		}

		&[data-hidden='1'] {
			display: none;
		}
	}

	&--info-card {
		background-color: $c-blue-dark;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		grid-column: 1/3;
		grid-row: 1/3;
		padding: 24px;

		@include mobile-media() {
			grid-column: 1/1;
			grid-row: 1/1;
		}

		&-title {
			color: $c-white;
			font-size: 22px;
			font-family: 'Bai Jamjuree', sans-serif;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 16px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 28px;
			}
		}

		&-point {
			color: $c-white;
			display: grid;
			font-size: 14px;
			gap: 4px;
			grid-template-columns: 16px 1fr;
			line-height: 22px;
			margin-bottom: 8px;

			& > span {
				margin-top: 3px;
			}

			&:last-of-type {
				margin-bottom: 24px;
			}

			@include mobile-media() {
				@include reset-scale();

				font-size: 12px;
				line-height: 20px;

				& > span {
					margin-top: 2px;
				}
			}
		}

		&-button {
			margin-top: auto;
		}
	}

	&--card {
		align-items: center;
		background-color: $c-white2;
		border: 1px solid $c-white2;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px 24px;
		text-align: center;

		&[data-current='1'] {
			border-color: $c-blue-neon;
		}

		&-date {
			color: $c-blue-dark !important;
			font-family: 'Bai Jamjuree', sans-serif;
			font-size: 22px;
			font-weight: 500;
			line-height: 30px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 20px;
				line-height: 28px;
			}
		}

		&-info {
			color: $c-blue-dark !important;
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: 16px;
			gap: 8px;
			// line-height: 23px;

			@include mobile-media() {
				@include reset-scale();

				font-size: 14px;
				line-height: 21px;
			}
		}

		&-underscore {
			background-color: $c-gray-light;
			height: 1px;
			width: 27.5px;
		}
	}

	&--button {
		display: flex;
		justify-content: center;
	}
}
