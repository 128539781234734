/* Hero Component Styles */
.Hero {
	@include space(padding-bottom, xl);

	&.text-under-lead {
		padding: 0;

		.HeroImage {
			//height: 670px;
		}

		.HeroImage--title {
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 500;
			font-size: 55px;
			line-height: 55px;
			text-align: center;
			color: $c-blue-neon;

			@include mobile {
				font-family: $base-font-family;
				font-style: normal;
				font-weight: 500;
				font-size: 48px;
				line-height: 60px;
				text-align: center;

				@supports (-webkit-touch-callout: none) {
					line-height: 80px;
				}
			}
		}

		@include ios-tablet {
			.HeroImage--title {
				line-height: 73px;
			}
		}

		.HeroLead {
			display: none;
		}

		.HeroImage {
			@include mobile {
				height: 100vh;
				max-height: 100%;
			}
		}

		.HeroImage--content {
			margin-top: 50px;
			@include mobile {
				margin-top: 0;

				.HeroImage--title {
					padding-bottom: 60px;
				}
			}
		}

		.HeroLead--lead {
			font-family: $secondary-font-family;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 38px;
			align-items: center;
			text-align: center;
			color: $c-background;
			padding-top: 55px;

			@include mobile {
				padding-top: 0;

				font-style: normal;
				font-weight: 500;
				font-size: 23px;
				line-height: 38px;
				align-items: center;
				text-align: center;

				@supports (-webkit-touch-callout: none) {
					line-height: 50px;
				}
			}
		}

		.HeroImage--background:after {
			background: linear-gradient(180deg, rgba(0, 0, 71, 0) 0%, #000047 100%);
		}

		.HeroImage--background {
			mix-blend-mode: normal;
		}
	}
}
