/* Circuits Component Styles */
.Circuits {
	@include space(padding-top, xl);
	@include space(padding-bottom, xl);

	@include mobile {
		.flickity-prev-next-button {
			top: calc(50vw - #{rem($side-padding)} - #{rem(40/2)});
		}
	}
}

.Circuits--heading {
	@include space(margin-bottom, l);

	text-align: center;
}

// Circuit item
.Circuit--row {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.Circuit--content {
	display: flex;
	height: 100%;
	align-items: center;
}

.Circuit--image {
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	border-radius: 5px;

	@include mobile {
		display: block;
		max-width: rem(480);
		margin-bottom: rem(map_get($spaces-mobile, m));
	}
}

.Circuit--imageInner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.Circuit--contentInner {
	@include space(padding-top, l);
	@include space(padding-bottom, l);

	@include tablet-portrait {
		padding-top: 0;
	}

	@include mobile {
		width: 100%;
		padding-bottom: 0;
	}
}

.Circuit--name {
	@include space(margin-bottom, m);
}

.Circuit--meta {
	@include space(margin-bottom, m);

	display: flex;
	padding: rem(20) 0;
	border-top: 1px solid $c-gray-light;
	border-bottom: 1px solid $c-gray-light;

	> div {
		flex: 1 0 0;
	}

	dd {
		@include tx-body;
	}

	@include tablet-portrait {
		width: 100%;
		flex-direction: column;

		> div {
			display: flex;
			flex: 1 0 auto;
		}

		> div > dt {
			flex: 1 0 50%;
			max-width: 50%;
		}

		dd {
			@include tx-body-s;
		}
	}
}

.Circuit--description {
	@include mobile {
		display: none;
	}
}
